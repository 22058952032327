import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { RestService } from '../_services/rest.service';
import { UsersService } from '../_services';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, router, userService, restService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.userService = userService;
        this.restService = restService;
        this.loading = false;
        this.submitted = 'none';
    }
    RegisterComponent.prototype.ngOnInit = function () {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            designation: ['', Validators.required]
        });
    };
    Object.defineProperty(RegisterComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.registerForm.controls; },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.onSubmit = function () {
        this.submitted = 'block';
        var obj = {
            "firstName": this.registerForm.get("firstName").value,
            "lastName": this.registerForm.get("lastName").value,
            "email": this.registerForm.get("email").value,
            "designation": this.registerForm.get("designation").value
        };
        this.restService.requestAccess(obj).subscribe(function (Response) {
            console.log("Response", Response);
            var temp = JSON.parse(JSON.stringify(Response.body));
        });
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        // console.log("First Name", this.registerForm.get("firstName").value)
        this.loading = true;
    };
    RegisterComponent.prototype.navigateToLogin = function () {
        this.userService.signOut();
    };
    return RegisterComponent;
}());
export { RegisterComponent };
