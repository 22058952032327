<app-navbar></app-navbar>

<div class = "column exploreicons">

<div class = "row">

  <div class = "col-sm-3 imgDivsactive">
      <a [routerLink]="['/tlc']" class = "thumbnail">
        <img src = "assets/tlc.png" class = "iconPic" alt = "Generic placeholder thumbnail">  
      </a>
      <p class = "exploretextactive"><b>{{ 'Explore.Thin Layer Chromatography' | translate}}</b></p>
      
    </div>
    <div class = "col-sm-3 imgDivs">
      <a class = "thumbnail">
        <img class = "iconPic" src = "assets/nmr2.png"  alt = "Generic placeholder thumbnail">
      </a>
      <p class = "exploretext"><b>{{ 'Explore.Polymer Testing' | translate}}</b></p>
    </div>
    <div class = "col-sm-3 imgDivs">
      <a class = "thumbnail">
        <img src = "assets/IRMicroscopy2.png" class = "iconPic" alt = "Generic placeholder thumbnail">
      </a>
      <p class = "exploretext"><b>{{ 'Explore.IR Microscopy' | translate}}</b></p>
    </div>
    <div class = "col-sm-3 imgDivs">
      <a class = "thumbnail">
        <img src = "assets/massSpectrometry2.png" class = "iconPic" alt = "Generic placeholder thumbnail">
      </a>
      <p class = "exploretext"><b>{{ 'Explore.Mass Spectrometry' | translate}}</b></p>
    </div>

</div>
</div>