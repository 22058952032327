<div class="row" style="padding-left: 5.5%; padding-right: 2%; background: white;">
  <div style="margin-right: 3%">
<form class="mp-ra-form">
  <mat-form-field class="example-full-width" floatLabel="never">
    <input class="dropdown-custom" type="text" [placeholder]="language=='en'? 'Select MP' : 'Choisir MP'" aria-label="Number" matInput [formControl]="Control_MP" [matAutocomplete]="autoMP" (click)="clickHandlerMP()">
    <mat-autocomplete autoActiveFirstOption #autoMP="matAutocomplete" (optionSelected)="MPSelected($event)">
      <mat-option *ngFor="let option of filteredOptions_MP | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
</div>

<div >
  
    <form class="mp-ra-form" >
  <mat-form-field class="example-full-width2" floatLabel="never">
    <input class="dropdown-custom" type="text" [placeholder]="language=='en'? 'Select RA' : 'Choisir RA'" aria-label="Number" matInput [formControl]="Control_RA" [matAutocomplete]="autoRA" (click)="clickHandlerRA()" >
    <mat-autocomplete autoActiveFirstOption #autoRA="matAutocomplete"  (optionSelected)="RASelected($event)" >
      <mat-option *ngFor="let option of filteredOptions_RA | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</form>
<span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'MP RA Description' : 'MP RA Description'">
<a href="https://docs.google.com/spreadsheets/d/18zCER9OGJri9S6LMeuOBNQ25Dqp3f-PTWn4IZ1JPujQ/edit#gid=0" target="_blank">
  <span style = "float: right; margin-top: -19%; margin-right: -4%;color: gray"><i class = "fa fa-info-circle fa-lg"></i></span>
</a>
</span>
</div>
</div>