import { Component} from '@angular/core';
import {Options,ChangeContext} from 'ng5-slider';
import { UpdateService } from '../_services/update.service';

export interface RangeSliderModel {
  minValue: number;
  maxValue: number;
  options: Options;
}


@Component({
  selector: 'app-segment-slider',
  templateUrl: './segment-slider.component.html',
  styleUrls: ['./segment-slider.component.scss']
})
export class SegmentSliderComponent {


  minimum: number=200;
  maximum: number=300;
  Y1:number;
  Y2:number;
  constructor(private updateService:UpdateService){
    this.updateService.minValue$.subscribe(
      data=>{
        this.minimum=data;
        this.sliderOptions(200,300)

      }
    );
    this.updateService.maxValue$.subscribe(
      data=>{
        this.maximum=data;
      }
    );

    this.updateService.undoY1$.subscribe(data=>{
      this.minimum=data
    });
    this.updateService.undoY2$.subscribe(data=>{
      this.maximum=data
      this.sliderOptions(this.minimum,this.maximum)
    });
  }
  onUserChangeEnd(event:ChangeContext){
this.Y1=event.value;
this.Y2=event.highValue;
this.updateService.updateSliderValues(this.Y1,this.Y2)
  }
verticalSlider2: RangeSliderModel={
  minValue: this.minimum,
    maxValue: this.maximum,

    options: {
      floor: 0,
      ceil: 500,
      vertical: true,
      noSwitching: true,
      showTicks: false,
      draggableRange:true,
      pushRange:true,
      minRange: 50,
      maxRange: 250,
      
  }
}
sliderOptions(min,max){
  this.verticalSlider2 = {
    minValue: min,
    maxValue: max,

    options: {
      floor: 0,
      ceil: 500,
      vertical: true,
      noSwitching: true,
      showTicks: false,
      draggableRange:true,
      minRange: 50,
      maxRange: 250,
      
  }

}
}
  
}
