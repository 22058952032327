import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges,
  ChangeDetectorRef, ChangeDetectionStrategy,Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { ImageUtils } from './image.utils';
import { RestService } from '../_services/rest.service';
import {FormControl} from '@angular/forms';
import { NgxGalleryOptions} from 'ngx-gallery';
import {TranslateService} from '@ngx-translate/core'
import {Options} from 'ng5-slider';
import { UpdateService } from '../_services/update.service';

interface MoveStart {
  active: boolean;
  type: string | null;
  position: string | null;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  clientX: number;
  clientY: number;
}

export interface RangeSliderModel {
    minValue: number;
    maxValue: number;
    options: Options;
  }

interface Dimensions {
  width: number;
  height: number;
}

export interface CropperPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface ImageCroppedEvent {
  base64?: string | null;
  file?: Blob | null;
  width: number;
  height: number;
  cropperPosition: CropperPosition;
  
}

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnChanges {
  private originalImage: any;
  private moveStart: MoveStart;
  private maxSize: Dimensions;
  private originalSize: Dimensions;

  /* Image Gallery Variables*/
  galleryOptions: NgxGalleryOptions[];
  searchedImages: any[]=[];

  safeImgDataUrl: SafeUrl | string;
  resultImage:string;
  marginLeft: SafeStyle | string = '0px';
  imageVisible = false;
  cropperVisible=false;
  showSpinner=false;
  spotNumber: number;
  spotList: string[]=[];
  spots= new FormControl();
  selectedSpot: string="";
  mp="";
  ra="";
  spotdb:string;
  Y1:number=0;
  Y2:number=0;
  matchImageErr = 0;
  height=500;
  imageStr_cfms
  width
  percentIntensity=5
  percentColor=15;
  temp_ref_fold_list=[];
  temp_req_id=[];
  ref_fold_list=[];
  req_id=[];
  secondary_search_flag=0;
  secondarySearch=false;
  temp_image_obj;
  matchedImageArrayMultipleImage=[];
  tempImageResponse
  //Variable to control checked state of secondary search check box
  secondarySearchChecked=false
  //variable to prevent api call from happening when no image is selected
  imageSelected
//to check image is selected and based on it prevent updating imagfe resposne array
  imageSelectedFromLocal

//Variables for language translation
language='en'

//Variable for multi spot match
multiSpot=0
mss_images=[]
multiSpotCheckedFlag=false
showMultiSpot=false;
undoButtonShow=0 //variable to control visibility of undo button
multiSpotResponseArray=[]
multiSpotCount=0 //variable to count the times multi spot has been clicked
spotSelectionParameterArray=[]

//Clear all button variable
clearAllShow=0


//Variables for year filter
showYearFilterButton=0
startYear=1990
currentDate=new Date()
endYear=this.currentDate.getFullYear()
yearModalDisplay='none'
selectedStartYear=this.startYear
selectedEndYear=this.endYear
newKeywordSearchedFlag
resetYearFilter=0;
clearYearFilter = false;

//Variable for slider options

 yearSlider: RangeSliderModel={
   minValue: this.startYear,
     maxValue: this.startYear+2,
 
     options: {
       floor: this.startYear,
       ceil: this.endYear,
       step: 1,
       noSwitching: true,
       showTicks: false,
       draggableRange:true,
       pushRange:true,
       minRange: 1,
       maxRange: 2
       
   }
 }

  @Input()
  set imageFileChanged(file: File) {
      this.initCropper();
      if (file) {
          this.loadImageFile(file);
      }
  }

  @Input()
  set imageChangedEvent(event: any) {
      this.initCropper();
      if (event && event.target && event.target.files && event.target.files.length > 0) {
          this.loadImageFile(event.target.files[0]);
          this.imageSelected=1;
      }
  }

  @Input()
  set imageBase64(imageBase64: string) {
      this.initCropper();
      this.loadBase64Image(imageBase64);
  }

  @Input() format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
  @Input() outputType: 'base64' | 'file' | 'both' = 'both';
  @Input() maintainAspectRatio = true;
  @Input() aspectRatio = 1;
  @Input() resizeToWidth = 0;
  @Input() roundCropper = false;
  @Input() onlyScaleDown = false;
  @Input() imageQuality = 92;
  @Input() autoCrop = true;
  @Input() cropper: CropperPosition = {
      x1: -100,
      y1: -100,
      x2: 10000,
      y2: 10000
  };

  @Output() imageCropped = new EventEmitter<ImageCroppedEvent>();
  @Output() imageCroppedBase64 = new EventEmitter<string>();
  @Output() imageCroppedFile = new EventEmitter<Blob>();
  @Output() imageLoaded = new EventEmitter<void>();
  @Output() loadImageFailed = new EventEmitter<void>();
  @Inject(DOCUMENT) document;

  constructor(private elementRef: ElementRef, 
              private sanitizer: DomSanitizer, 
              private cd: ChangeDetectorRef,
              private restService: RestService,
              private translate: TranslateService,
              private updateService:UpdateService) {
      this.initCropper();


    //selected start year
    this.updateService.imageTabStartYear$.subscribe(
        data=>{
            this.selectedStartYear=data
        }
    );

    //clear year filter
    this.updateService.clrYearFilter$.subscribe(
        data=>{
            this.clearYearFilter=data
        }
    );

    //selected start year
    this.updateService.imageTabEndYear$.subscribe(
        data=>{
            this.selectedEndYear=data
        }
    );

    //Applying year filter
    this.updateService.imageTabYearFilter$.subscribe(
        data=>{
            this.matchImage()
        }
    )


    //Check if Image is selected
    this.updateService.imageSelected$.subscribe(
        data=>{
            this.imageSelectedFromLocal=data
        }
    );

    //subscribin to spot DB 
    this.updateService.spotDB$.subscribe(
        data=>{
           
            this.spotdb=data;
        }
    );
//subscribin got spot list
    this.updateService.spotList$.subscribe(
        data=>{
            this.spotList=[];
            this.spotNumber=data;
            for (var i=0; i <this.spotNumber;i++)
          {
              this.spotList[i]=i.toString()
          }
          this.spotList[i]="Can't find my spot"

        }
    );
    
    //Subscribing to select TLC Search Type
    this.updateService.ImageSearchType$.subscribe(
        data=>{
            this.spots.setValue('');
            this.percentColor=15
            this.percentIntensity=5
            this.Y1=0
            this.Y2=0
            this.selectedSpot=""
            this.multiSpot=0
            this.multiSpotCheckedFlag=false
            this.showMultiSpot=false //Multi spot check box will go away on a fresh search
            this.multiSpot=0;
            this.multiSpotResponseArray=[]
            this.spotSelectionParameterArray=[]
            this.multiSpotCount=0
            this.clearAllShow=0
            if(data=="Fresh" && this.imageSelectedFromLocal==1){
                
                this.showYearFilterButton=0
                this.secondary_search_flag=0
                this.ref_fold_list=[]
                this.req_id=[]
                this.matchedImageArrayMultipleImage=[]
                this.updateService.updateImageResponseArray(this.matchedImageArrayMultipleImage)
                this.resetYearFilter=0
                this.selectedStartYear=this.startYear
                this.selectedEndYear=this.endYear
                this.mss_images=[]
            }else if (data=="Filter" && this.imageSelectedFromLocal==1){
                this.showYearFilterButton=0
                this.secondary_search_flag=1
                this.ref_fold_list=this.temp_ref_fold_list
                this.req_id=this.temp_req_id
                this.matchedImageArrayMultipleImage.push(this.tempImageResponse)
                this.updateService.updateImageResponseArray(this.matchedImageArrayMultipleImage)
                this.mss_images=[]
        
            }
        }
    ) 

      this.updateService.Y1$.subscribe(
          data=>{
              
              this.Y1=data;
          }
      );
      this.updateService.Y2$.subscribe(
        data=>{
            
            this.Y2=data;
        }
    );
    this.updateService.selectedMP$.subscribe(
        data=>{
          
          this.mp=data
        },
        
      );
      this.updateService.selectedRA$.subscribe(
        data=>{
          this.ra=data
        },
        
      );
      this.updateService.currentLanguage$.subscribe(
        data=>{
            this.language=data;
            translate.use(this.language);
        }
    )
        //Code for language selection
        this.language=this.translate.currentLang
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang(sessionStorage.getItem("language"));
        const browserLang = translate.getBrowserLang();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['cropper']) {
          setTimeout(() => {
              this.setMaxSize();
              this.checkCropperPosition(false);
              this.doAutoCrop();
              this.cd.markForCheck();
          });
      }
      if (changes['aspectRatio']) {
          this.resetCropperPosition();
      }
  }

  private initCropper() {
      this.imageVisible = false;
      this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
          + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
          + 'AAarVyFEAAAAASUVORK5CYII=';
      this.moveStart = {
          active: false,
          type: null,
          position: null,
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          clientX: 0,
          clientY: 0
      };
      this.maxSize = {
          width: 0,
          height: 0
      };
      this.originalSize = {
          width: 0,
          height: 0
      };
      this.cropper.x1 = -100;
      this.cropper.y1 = -100;
      this.cropper.x2 = 10000;
      this.cropper.y2 = 10000;
  }

  private loadImageFile(file: File) {
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
          const imageType = file.type;
          if (this.isValidImageType(imageType)) {
              try {
                  this.checkExifRotationAndLoadImage(event.target.result);
              } catch (e) {
                  this.loadImageFailed.emit();
              }
          } else {
              this.loadImageFailed.emit();
          }
      };
      fileReader.readAsDataURL(file);
  }

  private isValidImageType(type: string) {
      return type === 'image/jpeg'
          || type === 'image/jpg'
          || type === 'image/png'
          || type === 'image/gif'
          || type === 'image/tiff'
  }

  private checkExifRotationAndLoadImage(imageBase64: string) {
      const exifRotation = ImageUtils.getOrientation(imageBase64);
      if (exifRotation > 1) {
          ImageUtils.resetOrientation(
              imageBase64,
              exifRotation,
              (rotatedBase64: string) => this.loadBase64Image(rotatedBase64)
          );
      } else {
          this.loadBase64Image(imageBase64);
      }
  }

  private loadBase64Image(imageBase64: string) {
  
    
    this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
    this.originalImage = new Image();
        this.originalImage.onload = () => {
            this.originalSize.width = this.originalImage.width;
            this.originalSize.height = this.originalImage.height;
            this.cd.markForCheck();
        };
        this.originalImage.src = imageBase64;
        
      
  }


  imageLoadedInView(): void {
      
      if (this.originalImage != null) {
          this.imageLoaded.emit();
          setTimeout(() => {
              this.setMaxSize();
              this.resetCropperPosition();
              this.cd.markForCheck();
          });
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
      this.resizeCropperPosition();
      this.setMaxSize();
  }

  private resizeCropperPosition() {
      const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
      if (this.maxSize.width !== displayedImage.offsetWidth || this.maxSize.height !== displayedImage.offsetHeight) {
          this.cropper.x1 = this.cropper.x1 * displayedImage.offsetWidth / this.maxSize.width;
          this.cropper.x2 = this.cropper.x2 * displayedImage.offsetWidth / this.maxSize.width;
          this.cropper.y1 = this.cropper.y1 * displayedImage.offsetHeight / this.maxSize.height;
          this.cropper.y2 = this.cropper.y2 * displayedImage.offsetHeight / this.maxSize.height;
      }
  }

  private resetCropperPosition() {
      const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
      if (displayedImage.offsetWidth / this.aspectRatio < displayedImage.offsetHeight) {
          this.cropper.x1 = 0;
          this.cropper.x2 = displayedImage.offsetWidth;
          const cropperHeight = displayedImage.offsetWidth / this.aspectRatio;
          this.cropper.y1 = (displayedImage.offsetHeight - cropperHeight) / 2;
          this.cropper.y2 = this.cropper.y1 + cropperHeight;
      } else {
          this.cropper.y1 = 0;
          this.cropper.y2 = displayedImage.offsetHeight;
          const cropperWidth = displayedImage.offsetHeight * this.aspectRatio;
          this.cropper.x1 = (displayedImage.offsetWidth - cropperWidth) / 2;
          this.cropper.x2 = this.cropper.x1 + cropperWidth;
      }
      this.doAutoCrop();
      this.imageVisible = true;
  }

  startMove(event: any, moveType: string, position: string | null = null) {
      this.moveStart = Object.assign({
          active: true,
          type: moveType,
          position: position,
          clientX: this.getClientX(event),
          clientY: this.getClientY(event)
      }, this.cropper);
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  moveImg(event: any) {
      if (this.moveStart.active) {
          event.stopPropagation();
          event.preventDefault();
          this.setMaxSize();
          if (this.moveStart.type === 'move') {
              this.move(event);
              this.checkCropperPosition(true);
          } else if (this.moveStart.type === 'resize') {
              this.resize(event);
              this.checkCropperPosition(false);
          }
          this.cd.detectChanges();
      }
  }

  private setMaxSize() {
      const el = this.elementRef.nativeElement.querySelector('.source-image');
      this.maxSize.width = el.offsetWidth;
      this.maxSize.height = el.offsetHeight;
      this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
  }

  private checkCropperPosition(maintainSize = false) {
      if (this.cropper.x1 < 0) {
          this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
          this.cropper.x1 = 0;
      }
      if (this.cropper.y1 < 0) {
          this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
          this.cropper.y1 = 0;
      }
      if (this.cropper.x2 > this.maxSize.width) {
          this.cropper.x1 -= maintainSize ? (this.cropper.x2 - this.maxSize.width) : 0;
          this.cropper.x2 = this.maxSize.width;
      }
      if (this.cropper.y2 > this.maxSize.height) {
          this.cropper.y1 -= maintainSize ? (this.cropper.y2 - this.maxSize.height) : 0;
          this.cropper.y2 = this.maxSize.height;
      }
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  moveStop(event: any) {
      if (this.moveStart.active) {
          this.moveStart.active = false;
          this.doAutoCrop();
      }
  }

  private move(event: any) {
      const diffX = this.getClientX(event) - this.moveStart.clientX;
      const diffY = this.getClientY(event) - this.moveStart.clientY;

      this.cropper.x1 = this.moveStart.x1 + diffX;
      this.cropper.y1 = this.moveStart.y1 + diffY;
      this.cropper.x2 = this.moveStart.x2 + diffX;
      this.cropper.y2 = this.moveStart.y2 + diffY;
  }

  private resize(event: any) {
      const diffX = this.getClientX(event) - this.moveStart.clientX;
      const diffY = this.getClientY(event) - this.moveStart.clientY;
      switch (this.moveStart.position) {
          case 'left':
              this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
              break;
          case 'topleft':
              this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
              this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
              break;
          case 'top':
              this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
              break;
          case 'topright':
              this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
              this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
              break;
          case 'right':
              this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
              break;
          case 'bottomright':
              this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
              this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
              break;
          case 'bottom':
              this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
              break;
          case 'bottomleft':
              this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
              this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
              break;
      }

      if (this.maintainAspectRatio) {
          this.checkAspectRatio();
      }
  }

  private checkAspectRatio() {
      let overflowX = 0;
      let overflowY = 0;

      switch (this.moveStart.position) {
          case 'top':
              this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
              overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
              overflowY = Math.max(0 - this.cropper.y1, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
              }
              break;
          case 'bottom':
              this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
              overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
              overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : (overflowX / this.aspectRatio);
              }
              break;
          case 'topleft':
              this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
              overflowX = Math.max(0 - this.cropper.x1, 0);
              overflowY = Math.max(0 - this.cropper.y1, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
              }
              break;
          case 'topright':
              this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
              overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
              overflowY = Math.max(0 - this.cropper.y1, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
              }
              break;
          case 'right':
          case 'bottomright':
              this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
              overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
              overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
              }
              break;
          case 'left':
          case 'bottomleft':
              this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
              overflowX = Math.max(0 - this.cropper.x1, 0);
              overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
              if (overflowX > 0 || overflowY > 0) {
                  this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                  this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
              }
              break;
      }
  }

  private doAutoCrop() {
      if (this.autoCrop) {
          this.crop();
      }
  }

  crop() {
      const displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
      
      if (displayedImage && this.originalImage != null) {
          const ratio = this.originalSize.width / displayedImage.offsetWidth;
          const left = Math.round(this.cropper.x1 * ratio);
          const top = Math.round(this.cropper.y1 * ratio);
          const width = Math.round((this.cropper.x2 - this.cropper.x1) * ratio);
          const height = Math.round((this.cropper.y2 - this.cropper.y1) * ratio);
          const resizeRatio = this.getResizeRatio(width);
          const resizedWidth = Math.floor(width * resizeRatio);
          const resizedHeight = Math.floor(height * resizeRatio);

          const cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
          cropCanvas.width = resizedWidth;
          cropCanvas.height = resizedHeight;

          const ctx = cropCanvas.getContext('2d');
          if (ctx) {
              ctx.drawImage(this.originalImage, left, top, width, height, 0, 0, width * resizeRatio, height * resizeRatio);
              this.cropToOutputType(cropCanvas, resizedWidth, resizedHeight);
          }
      }
  }

  private cropToOutputType(cropCanvas: HTMLCanvasElement, resizedWidth: number, resizedHeight: number) {
      const output: ImageCroppedEvent = {
          width: resizedWidth,
          height: resizedHeight,
          cropperPosition: Object.assign({}, this.cropper)
      };
      switch (this.outputType) {
          case 'base64':
              output.base64 = this.cropToBase64(cropCanvas);
              this.imageCropped.emit(output);
              break;
          case 'file':
              this.cropToFile(cropCanvas)
                  .then((result: Blob | null) => {
                      output.file = result;
                      this.imageCropped.emit(output);
                  });
              break;
          case 'both':
              output.base64 = this.cropToBase64(cropCanvas);
              this.cropToFile(cropCanvas)
                  .then((result: Blob | null) => {
                      output.file = result;
                      this.imageCropped.emit(output);
                  });
              break;
      }
  }

  private cropToBase64(cropCanvas: HTMLCanvasElement): string {
      const imageBase64 = cropCanvas.toDataURL('image/' + this.format, this.getQuality());
      this.imageCroppedBase64.emit(imageBase64);
      return imageBase64;
  }

  private cropToFile(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
      return this.getCanvasBlob(cropCanvas)
          .then((result: Blob | null) => {
              if (result) {
                  this.imageCroppedFile.emit(result);
              }
              return result;
          });
  }

  private getCanvasBlob(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
      return new Promise((resolve) => {
          cropCanvas.toBlob(
              (result: Blob | null) => resolve(result),
              'image/' + this.format,
              this.getQuality()
          );
      });
  }

  private getQuality(): number {
      return Math.min(1, Math.max(0, this.imageQuality / 100));
  }

  private getResizeRatio(width: number): number {
      return this.resizeToWidth > 0 && (!this.onlyScaleDown || width > this.resizeToWidth)
          ? this.resizeToWidth / width
          : 1;
  }

  private getClientX(event: any) {
      return event.clientX != null ? event.clientX : event.touches[0].clientX;
  }

  private getClientY(event: any) {
      return event.clientY != null ? event.clientY : event.touches[0].clientY;
  }

  onSelectSpot(event:any){
      this.selectedSpot=event.value;
      this.updateService.updateSpotNo(this.selectedSpot)
      if (this.selectedSpot=="Can't find my spot"){
          this.cropperVisible=true;
          this.updateService.updateCFMS(1)
          this.Y1=0
          this.Y2=0
      }
      else{
          this.cropperVisible=false;
          this.updateService.updateCFMS(0)
      }
  }
  intensityErrorSelector(event){
    this.percentIntensity=parseInt(event);
    this.updateService.updateIntensityVariation(this.percentIntensity)
  
}
colorErrorSelector(event){
    this.percentColor=parseInt(event);
    this.updateService.updateColorVariation(this.percentColor)
    
}
  matchImage(){
      
    if (this.selectedSpot=="Can't find my spot"){
        
        var scaleFactorY=(this.originalImage.height/400)
        var scaleFactorX=(this.originalImage.width/200)
        var xmin=Math.ceil(scaleFactorX*this.cropper.x1);
        var xmax=Math.ceil(scaleFactorX*this.cropper.x2);
        var ymin=Math.ceil(scaleFactorY*this.cropper.y1);
        var ymax=Math.ceil(scaleFactorY*this.cropper.y2);

         //Initializing images on top of which multi spot needs to take place
         if (this.multiSpotCount>0){
            this.mss_images=this.multiSpotResponseArray[this.multiSpotCount-1].mss_images
        }

        var obj={
            "Y1": this.Y1,
            "Y2": this.Y2,
            "MP":this.mp,
            "RA":this.ra,
            "xmin": xmin,
            "ymin": ymin,
            "xmax": xmax,
            "ymax": ymax,
            "imageStr_cfms": this.originalImage.src.split("base64,")[1],
            "intensity_variation":this.percentIntensity,
            "color_variation":this.percentColor,
            "request_id_list":this.req_id,
            "ref_plate_folder_list":this.ref_fold_list,
            "checkboxflag":this.secondary_search_flag,
            "startYear": this.selectedStartYear,
            "endYear": this.selectedEndYear,
            "multiSpotSelect":this.multiSpot,
            "mss_images": this.mss_images,
            "cropperX1":this.cropper.x1,
            "cropperX2":this.cropper.x2,
            "cropperY1":this.cropper.y1,
            "cropperY2":this.cropper.y2,
            "Spot": this.selectedSpot
        }

        this.spotSelectionParameterArray.push(obj)//pushing spot parameters in array
        
        
        this.restService.matchImageCfms(obj).subscribe(Response =>{
     
            var temp=JSON.parse(JSON.stringify(Response.body));
            this.updateService.updateImageResponse(temp);
            this.tempImageResponse=temp
            this.temp_ref_fold_list=temp.ref_plate_folder_list
            this.temp_req_id=temp.request_id_list
            //condition for showing undo button filter
            if (this.multiSpot==1){
                this.undoButtonShow=1
            }else{
                this.undoButtonShow=0
            }
            //condition for showing year filter
            if (this.secondary_search_flag==0 && this.multiSpot==0){
                this.showYearFilterButton=1
              }else{
                  this.showYearFilterButton=0
              }
            //Multispot check box will only appear once the match image is clicked for fresh results
            this.showMultiSpot=true;
            //Re-adjusting the checkbox after match has been performed
            this.multiSpotCheckedFlag=false
            //pushing image response to multi spot response array for undo functionality
            this.multiSpotResponseArray.push(temp)

         //handling elements in response array when only slider or variation changed but multi spot did not occur
         var multiSpotArraylength=this.multiSpotResponseArray.length
         var spotParameterLength=this.spotSelectionParameterArray.length
         if (multiSpotArraylength>this.multiSpotCount+1){
             this.multiSpotResponseArray.splice(multiSpotArraylength-2,1)
             this.spotSelectionParameterArray.splice(spotParameterLength-2,1)
         }

         //condition to show clear all filter
         if (this.multiSpotCount>0){
             this.clearAllShow=1
         }
            
          },
          error =>{

              var matchImageErr = true;
              this.updateService.updateErrflags(matchImageErr);
          });


        
    }else{
        //Initializing images on top of which multi spot needs to take place
        if (this.multiSpotCount>0){
            this.mss_images=this.multiSpotResponseArray[this.multiSpotCount-1].mss_images
        }
      var obj_temp = {
        "Spot" : this.selectedSpot,
        "Y1": this.Y1,
        "Y2": this.Y2,
        "spot_db":this.spotdb,
        "MP":this.mp,
        "RA":this.ra,
        "intensity_variation":this.percentIntensity,
        "color_variation":this.percentColor,
        "request_id_list":this.req_id,
        "ref_plate_folder_list":this.ref_fold_list,
        "checkboxflag":this.secondary_search_flag,
        "startYear": this.selectedStartYear,
        "endYear": this.selectedEndYear,
        "multiSpotSelect":this.multiSpot,
        "mss_images": this.mss_images
      };
     
      this.spotSelectionParameterArray.push(obj_temp)//pushing spot parameters in array
      this.restService.matchImage(obj_temp).subscribe(Response =>{
     
        var temp=JSON.parse(JSON.stringify(Response.body));
        this.updateService.updateImageResponse(temp);
        this.tempImageResponse=temp
        this.temp_ref_fold_list=temp.ref_plate_folder_list
        this.temp_req_id=temp.request_id_list
        console.log("printing request_id_list");
        console.log(temp.request_id_list);
        
        
        //condition for showing undo button of mutlispot
        if (this.multiSpot==1){
            this.undoButtonShow=1
        }else{
            this.undoButtonShow=0
        }
         //condition for showing year filter
        if (this.secondary_search_flag==0 && this.multiSpot==0){
            this.showYearFilterButton=1
          }else{
              this.showYearFilterButton=0
          }

        //Multispot check box will only appear once the match image is clicked for fresh results
        this.showMultiSpot=true;
        //Re-adjusting the checkbox after match has been performed
        this.multiSpotCheckedFlag=false
         
        //pushing image response to multi spot response array for undo functionality
        this.multiSpotResponseArray.push(temp)
         
         //handling elements in response array when only slider or variation changed but multi spot did not occur
         var multiSpotArraylength=this.multiSpotResponseArray.length
         var spotParameterLength=this.spotSelectionParameterArray.length
         if (multiSpotArraylength>this.multiSpotCount+1){
             this.multiSpotResponseArray.splice(multiSpotArraylength-2,1)
             this.spotSelectionParameterArray.splice(spotParameterLength-2,1)
         }

          //condition to show clear all filter
          if (this.multiSpotCount>0){
            this.clearAllShow=1
        }
      },
      error =>{
          var matchImageErr = true;
          this.updateService.updateErrflags(matchImageErr);
      });
      this.selectedStartYear=this.startYear
      this.selectedEndYear=this.endYear

  }

 
  
}

openYearModal(){
    if (this.resetYearFilter==0){
        this.updateService.displayYearModal(1)
    }else{
        this.updateService.displayYearModal(2)
    }
    this.resetYearFilter=1
    
  }

  ClearYearFilterfn(){
      setTimeout(() => {
        this.clearYearFilter = false;
        this.updateService.updateClearYearFilter(false);
      }, 1000);
  }

  onMultiSpotSelect(){

      if(this.multiSpotCheckedFlag){
          this.multiSpotCheckedFlag=false
          this.multiSpotCount-=1
          if (this.multiSpotCount==0){
              this.multiSpot=0
          }
          
      }else {
          this.showYearFilterButton=0
          this.multiSpotCount+=1;
          this.multiSpotCheckedFlag=true
          this.multiSpot=1
          this.Y1=0
          this.Y2=0
          this.spots.setValue('');
          this.percentColor=15
          this.percentIntensity=5
          this.cropperVisible=false;
          this.updateService.updateCFMS(0)
      }
  }

  //function to handle undo multi spot button click

  undoMutliSpot(){

this.multiSpotCount-=1;
this.updateService.updateImageResponseFromUndoMultiSpot(this.multiSpotResponseArray[this.multiSpotCount]);
this.tempImageResponse=this.multiSpotResponseArray[this.multiSpotCount];
this.multiSpotResponseArray.pop();

//fiding undo button when user has reached first spot selection
if (this.multiSpotCount==0){
    this.undoButtonShow=0
}

//Updating segment slider values
this.Y1=this.spotSelectionParameterArray[this.multiSpotCount].Y1
this.Y2=this.spotSelectionParameterArray[this.multiSpotCount].Y2
this.updateService.updateSliderForUndoMultispot(this.Y1,this.Y2)

//Updating variation slider values
this.percentColor=this.spotSelectionParameterArray[this.multiSpotCount].color_variation
this.percentIntensity=this.spotSelectionParameterArray[this.multiSpotCount].intensity_variation

//Updating spot number
this.selectedSpot=this.spotSelectionParameterArray[this.multiSpotCount].Spot
console.log(this.selectedSpot)
this.spots.setValue(this.selectedSpot);

//Updating spot area if can't find my spot is selected
if (this.selectedSpot=="Can't find my spot"){
    //this.updateService.updateCFMS(1)
    this.cropperVisible=true
    this.cropper.x1=this.spotSelectionParameterArray[this.multiSpotCount].cropperX1
    this.cropper.x2=this.spotSelectionParameterArray[this.multiSpotCount].cropperX2
    this.cropper.y1=this.spotSelectionParameterArray[this.multiSpotCount].cropperY1
    this.cropper.y2=this.spotSelectionParameterArray[this.multiSpotCount].cropperY2
    this.updateService.updateSliderForUndoMultispot(this.Y1,this.Y2)
}
this.spotSelectionParameterArray.pop();

}

clearAll(){
    this.Y1=0;
    this.Y2=0;
    this.updateService.updateSliderForUndoMultispot(200,300)
    this.percentColor=15
    this.percentIntensity=5
    this.spots.setValue('')
    this.multiSpot=0;
    this.multiSpotResponseArray=[]
    this.spotSelectionParameterArray=[]
    this.multiSpotCount=0
    this.cropperVisible=false;
    this.updateService.updateCFMS(0)
    this.clearAllShow=0
}

}
