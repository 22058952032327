<!-- Div for showing image results -->
  
  <div *ngIf="showImageResults">

   <div *ngIf="showResponseBreadcrumb==1">
     <ul class="breadcrumb">
        <li *ngFor="let response of uploadResponseNumber" >
            <a (click)="setResponse(response)" [style.text-decoration]="response==highlightSelectedBreadcrumb?'underline':'none'"
            [style.color]="response==highlightSelectedBreadcrumb? 'blue':'gray'">{{ 'Image Results.Upload' | translate}} {{response}}</a>
        </li>
        <li><a (click)="setResponse(uploadResponseNumber.length+1)" [style.text-decoration]="highlightSelectedBreadcrumb==uploadResponseNumber.length+1?'underline':'none'"
          [style.color]="highlightSelectedBreadcrumb==uploadResponseNumber.length+1? 'blue':'gray'">{{ 'Image Results.Upload' | translate}} {{uploadResponseNumber.length+1}}</a></li>
     </ul>
   </div>

      <!--Button for clearing image elastic search results-->
      <button *ngIf="counterElasticSearch>1" type="button" class="btn btn-default btn-sm" (click)="onClearTextSearch()" style="margin-left:4%;margin-top:2%;background: #2577a8;color: white">
          <span class="glyphicon glyphicon-refresh"></span>{{ 'Image Results.Clear Text Search' | translate}}
        </button>

        <!--Error warning when searched keyword is not present in matched reports-->
        <span *ngIf="elasticSearchNoResultWarning==1" style="margin-left:4%;margin-top:2%;color: grey; text-align: center;">
          <p><i>{{ 'Image Results.Seems like that word is not present in the reports! Try again!' | translate}} </i></p>
        </span>

        <!--div for showing Reference plates-->
        
        <span *ngIf="showReferencePlates==1">
        <h4  style="margin-top: 2%;margin-left: 4%;color: gray;">{{ 'Image Results.Reference Plate' | translate}}</h4>
        <p style="float:right; margin-top:-4%;margin-right:5%;color:gray">{{refPlateCount}} {{ 'Image Results.Plates found' | translate}}</p>
      </span>
      <div *ngIf="showReferencePlates==1" class="reference-plates">
        
        <div *ngFor="let refPlate of referencePlates">
            
          <div class="row result-ref">
              
            <div class="resultImage col-sm-2">
                <ngx-gallery [options]="galleryOptionsMatchedRef" [images]="refPlate.matched_image_url">
                </ngx-gallery>
            </div>

            <div class="col-sm-10">
                <div style="margin-top:2%; margin-bottom: -3%;">  
                    <b  style="color: gray">{{refPlate.ref_plate_folder}}</b> 
                    <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'View all images' : 'Voir toutes les images'">
                      <a (click)="openImagemodal(refPlate.ref_associated_images)">
                      <span style = "float: right; padding-right:10px;margin-left:-20px"><i class = "fa fa-eye fa-lg"></i></span>
                      </a>
                     </span> 
             </div>
             <hr>
                <ngx-gallery [options]="galleryOptionsRef" [images]="refPlate.ref_associated_images">

                </ngx-gallery>

            </div>

            <div class="col-sm-2" class="ref-accuracy">
                <div class="accuracy" style="display: inline-block">
                    <circle-progress
                        [percent]="refPlate.match_score"
                        [radius]="30"
                        [space]="-5"
                        [outerStrokeWidth]="5"
                        [innerStrokeWidth]="5"
                        [outerStrokeColor]="'#2577a8'"
                        [innerStrokeColor]="'#87ceeb'"
                        [animation]="true"
                        [animationDuration]="300"
                        [showSubtitle]="false"
                        [titleFontSize]="'12'"></circle-progress>
                   
                  </div>
             </div>
          </div>
        </div>
        <hr>
      </div>
      <div class="spinner_overlay"*ngIf=displayLoading style="text-align:center">
        <img src="assets/beaker.gif" class="loading-gif" style="size:30%"/>
        <p style = "color: gray;margin-top: -45px">{{ 'Image Results.Loading your results' | translate}}</p>
      </div>

       <!--Div for showing number of matched results-->

       <div *ngIf="pager.pages && pager.pages.length && showImageResults" >
          <h4  style="margin-top: 2%;margin-left: 4%;color: gray;">{{ 'Image Results.Matched Requests' | translate}}</h4>
       <p style="float:right; margin-top:-3%;margin-right:5%;color:gray">{{ 'Image Results.Showing Results' | translate}} {{startIndex}} - {{endIndex}} {{ 'Image Results.of' | translate}} {{resultCount}}</p>
       </div>

      <!--Div for showing matched images page wise-->
      
    <div *ngFor="let result of pagedItems">
   
      <div class = "row result">
       <div class = "resultImage col-sm-2">
        
        <ngx-gallery [options]="galleryOptionsMatched" [images]="result.matched_image_url"></ngx-gallery>

       </div>
  
       <div class = "resultText col-sm-10">
          <div class="requestID">
      <a [href]=result.preview_url target="_blank">
              <b>{{ 'Image Results.Request ID:' | translate}}{{result.request_id}}</b> 
            </a>
              <div style = "display:block;float:right;margin-right: 5%;">
                <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Download the report' : 'Téléchargez le rapport'">
              <a [href]=result.download_path download={{result.file_name}} class="downloadButton" ><i class="fa fa-download fa-lg" ></i></a>
              </span>
            </div>
            <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Report an issue' :'Signaler un problème'">
            <a (click)="openModal(result.request_id,result.matched_image_url,result.match_score)"> 
            <span style = "float: right; padding-right:10px;"><i class = "fa fa-exclamation-triangle fa-lg"></i></span>
          </a>
        </span> 
           <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Mark as important' : 'Marquer comme important'">
           <span style = "float: right; padding-right:10px;"><i class = "fa fa-star-o fa-lg"></i></span>
           </span> 
             <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'View all images' : 'Voir toutes les images'">
               <a (click)="openImagemodal(result.associated_images)">
              <span style = "float: right; padding-right:10px;"><i class = "fa fa-eye fa-lg"></i></span>
               </a>
            </span> 
            <span *ngIf="result.embed_flag==1" style = "float:right; padding-right:10px;"><i class = "fa fa-paperclip fa-lg" style="color:red" ></i></span>
          </div>
       <hr>
       <div class="row">
         <div class="col-sm-10 " style="margin-right:-30px;">
           <p [innerHTML]="result.preview_text"> </p>
         </div>
         <div class="col-sm-2">
            <div class="accuracy" style="display: inline-block">
                <circle-progress
                    [percent]="result.match_score"
                    [radius]="30"
                    [space]="-5"
                    [outerStrokeWidth]="5"
                    [innerStrokeWidth]="5"
                    [outerStrokeColor]="'#2577a8'"
                    [innerStrokeColor]="'#87ceeb'"
                    [animation]="true"
                    [animationDuration]="300"
                    [showSubtitle]="false"
                    [titleFontSize]="'12'"></circle-progress>
               
              </div>
         </div>
       </div>
         <div>
           <hr>
           <p><b>Number of associated images: {{result.associated_images.length}}</b></p> 
           <!-- <ngx-gallery [options]="galleryOptions" [images]="result.associated_images"></ngx-gallery> -->
          </div> 
     
      </div>
     
    </div>
  
      </div> 

      <!--Div for pagenation-->
      <div *ngIf="pager.pages && pager.pages.length && showImageResults" style="text-align:center;">
      <ul *ngIf="pager.pages && pager.pages.length && showImageResults" class="pagination" >
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(1)">{{ 'Image Results.First' | translate}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(pager.currentPage - 1)">{{ 'Image Results.Previous' | translate}}</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="setPage(page)">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.currentPage + 1)">{{ 'Image Results.Next' | translate}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.totalPages)">{{ 'Image Results.Last' | translate}}</a>
        </li>
    </ul>
  </div>
    </div>

    <!--Div for showing loading gif-->
  <div *ngIf="showLoading==1" style="text-align:center">
    <img src="assets/beaker.gif" class="loading-gif" style="size:30%"/>
    <p style = "color: gray;margin-top: -45px">{{ 'Image Results.Loading your results' | translate}}</p>
  </div>

<!--Error div-->
  <div *ngIf = "errorflag" style = "text-align: center; margin-top: 10em;">
    <img src ="assets/network-error.gif" style = "height:15em"/>
    
  </div>

  <!--No image matches div-->
  <div *ngIf = "showNoResults==1" style = "text-align: center; margin-top: 10em;">
    <img src ="assets/noimage2.png" style = "height:15em"/>
    <p>{{ 'Image Results.Sorry we could not find any matching images!' | translate}}</p>
    <p>{{ 'Image Results.Try adjusting the variation sliders and click on Match Image' | translate}}</p>
    
  </div>
<!--Div for showing placeholder-->
      <div *ngIf="showPlaceholder==1">
          <img src="assets/placeholder-chemistry-2.png" class="placeholder-image">
      
          <p style = "text-align: center; color: gray; margin-top: 2%;">{{ 'Image Results.Start searching to see image results!' | translate}}</p>
        
      </div>

      
    <!-- Issue Modal dialogue portion starting here -->

    <div class="backdrop" [ngStyle]="{'display':issueModalDisplay}"></div>
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':issueModalDisplay}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
           
            <b class="modal-title" style="width:175px" >{{ 'Image Results.Report issue' | translate}}</b>
            <a (click)="onIssueCloseHandled()" style="position:absolute;right:33px;">
            <span><i class = "fa fa-close fa-lg"></i></span>
          </a>
          </div>
          <div *ngIf="showMoadalContent==1" class="modal-body" >
            <div>
              <p><b>{{ 'Image Results.Reporting issue for:' | translate}}:</b></p>
              <p><u>{{ 'Image Results.Request ID:' | translate}}</u> {{modalReqId}} | <u>{{ 'Image Results.Image name' | translate}}</u> : {{modalImageName}}</p>
            </div>
            <div>
              <p><b>{{ 'Image Results.Filters selected:' | translate}} {{modalReqId}}</b></p>
              <p><u>MP</u> : {{mp}} | <u>RA</u> : {{ra}} | <u> {{ 'Image Results.Selected Spot' | translate}}</u> : {{modalSpot}} | <u>{{ 'Image Results.Intensity variation' | translate}}</u> : {{modalIntensity}} | <u>{{ 'Image Results.Color Variation' | translate}}</u> : {{modalColor}}</p>
            </div>
            <p><b>{{ 'Image Results.Add some comment:' | translate}}</b></p>
            <textarea name="Text1" cols="40" rows="5" #userInput></textarea>
            <div> <button  type="button" class="btn btn-default btn-sm"  (click)="submitIssue(userInput.value)" style="background: #2577a8;color: white">
              {{ 'Image Results.Submit' | translate}}
              </button></div>
           
          </div>
          <div *ngIf="showModalLoader==1" class="modal-body" style="text-align:center">
              <img src="assets/beaker.gif" class="loading-gif-modal" style="size:30%"/>
              <p style = "color: gray;margin-top: -45px">{{ 'Image Results.Submitting your issue…' | translate}}</p>
          </div>
          
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->


    <!--confirmation modal-->
    <div class="backdrop" [ngStyle]="{'display':confirmationModalDisplay}"></div>
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':confirmationModalDisplay}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
           
            <b class="modal-title">{{ 'Image Results.Your issue has been successfully logged!' | translate}}</b>
            <a (click)="onConfirmationCloseHandled()" style="margin-left: 19%;">
            <span style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
          </a>
          </div>
          
            
           
          </div>
          
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->

      <!--Fail modal-->
    <div class="backdrop" [ngStyle]="{'display':failModalDisplay}"></div>
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':failModalDisplay}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
           
            <b class="modal-title">{{ 'Image Results.Sorry something went wrong! Please try again!' | translate}}</b>
            <a (click)="onFailCloseHandled()" style="margin-left: 19%;">
            <span style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
          </a>
          </div>
          
            
           
          </div>
          
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    

      <!--Viewing the associated images by selecting corresponding MP and RA in the result displayed-->
        <!--Modal for showing all the images-->
  <div class="backdrop" [ngStyle]="{'display':modalDisplay}"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':modalDisplay}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
         
          <b class="modal-title">{{ 'Image Results.Associated Images:' | translate}}</b>
          <a (click)="onCloseHandled()" style="margin-left: 69%;">
          <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        </div>
        <div  class="modal-body" style="overflow:auto; height:30em" >

          <div *ngFor="let image of modalImageList">
              <button class="accordion" (click)="openImage(modalImageList,image)">
              <p [innerHTML]="image.description"><i class="fa fa-plus" style="float:right" aria-hidden="true">
              </i></p></button>
          </div>
        </div>
        <div class="modal-header"></div>
      
        
        
      </div><!-- /.modal-content -->
   </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->

  <!--Modal to open clicked image from modal-->


  <div class="backdrop" [ngStyle]="{'display':imageModalDisplay}" style="background-color:black"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':imageModalDisplay}">
    <div class="modal-dialog-image" role="document">
      <div class="modal-content" style="background-color:transparent; color:white">
        <div class="modal-header">
         
          <b class="modal-title" >{{ 'Image Results.Click on the image to zoom' | translate}}</b>
          <a (click)="onCloseImageHandled()" style="margin-left: 80%;">
          <span style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        </div>
        <div  class="modal-body" style="margin:auto;"  >
          <ngx-gallery [options]="modalGalleryOptions" [images]="imageModalGallery">
          </ngx-gallery>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
