import { Component, NgZone, OnInit } from '@angular/core';
import { UsersService } from '../_services/users.service';
import { Router } from '@angular/router';
import {UpdateService} from '../_services/update.service'

declare var google: any;

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.css']
})
export class GoogleLoginComponent implements OnInit {
 
  modalDisplay='none';
  loadingFlag=0;
  invalidLoginShow=0;
  first:String;
  last:String;
  emailId:String;
  role:String;
  accessDetails={"First Name":"","Last Name":"","Email ID":"","Role":""};
  private guiclt_id: string;

  constructor(private userService: UsersService, private router:Router, 
    private updateService: UpdateService, private ngZone: NgZone) { 
    this.updateService.invalidLoginFlag.subscribe(
      status=>{
        console.log(status)
        
        this.showInvalidLogin(status)
      }
    );
    this.guiclt_id = userService.client_id;
  }

  ngOnInit() {
    this.loadingFlag=0;
  }

  ngAfterViewInit(): void {
    this.loadGoogleScript().then(() => {
      this.renderGoogleSignInButton();
    });
  }

  loadGoogleScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('google-js')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.id = 'google-js';
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject();
      document.body.appendChild(script);
    });
  }

  handleCredentialResponse = (response) => {
    if (response && response.credential){
      this.invalidLoginShow=0;
      this.loadingFlag=1;
      this.executeResponse(response);
    }
  }

  renderGoogleSignInButton(): void {
    this.ngZone.runOutsideAngular(() => {
      google.accounts.id.initialize({
        client_id: this.guiclt_id,
        callback: this.handleCredentialResponse
      });
      google.accounts.id.renderButton(
        document.getElementById('google-button'),
        {
          type: 'standard',
          shape: 'rectangular',
          theme: 'filled_blue',
          text: 'signin_with',
          size: 'large',
          logo_alignment: 'left'
        }
      );
    });
  }

 /* signIn(){
    this.invalidLoginShow=0
    this.loadingFlag=1;
    this.userService.signIn();
    
  }*/

  executeResponse(response) {
    this.userService.id_token = response.credential;
    this.userService.signInGoogleUser();
  }

  register(){
    this.router.navigate(["/register"]);
  }

  firstName($event){
    this.first=$event
  }

  accessRequest($event,type){
    console.log("check in accessRequest",$event,type)
    if(type=='first'){
      this.accessDetails["First Name"]=$event
    }
    if(type=='last'){
      this.accessDetails["Last Name"]=$event
    }
    if(type=='email'){
      this.accessDetails["Email ID"]=$event
    }
    if(type=='role'){
      this.accessDetails["Role"]=$event
    }
  }

  Submit(){
    console.log("Access Details",this.accessDetails)
    this.onCloseHandled()
  }

  openModal(){
    this.modalDisplay='block'
  }

  onCloseHandled(){
    this.modalDisplay='none'
  }

  showInvalidLogin(status){
    this.loadingFlag=0;
    this.invalidLoginShow=status
  }

}
