import {Injectable,NgZone,Injector} from "@angular/core";
import * as _ from "lodash";
// import {GoogleAuthService} from "ng-gapi/lib/GoogleAuthService"
// import GoogleUser = gapi.auth2.GoogleUser;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import {UpdateService} from '../_services/update.service';

@Injectable()
export class UsersService {

  public authentication_response:Object;
  public auth_code: string="abcd";

  Auth_backend="https://backend-dot-gcp-sqo-ri-tlc-d.appspot.com";
  Auth_google_backend="https://europe-west1-gcp-sqo-ri-tlc-d.cloudfunctions.net";
  Auth_URL= environment.api_url;
  id_token = "";
  private user = undefined;
  public client_id = environment.client_id;
 
  //Variable to control where the login is happening from
  loginFromModal=0;  

  //Variable to read expires_at
  expiryTime=new Subject <string>();
  expiryTime$=this.expiryTime.asObservable();

  constructor(
    private injector: Injector,
    private  httpClient:  HttpClient,
    // private googleAuthService: GoogleAuthService,
    private router: Router,
    //private restService: RestService,
    private updateService: UpdateService) { }

    // public signIn() {

    //   this.googleAuthService.getAuth().subscribe((auth) => {
    //       auth.signIn().then(res => this.signInSuccessHandler(res), err => this.signInErrorHandler(err));
    //   });
    // }

    public signInGoogleUser() {
      this.user = JSON.parse(atob(this.id_token.split('.')[1]));
      this.signInSuccessHandler(this.user);
    }

    private signInSuccessHandler(res: any) {
      sessionStorage.setItem("id_token", this.id_token);
      let expires_at=res.exp.toString();
      sessionStorage.setItem("timer",expires_at);
      //sessionStorage.setItem("language","en");
      sessionStorage.setItem("language","en");

      this.validateToken().subscribe(
        Response=>{
          console.log('ValidateToken response');
          
            let result=JSON.parse(JSON.stringify(Response.body));
            const valid=result.validity;
            console.log(valid);
            if (valid=="valid"){
              sessionStorage.setItem('currentUser',JSON.stringify(result));
              this.navigate();
            }else{
              this.invalidTokenHandler();
            }
        });
    }
       
    public validateToken(){
      const id_token=sessionStorage.getItem('id_token');
      const validation_object={
        "id_token": id_token
      }
      
      return this.httpClient.post(`${this.Auth_URL}/validate/`,validation_object,{
        headers:new HttpHeaders({"Content-Type":"application/json"}),
        reportProgress:true,
         observe:'response',
      });      
    }    

    navigate(){      
      if (this.loginFromModal==0) {
        if (!sessionStorage.getItem("select language")) {
          sessionStorage.setItem("select language","yes");
        }
        const ngZone = this.injector.get(NgZone);
        ngZone.run(() => {
          this.router.navigate(['/home'], { skipLocationChange: true });
        });
      }
    }
    
    // private signInErrorHandler(err) {
    //   console.warn(err);
    // }

    signOut(){
      this.loginFromModal=0;
      this.router.navigate(["/login"]);
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("language");
      sessionStorage.removeItem("select language");
      sessionStorage.removeItem("timer");
      this.router.navigateByUrl("/login").then(() => {
        this.updateService.loggedIn.next(false);
        window.location.reload();
      });

      // sessionStorage.removeItem("timer");
      // localStorage.removeItem("language");
      // localStorage.removeItem("select language");
    }

    LoginModalFlag(){
      this.loginFromModal=1;
    }
  
    updateTimer(){
      this.expiryTime.next(sessionStorage.getItem("timer"))
    }

    invalidTokenHandler(){
      console.log("inside invalid tag in user service")
      this.updateService.updateInvalidLoginFlag(1);
    }
  
}
