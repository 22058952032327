import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {UpdateService} from '../_services/update.service'


@Injectable({
  providedIn: 'root'
})

export class RestService {


  //Variables for API URLs
//  API_LOCAL='http://172.20.93.79:8000'

  API_LOCAL='http://172.20.93.79:8000'
  API_Hotspot='http://192.168.43.231:8000'
  API_Backend="https://backend-temp-dot-gcp-sqo-ri-tlc-d.appspot.com"
  API_test="https://backend-dot-gcp-sqo-ri-tlc-d.appspot.com"
  //API_URL=this.API_Backend
  API_URL= environment.api_url;
  id_token=sessionStorage.getItem('id_token');

//Getting id token from session storage
getIDtoken(){
  this.id_token=sessionStorage.getItem('id_token');
  }




constructor(private  httpClient:  HttpClient,private updateService: UpdateService) { }



///########################## HTTPS Requests start here ########################################

//Crop image and identify spoy

getImage(imageSrc){
  this.getIDtoken(); //to get the latest token from session storage
  this.updateService.uploadPlaceholder.next(false);
  this.updateService.uploadLoadingVisible.next(true);
  return this.httpClient.post(`${this.API_URL}/identifyspots/`,imageSrc,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress: true,
    observe: 'response'
  });
  
}

//Get MP list

getMP(obj){
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/getMP/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token,"X-Requested-With":"XMLHttpRequest"}),
    reportProgress:true,
    observe: 'response'});
  
}

//Get RA list

getRA(obj){
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/getRA/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress:true,
    observe: 'response'
  });
}

//Update Jumbo keyword dropdown after selcting jumbo type

getJumboKeyword(obj){
  console.log("Performing getJumboKeyword")
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/getJumboList/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress:true,
    observe: 'response'
  });
}

//Performing image match

matchImage(spot_json){
  this.getIDtoken(); //to get the latest token from session storage
  this.updateService.imageShowLoading.next(1)
  this.updateService.matchImageClicked.next(1);
  var res= this.httpClient.post(`${this.API_URL}/imagematch/`,spot_json,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress: true,
    observe: 'response'
  });
  this.updateService.match_images.next(res);
  return res;
}

//Performing image match when user draws a box(Can't find my spot)
matchImageCfms(spot_json){
  this.getIDtoken(); //to get the latest token from session storage
  this.updateService.imageShowLoading.next(1)
  this.updateService.matchImageClicked.next(1);
  var res= this.httpClient.post(`${this.API_URL}/imagematchCfms/`,spot_json,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}), 
    reportProgress: true,
    observe: 'response'
  });
  
  this.updateService.match_images.next(res);
  return res;

}


//Get reference plate list
getReference(){
  this.getIDtoken(); //to get the latest token from session storage
  var obj={}
  return this.httpClient.post(`${this.API_URL}/getReference/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress:true,
    observe: 'response'
  });
}

getReferenceImages(obj){
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/getReferenceImages/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress:true,
    observe: 'response'
  });
}

submitIssue(obj){
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/imageMatchIssues/`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress:true,
    observe: 'response'
  });
}


updateInputFile(obj){
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`https://www.googleapis.com/upload/storage/v1/b/tlc_data_ingest_dev/o?uploadType=resumable&name=myObject`,obj,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token,'Content-Length':'524288','Content-Range': 'bytes 0-524287/2000000','X-Upload-Content-Type': 'application/zip','X-Upload-Content-Length':'2000000',"X-Requested-With":"XMLHttpRequest"}),
    reportProgress:true,
    observe: 'response'});
}


requestAccess(obj){ 
  console.log("Entering Request access API Hit")
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/requestAccess/`,obj,{
  headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token,"X-Requested-With":"XMLHttpRequest"}),
  reportProgress:true,
  observe: 'response'});
}

accessTable(){
  var obj={}
  console.log("Entering Access Table API Hit")
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/accessTable/`,obj,{
  headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token,"X-Requested-With":"XMLHttpRequest"}),
  reportProgress:true,
  observe: 'response'});
}

validateData(){
  var obj={}
  console.log("Entering Validate data")
  this.getIDtoken(); //to get the latest token from session storage
  return this.httpClient.post(`${this.API_URL}/validateData/`,obj,{
  headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token,"X-Requested-With":"XMLHttpRequest"}),
  reportProgress:true,
  observe:'response'});
}

//Performing image match


getAssociatedImages(request_payload){
  // this.updateService.imageShowLoading.next(1)
  // this.updateService.matchImageClicked.next(1);
  this.getIDtoken(); //to get the latest token from session storage
  var res= this.httpClient.post(`${this.API_URL}/getSignedUrl/`,request_payload,{
    headers: new HttpHeaders({"Authorization":"Bearer "+this.id_token}),
    reportProgress: true,
    observe: 'response'
  });
  this.updateService.signed_urls.next(res);
  return res;
}


}


