

  <div class="dropDownDiv">
    <div class="row" style="padding-left: 5.5%; padding-right: 2%; background: white;">
 
<div style="margin-left: 3%">
  <form class="mp-ra-form">
    <mat-form-field style="width:225px;" class="example-full-width" floatLabel="never">
      <input class="dropdown-custom" type="text" [placeholder]="language=='en'? 'Select Reference Plate Folder' : 'Sélectionnez une plaque de référence' " aria-label="Number" matInput [formControl]="Control_Ref" [matAutocomplete]="autoRef" (click)="clickHandlerRef()">
      <mat-autocomplete autoActiveFirstOption #autoRef="matAutocomplete" (optionSelected)="RefSelected($event)">
        <mat-option *ngFor="let option of filteredOptions_Ref | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  </div>
    </div>
  </div>

  <div>
    <div class="result">
      <div>
        <div style="padding-top:1%;color: gray; text-align: center; font-size: 1.5em">
          <b>{{selectedFolder}}</b>
          <span tabindex="0" data-toggle="tooltip" [title]="language=='en'?'View all images' : 'Voir toutes les images'">
              <a *ngIf="showPlaceholder!=1" (click)="openImagemodal(reference_plates)">
              <span  style = "float: right; padding-right:2%;"><i class = "fa fa-eye fa-lg"></i></span>
              </a>
            </span>
        </div>
        <hr>
        <!--Placeholder div-->
        <div *ngIf="showPlaceholder==1">
            <img src="assets/placeholder-chemistry-2.png" class="placeholder-image">
            <p style = "text-align: center;margin-top:1%">{{ 'Reference Plate.Start searching' | translate}}!</p>
        </div>
            <!--Div for showing loading gif-->
        <div *ngIf="showLoader==1" style="text-align:center">
            <img src="assets/beaker.gif" class="loading-gif" style="size:30%"/>
            <p style = "color: gray;margin-top: -45px">{{ 'Reference Plate.Loading your results...' | translate}}</p>
        </div>
        <div *ngIf="showReferencePlate==1" style="text-align:center">
              <ngx-gallery [options]="galleryOptions" [images]="reference_plates">
              </ngx-gallery>
        </div>
    
    <!--Div for showing error in request-->
  <div *ngIf = "refErr==1" style = "text-align: center; margin-top: 10em;">
    <img src ="assets/network-error.gif" style = "height:15em"/>

  </div>
      </div>


    </div>
  </div>

  <!--Modal for showing all the images-->
  <div class="backdrop" [ngStyle]="{'display':modalDisplay}"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':modalDisplay}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
         
          <b class="modal-title">{{ 'Reference Plate.All Images' | translate}}:</b>
          <a (click)="onCloseHandled()" style="position:absolute;right:20px;">
          <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        </div>
        <div  class="modal-body" style="overflow:auto; height:30em" >

          <div *ngFor="let image of modalImageList">
              <button class="accordion" (click)="openImage(image)">
              <p [innerHTML]="image.description"><i class="fa fa-plus" style="float:right" aria-hidden="true">
              </i></p></button>
          </div>
        </div>
        <div class="modal-header"></div>
      
        
        
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->


   <!--Modal to open clicked image from modal-->


   <div class="backdrop" [ngStyle]="{'display':imageModalDisplay}" style="background-color:black"></div>
   <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':imageModalDisplay}">
     <div class="modal-dialog-image" role="document">
       <div class="modal-content" style="background-color:transparent; color:white">
         <div class="modal-header">
             
           <b class="modal-title" >{{ 'Image Results.Click on the image to zoom' | translate}}</b>
           <a (click)="onCloseImageHandled()" style="margin-left: 80%;">
           <span style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
         </a>
         
 
         </div>
         <div  class="modal-body" style="margin:auto;"  >
           <ngx-gallery [options]="modalGalleryOptions" [images]="imageModalGallery" ></ngx-gallery>
         </div>
       </div><!-- /.modal-content -->
     </div><!-- /.modal-dialog -->
   </div><!-- /.modal !-->
 
