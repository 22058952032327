/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./segment-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ng5-slider/ng5-slider.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "ng5-slider";
import * as i5 from "./segment-slider.component";
import * as i6 from "../_services/update.service";
var styles_SegmentSliderComponent = [i0.styles];
var RenderType_SegmentSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SegmentSliderComponent, data: {} });
export { RenderType_SegmentSliderComponent as RenderType_SegmentSliderComponent };
export function View_SegmentSliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-2 custom-slider"], ["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ng5-slider", [["class", "ng5-slider"]], [[2, "vertical", null], [2, "animate", null], [1, "disabled", 0]], [[null, "valueChange"], [null, "highValueChange"], [null, "userChangeEnd"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.verticalSlider2.minValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("highValueChange" === en)) {
        var pd_2 = ((_co.verticalSlider2.maxValue = $event) !== false);
        ad = (pd_2 && ad);
    } if (("userChangeEnd" === en)) {
        var pd_3 = (_co.onUserChangeEnd($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.ɵa]), i1.ɵdid(3, 4964352, null, 1, i4.ɵa, [i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone], { value: [0, "value"], highValue: [1, "highValue"], options: [2, "options"] }, { valueChange: "valueChange", highValueChange: "highValueChange", userChangeEnd: "userChangeEnd" }), i1.ɵqud(335544320, 1, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.verticalSlider2.minValue; var currVal_4 = _co.verticalSlider2.maxValue; var currVal_5 = _co.verticalSlider2.options; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).sliderElementVerticalClass; var currVal_1 = i1.ɵnov(_v, 3).sliderElementAnimateClass; var currVal_2 = i1.ɵnov(_v, 3).sliderElementDisabledAttr; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SegmentSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-segment-slider", [], null, null, null, View_SegmentSliderComponent_0, RenderType_SegmentSliderComponent)), i1.ɵdid(1, 49152, null, 0, i5.SegmentSliderComponent, [i6.UpdateService], null, null)], null, null); }
var SegmentSliderComponentNgFactory = i1.ɵccf("app-segment-slider", i5.SegmentSliderComponent, View_SegmentSliderComponent_Host_0, {}, {}, []);
export { SegmentSliderComponentNgFactory as SegmentSliderComponentNgFactory };
