import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { UpdateService } from './_services/update.service';
import { environment } from '../environments/environment';
import { UsersService } from './_services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent { 
    hello = " Hi Patrick!";
    userName="Patrick"
    title = '';
    loggedIn=false;
    englishSelected=true;
    frenchSelected=false;
   
    constructor(private updateService:UpdateService,
        private userService: UsersService,
        private router: Router){
        updateService.loggedIn$.subscribe(
            data=>{
              this.loggedIn=data;
            }
          )

         console.log(environment.production)
    }
    logout(){        
        this.loggedIn=false;
        this.userService.signOut();
    }
    selectEnglish(){
        this.frenchSelected=false;
        this.englishSelected=true;

    }
    selectFrench(){
        this.englishSelected=false;
        this.frenchSelected=true;

    }  
}
