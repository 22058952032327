
<div class = "column" style = "padding-bottom: 0.5em;">

      <!--Placeholder image and text-->
      <div *ngIf="uploadPlaceholderVisible "> 
            <div class="placeholderImage" >
                  <img src="/assets/default.png" style = "width: 75%; margin-left:13%;

                  margin-top: 10%; border: 1px dotted lightslategray;"/>
            </div>

            <div class="placeholderText" >
                  <p>{{ 'Upload Image.Upload a .png/.tif image of plate' | translate}}</p>
            </div>
      </div>

      <!--Multi plate error-->
      <div *ngIf="showMultiPlateError==1 "> 
            <div class="placeholderImage" >
                  <img src="/assets/default.png" style = "width: 75%; margin-left:13%;
      
                  margin-top: 10%; border: 1px dotted lightslategray;"/>
            </div>
            <div class="placeholderText" >
                  <p style="color:red;">{{ 'Upload Image.Seems like you uploaded a multi-plate image' | translate}}.{{ 'Upload Image.Please upload a single plate image' | translate}} </p>
            </div>
      </div>

      <!--No plate detected error-->
      <div *ngIf="showNoPlateError==1 "> 
            <div class="placeholderImage" >
                  <img src="/assets/default.png" style = "width: 75%; margin-left:13%;
            
                  margin-top: 10%; border: 1px dotted lightslategray;"/>
            </div>
            <div class="placeholderText" >
                  <p style="color:red;">{{ 'Upload Image.Sorry we could not find a plate in image' | translate}}</p>
            </div>
      </div>

      <!--Loading GIF -->
      <div *ngIf="loadingVisible" style = "text-align: center;margin-left: auto; margin-right: auto; display: block;"> 
            <div style="height: 485.6px; padding-top: 23%">
                  <img src="/assets/beaker.gif" class = "loadingGif"/>
                  <p style = "color:grey;margin-top:-40px;">{{ 'Upload Image.Processing your image…' | translate}}</p>
            </div>
      </div>
      <!-- Showing the vertical text to go along with the slider here-->
       <div class="col-sm-1"  [style.display]="cropperReady && !loadingVisible? null : 'none'">
       <div class="vertical-text" display="block" style="font-size:13.5px;margin-left:-24px;margin-top: 80px;
        color: grey">
        {{ 'Upload Image.Adjust the slider to select the segment' | translate}}</div>
      </div> 
<!--Showing processed image with slider here-->
      <div class = "row" [style.display]="uploadPlaceholderVisible || loadingVisible || showMultiPlateError==1  || showNoPlateError==1? 'none' : null" style="margin-top: 3%">
            <!--Segment Slider imported-->
            <!-- <div class="col-sm-1">
            <div class="vertical-text" display="block">Segment Slider</div>
            </div> -->
           
            <div class = "col-sm-1" style="width:2px;margin-left: -13%">
                  <app-segment-slider></app-segment-slider>
            </div>
            <!--Image cropper component imported here-->
            <div class = "col-sm-12" style = "padding-left: 16.5px;margin-left: 4%;width: 500px">
                  <image-cropper
                  [imageBase64]="resultImage"
                  [maintainAspectRatio]="false"
                  [aspectRatio]="4 / 3"
                  [resizeToWidth]="128"
                  [roundCropper]="false"
                  format="png"
                  outputType="base64"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (loadImageFailed)="loadImageFailed()"
                  [style.display]="cropperReady ? null : 'none'" style="width:500px">
                  </image-cropper>
            </div>
      </div>

      <!--Button for uploading TLC image-->
      <div class = "row uploadButton">
            <input 
            type="file"
            (change)="handleFilechange($event)"
            id="files" 
            style="display:none;"
            accept="image/png,image/tiff"
            #fileInput>
            <span style = "margin-left: auto;margin-right: auto;display: block;" tabindex="0" data-toggle="tooltip"
            [attr.title]="(mp=='' || ra=='') && language=='en'? 'Select MP, RA and click on Select TLC Plate': (mp=='' || ra=='') && language=='fr'? 'Sélectionnez MP et RP et cliquez sur sélectionner plaque de ccm':''">
            <button mat-raised-button [color]="'custom'"  [disabled]="mp=='' || ra==''?true:false" 
            (click)="onImageSelectClick()">
            {{ 'Upload Image.Select TLC plate' | translate}}
            </button>
            </span>
      </div>


</div>



<div *ngIf="showImageHist==1" class="column">
            <hr>
            <h3 style="color:#2577A7;text-align:center;font-weight:10;font-size:18px;
            padding-top:2%">{{ 'Upload Image.Upload Image History' | translate}}</h3>
         <ngx-gallery [options]="galleryOptions" [images]="searchedImages"
          style="padding:0% 1% 0% 1%;border-radius: 5 px;margin-left: 6px">
        </ngx-gallery>

</div>

<!--Modal for selecting fresh search or filter search-->
<div class="backdrop" [ngStyle]="{'display':modalDisplay}"></div>
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':modalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
       
        <b class="modal-title">{{ 'Upload Image.Please select an option to continue' | translate}}</b>
        <a (click)="onCloseHandled()" style="position: absolute;right:20x">
        <span><i class = "fa fa-close fa-lg"></i></span>
      </a>
      </div>
      <div  class="modal-body" >
            
                  <mat-radio-group [(ngModel)]="labelPosition" (change)="onSelectSearchTypeRadio($event)">
                    <mat-radio-button class="example-margin" value="Fresh" id="fresh">{{ 'Upload Image.Fresh Search' | translate}}</mat-radio-button>
                    <mat-radio-button class="example-margin" value="Filter" id="filter">{{ 'Upload Image.Filter Current Results' | translate}}</mat-radio-button>
                  </mat-radio-group>
             <p style="color:gray; margin-top: 4%;">{{ 'Upload Image.Ensure you have selected corrent MP and RA for next image' | translate}}</p>
      </div>
      
      
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->



    <!--Pop up for applying year filter-->

    <div class="backdrop" [ngStyle]="{'display':yearModalDisplay}"></div>
    <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':yearModalDisplay}">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="width:150%">
          <div class="modal-header">
           
            <b class="modal-title">{{ 'Search Report.Year Range' |translate}}</b>
            <a (click)="onCloseYearModal()" style="position:absolute;right:20px">
            <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
          </a>
          </div>
          <div  class="modal-body" style="overflow:auto;" >
              <div class="year-slider">
              <ng5-slider [(value)]="yearSlider.minValue" [(highValue)]="yearSlider.maxValue" [options]="yearSlider.options" (userChangeEnd)="onUserChangeYear($event)"></ng5-slider>
              </div>
              <button type="button" class="btn btn-default" style="margin-top: 15px;
          background: #2577a8;
          border: none;
          color: white;" (click)="applyYearFilter()">{{  'Search Report.Apply' |translate }}</button>
          </div>
          <div class="modal-header"></div>
        
          
          
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal !-->

