import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersService } from '../_services/users.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private userService: UsersService) { }

    valid;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(sessionStorage.getItem("currentUser")){
            this.valid=JSON.parse(sessionStorage.getItem("currentUser")).validity;
        }
        if (this.valid=="valid") {
            // logged in so return true
            console.log("valid token received so taking to home")
            return true;
        }
        console.log("taking to login again")
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}