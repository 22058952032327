<div class = "regDiv" style = "background: white;">
<h2 style="padding:10px" class = "regHeading">Request Access</h2>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class = "regForm">
    <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" formControlName="firstName" class="form-control"  />
       
        
    </div>
    <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" formControlName="lastName" class="form-control" />
        
        
    </div>
    <div class="form-group">
        <label for="email">Solvay Email ID</label>
        <input type="text" formControlName="email" class="form-control" />
    </div>
    <div class="form-group">
        <label for="role">Designation</label>
        <input type="text" formControlName="designation" class="form-control" />
        </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">Register</button>
        <a (click)="navigateToLogin()" class="btn btn-link">Cancel</a>
    </div>
</form>

</div>

<!--Modal for displaying successful registration message-->
<div class="backdrop" [ngStyle]="{'display':submitted}"></div>
<div class="modal" tabindex="-1" role="dialog" style="margin-top:200px" [ngStyle]="{'display':submitted}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header">
        <b class="modal-title">Registation Successful!</b>
        <a (click)="navigateToLogin()" style="position: absolute;right:25px;">
        <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
      </div>
      <div style="height:55px" class="modal-body" > 
      <h5 style="text-align:center">You will be notified once the admin has provided you access</h5>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
 </div><!-- /.modal !-->
