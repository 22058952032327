
<app-navbar></app-navbar>
<div class = "row">

    <div class = "col-sm-3 imgDivs">
        <a class = "thumbnail">
          <img class = "disabled" src = "assets/journal_icon3.png">
        </a>
        <p class = "homeicontext"><b>{{userName}}'s {{ 'HomePage.Journal' | translate}}</b></p>
      </div>
      <div class = "col-sm-3 imgDivsactive">
        <a [routerLink]="['/explore']" class = "thumbnail">
          <img class = "imgDivExp" src = "/assets/explore_icon3.png">     
        </a>
        <p class = "homeiconexplore"><b>{{'HomePage.Explore' | translate}}</b></p>
        
      </div>
      <div class = "col-sm-3 imgDivs">
        <a class = "thumbnail">
          <img class = "disabled" src = "/assets/patent.png" >
        </a>
        <p class = "homeicontext2"><b>{{'HomePage.Patents' | translate}}</b></p>
      </div>
      <div class = "col-sm-3 imgDivs">
        <a class = "thumbnail">
          <img class = "disabled" src = "assets/cognitive_icon.png">
        </a>
        <p class = "homeicontext2"><b>{{'HomePage.Cognitive Search' | translate}}</b></p>
      </div>

</div>

