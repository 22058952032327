<div class = "row SearchBarDiv" >
    
    <div class = "col-sm-6 reportCont1" style="margin-top: 2%; margin-bottom: 25px;">
      <span class = "DDText" >{{ 'TLC.Digital Database Image Search' | translate}}</span>
    </div>

    <!-- <div class = "col-sm-6 reportCont2" style=" margin-bottom: 25px;">
      <input id="search_field_image" class = "searchBar col-sm-11" type="text" (keyup.enter)="search($event,'i')"
      style="background: white;" [placeholder]="language=='en'? 'Cognitive Search' : 'Cherche Cognitif'"   #reference>
    <button type="button" class="btn btn-default btn-sm searchButton col-sm-1"
    style=
    "color: white;
    background: #2577a8;    
    min-width: 3%;
    border:none;"
    (click)="search(reference.value,'b')"><i class="fa fa-search"></i>
  </button>
  </div> -->
</div>







<!--
margin-top: -8%;
height:70%;
border: none;
margin-left: 92%;
width: 6%; -->