<div class="spinner_overlay"*ngIf=displayLoading style="text-align:center">
  <img src="assets/beaker.gif" class="loading-gif" style="size:30%"/>
  <p style = "color: gray;margin-top: -45px">{{ 'Image Results.Loading your results' | translate}}</p>
</div>

<div class = "row SearchBarDiv">


        <div class = "col-sm-6 reportCont1" style="margin-top: 2%; margin-bottom: 25px;">
      <span class = "DDText">{{ 'Search Report.Digital Database Report Search' | translate}}</span>
    </div>
<!--Free text search when jumbi visible is false-->
    <div class = "col-sm-6 reportCont2" style=" margin-bottom: 25px;" [style.display]="jumboVisible?'none':null">
      <!-- <div _ngcontent-wfd-c5="" class="col-sm-6 reportCont2" style="margin-left:-3%; width:500px"> -->
        <!-- <input _ngcontent-wfd-c5="" class="searchBar" id="search_field_image" style="z-index:5" type="text" placeholder="Cognitive Search"></div> -->
      <input _ngcontent-wfd-c5="" id="search_field" class = "searchBar col-sm-11" id="search_field_image" style="background: white;" type="text" (keyup.enter)="search($event,'i')" [placeholder]="language=='en'? 'Cognitive Search' : 'Cherche Cognitif'" #reference  >
     
      <button type="button" class="btn btn-default btn-sm searchButton col-sm-1"
      [style.display]="jumboVisible?'none':null"
       style=
       "color: white;
      background: #2577a8;    
      min-width: 3%;
      border:none;"
       (click)="search(reference.value,'b')"><i class="fa fa-search"></i></button>



    </div>
    <!--Jumbo text search when jumbo visible is true-->
    

    
    
    
      <div class = "col-sm-6 reportContjumbo" [style.display]="jumboVisible?null:'none'">
      <!-- <app-jumbo-search-bar></app-jumbo-search-bar> -->
      <span style = "margin-left: auto;margin-right: auto;display: block;" tabindex="0" 
      data-toggle="tooltip" title="Select Keywords from the above dropdowns ">
      <mat-form-field class="example-chip-list" floatLabel="never" style="    
      margin-top: 2.8%;
      width: 87.5%;
    margin-left: 12.5%;">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let keyword of jumboKeyword" [selectable]="selectable"
                   [removable]="removable" (removed)="remove(keyword)">
            {{keyword.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>

    

          <input
          [placeholder]="language=='en'? 'Add Jumbo Keywords' : 'Ajouter des Mots-clés jumbo'"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 disabled>
        </mat-chip-list>
      </mat-form-field>
      </span>
      
        <span>      <button type="button" class="btn btn-default btn-sm" style="margin-top: -2px;
      float: right;
        margin-right: 14px;
        background: #2577a8;
        border: none;
        color: white;" (click)="jumboSearch('i')"><b style="font-size:13px;">{{ 'Jumbo Search.Search' | translate}} </b><i class="fa fa-search"></i></button>
        </span>
        <span style="float: right;margin-left:3%; margin-right: 2%;margin-top: 0.5%;">
          <form action="/action_page.php">
            <input type="checkbox" class="cbox" name="type" value="exact"  (click)="onExactMatch()" checked> 
            <span class="exmatch" style="color:white">{{ 'Jumbo Search.Exact Match' | translate}}</span>
          </form>
          </span>


    </div>

    
  </div>
<div>
    <div *ngIf="pager.pages && pager.pages.length && showSearchResults==1" >
      <p style="    color: white;
      margin-top: -2%;
      float: left;
      margin-left: 3%;"
      >{{'Search Report.Year' | translate}}: {{selectedStartYear}}-{{selectedEndYear}}</p>
        <button *ngIf='yearFilterReset==0' type="button" class="btn btn-default btn-sm" style="float: right;
        margin-top: -2.5%;
        margin-right: 20%;" (click)="openYearModal()">{{'Search Report.Year Filter' | translate}}</button>
     <p style="float:right; margin-top:-2%;margin-right:4%;color:white">{{ 'Search Report.Showing Results' | translate}} {{startIndex}} - {{endIndex}} {{ 'Image Results.of' | translate}} {{resultCount}}</p>
     </div>
  <div *ngIf="showSearchResults==1">
    <div *ngFor="let searchResult of pagedItems" style="margin-top:20px">
  
      <div class="result">
    
        <div class="requestID">
        <a [href]="searchResult.preview_url" target="_blank">
          <b>{{'Search Report.Request ID:' | translate}} {{searchResult.request_id}} </b> 
        </a>
          <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Download the report' : 'Téléchargez le rapport'">
          <div style = "display:block;float:right;margin-right: 5%;">
          <a [href]=searchResult.download_path download={{searchResult.file_name}} class="downloadButton"><i class="fa fa-download fa-lg" ></i></a>
        </div>
        </span>
          <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Mark as important' : 'Marquer comme important'">
          <span style = "float: right; padding-right:10px;"><i class = "fa fa-star-o fa-lg"></i></span>
        </span>
        <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'View all images' : 'Voir toutes les images'">
          <a (click)="openImagemodal(searchResult.associated_images)">
          <span *ngIf="searchResult.associated_images[0].small!==''" style = "float: right; padding-right:10px;"><i class = "fa fa-eye fa-lg"></i></span>
          </a>
        </span>
        <span *ngIf="searchResult.embed_flag==1" style = "float:right; padding-right:10px;"><i style="color: red" class = "fa fa-paperclip fa-lg"></i></span>
         <!-- <span style = "float: right; padding-right:1%;">
          <a [href]=searchResult.preview_url><i class = "fa fa-star-o"></i></a>
         </span> -->
        
    </div>
    <hr class="partition-line">
    <div class="row">
      <div class="col-sm-8">
      <p [innerHTML]="searchResult.preview_text" class="previewText"></p>
      </div>
      <div *ngIf="searchResult.associated_images[0].small==''" class="col-sm-4" style=" margin-top: -1%">
    <div style="margin-left:32%;">
        <img src="assets/noimage2.png" style="height:100px;widows: 145px;">
        <div style="font-size:15px;color:gray;  margin-right: 48%">
        <span style="text-align:center;">
        <p style="margin-bottom:-2%;">{{'Search Report.No images associated' | translate}}</p>
      </span>
      <span style="text-align:center">
            <p>{{'Search Report.with this report' | translate}}</p>
          </span>
      </div>
      </div>
      </div>
      
      <div *ngIf="searchResult.associated_images[0].small!=[]" class="col-sm-4" >
        <div style="margin-left:32%; margin-top: -2%; align-items: center;" >
          <img class="clickable" src="assets/photo-icon.png" style="height:100px;widows: 145px;" (click)="openImagemodal(searchResult.associated_images)">
          <div style="font-size:15px;color:gray;  margin-right: 48%">
          <span class="clickable" style="text-align:center;" (click)="openImagemodal(searchResult.associated_images)">
          <p style="margin-bottom:-2%; ">{{'Search Report.Click here to view' | translate}}</p>
        </span>
        <span class="clickable" style="text-align:center" (click)="openImagemodal(searchResult.associated_images)">
              <p>{{'Search Report.Associated images' | translate}}</p>
            </span>
        </div>
        </div>
        
          <!-- <div style = "margin-bottom: 26px; margin-left:6em;">
              <ngx-gallery [options]="galleryOptions" [images]="searchResult.associated_images" ></ngx-gallery>
            </div> -->
      </div>
    </div>
  </div>
    
    </div>
  </div>

    <!-- pager -->
    <div style="text-align:center">
    <ul *ngIf="pager.pages && pager.pages.length && showSearchResults==1" class="pagination" >
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(1)">{{ 'Image Results.First' | translate}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === 1}">
            <a (click)="setPage(pager.currentPage - 1)">{{ 'Image Results.Previous' | translate}}</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a (click)="setPage(page)">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.currentPage + 1)">{{ 'Image Results.Next' | translate}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a (click)="setPage(pager.totalPages)">{{ 'Image Results.Last' | translate}}</a>
        </li>
    </ul>
  </div>
  </div>

  <!--Loading Gif-->
  <div *ngIf="showLoading==1" style="margin-top: 10%;text-align: center" >
    <img src="assets/beaker-transparent.gif"  style="width: 25%">
    <p style="margin-top:-5%">{{ 'Image Results.Loading your results' | translate}}...</p>
  </div>
  <div class="ph-image">
  <!--Placeholder Image-->
 
  <div *ngIf="searchFlag==0" style="margin-top:20px">
    <img src="assets/placeholder-chemistry-2.png" class="placeholder-image">
    <p style = "text-align: center;margin-top:1%">{{ 'Search Report.Start searching' | translate}}!</p>
  </div>
</div>


  <!--Results div-->
  
  <div *ngIf="searchFlag==1 && showNoResult==1" style="margin-top:10%; text-align: center">
    <img src="assets/result-not-found.png" class="placeholder-image-not-found">
    <p style = "text-align: center;margin-top:1%">{{ 'Search Report.No reports found' | translate}}! <br>{{ 'Search Report.Try again with a different keyword' | translate}}</p>
  </div>

  <!--Error Image-->
  <div *ngIf = "SearchErr==1" style="text-align: center">
    <img src="assets/network-error.gif" >
    
  </div>

  <!--Modal for showing all the images-->
  <div class="backdrop" [ngStyle]="{'display':modalDisplay}"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':modalDisplay}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
         
          <b class="modal-title">{{ 'Image Results.Associated Images:' | translate}}</b>
          <a (click)="onCloseHandled()" style="margin-left: 69%;">
          <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        </div>
        <div  class="modal-body" style="overflow:auto; height:30em" >

          <div *ngFor="let image of modalImageList">
              <button class="accordion" (click)="openImage(modalImageList, image)">
              <p [innerHTML]="image.description"><i class="fa fa-plus" style="float:right" aria-hidden="true">
              </i></p></button>
          </div>
        </div>
        <div class="modal-header"></div>
      
        
        
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->

  <!--Modal to open clicked image from modal-->


  <div class="backdrop" [ngStyle]="{'display':imageModalDisplay}" style="background-color:black"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':imageModalDisplay}">
    <div class="modal-dialog-image" role="document">
      <div class="modal-content" style="background-color:transparent; color:white">
        <div class="modal-header">
            
          <b class="modal-title" >{{ 'Image Results.Click on the image to zoom' | translate}}</b>
          <a (click)="onCloseImageHandled()" style="margin-left: 80%;">
          <span style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        

        </div>
        <div  class="modal-body" style="margin:auto;"  >
          <ngx-gallery [options]="modalGalleryOptions" [images]="imageModalGallery" ></ngx-gallery>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->

  <!--Pop up for applying year filter-->

  <div class="backdrop" [ngStyle]="{'display':yearModalDisplay}"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':yearModalDisplay}">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width:150%">
        <div class="modal-header">
         
          <b class="modal-title">{{ 'Search Report.Year Range' |translate}}</b>
          <a (click)="onCloseYearModal()" style="margin-left: 69%;">
          <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
        </div>
        <div  class="modal-body" style="overflow:auto;" >
        <div class="year-slider">
        <ng5-slider  [(value)]="yearSlider.minValue" [(highValue)]="yearSlider.maxValue" [options]="yearSlider.options" (userChangeEnd)="onUserChangeYear($event)"></ng5-slider>
        </div>
          <button type="button" class="btn btn-default" style="margin-top: 15px;
        background: #2577a8;
        border: none;
        color: white;" (click)="applyYearFilter()">{{  'Search Report.Apply' |translate }}</button>
        </div>
        
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal !-->
    
   
    




 

