import { ElementRef, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUtils } from './image.utils';
import { RestService } from '../_services/rest.service';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';
var ImageCropperComponent = /** @class */ (function () {
    function ImageCropperComponent(elementRef, sanitizer, cd, restService, translate, updateService) {
        var _this = this;
        this.elementRef = elementRef;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.restService = restService;
        this.translate = translate;
        this.updateService = updateService;
        this.searchedImages = [];
        this.marginLeft = '0px';
        this.imageVisible = false;
        this.cropperVisible = false;
        this.showSpinner = false;
        this.spotList = [];
        this.spots = new FormControl();
        this.selectedSpot = "";
        this.mp = "";
        this.ra = "";
        this.Y1 = 0;
        this.Y2 = 0;
        this.matchImageErr = 0;
        this.height = 500;
        this.percentIntensity = 5;
        this.percentColor = 15;
        this.temp_ref_fold_list = [];
        this.temp_req_id = [];
        this.ref_fold_list = [];
        this.req_id = [];
        this.secondary_search_flag = 0;
        this.secondarySearch = false;
        this.matchedImageArrayMultipleImage = [];
        //Variable to control checked state of secondary search check box
        this.secondarySearchChecked = false;
        //Variables for language translation
        this.language = 'en';
        //Variable for multi spot match
        this.multiSpot = 0;
        this.mss_images = [];
        this.multiSpotCheckedFlag = false;
        this.showMultiSpot = false;
        this.undoButtonShow = 0; //variable to control visibility of undo button
        this.multiSpotResponseArray = [];
        this.multiSpotCount = 0; //variable to count the times multi spot has been clicked
        this.spotSelectionParameterArray = [];
        //Clear all button variable
        this.clearAllShow = 0;
        //Variables for year filter
        this.showYearFilterButton = 0;
        this.startYear = 1990;
        this.currentDate = new Date();
        this.endYear = this.currentDate.getFullYear();
        this.yearModalDisplay = 'none';
        this.selectedStartYear = this.startYear;
        this.selectedEndYear = this.endYear;
        this.resetYearFilter = 0;
        this.clearYearFilter = false;
        //Variable for slider options
        this.yearSlider = {
            minValue: this.startYear,
            maxValue: this.startYear + 2,
            options: {
                floor: this.startYear,
                ceil: this.endYear,
                step: 1,
                noSwitching: true,
                showTicks: false,
                draggableRange: true,
                pushRange: true,
                minRange: 1,
                maxRange: 2
            }
        };
        this.format = 'png';
        this.outputType = 'both';
        this.maintainAspectRatio = true;
        this.aspectRatio = 1;
        this.resizeToWidth = 0;
        this.roundCropper = false;
        this.onlyScaleDown = false;
        this.imageQuality = 92;
        this.autoCrop = true;
        this.cropper = {
            x1: -100,
            y1: -100,
            x2: 10000,
            y2: 10000
        };
        this.imageCropped = new EventEmitter();
        this.imageCroppedBase64 = new EventEmitter();
        this.imageCroppedFile = new EventEmitter();
        this.imageLoaded = new EventEmitter();
        this.loadImageFailed = new EventEmitter();
        this.initCropper();
        //selected start year
        this.updateService.imageTabStartYear$.subscribe(function (data) {
            _this.selectedStartYear = data;
        });
        //clear year filter
        this.updateService.clrYearFilter$.subscribe(function (data) {
            _this.clearYearFilter = data;
        });
        //selected start year
        this.updateService.imageTabEndYear$.subscribe(function (data) {
            _this.selectedEndYear = data;
        });
        //Applying year filter
        this.updateService.imageTabYearFilter$.subscribe(function (data) {
            _this.matchImage();
        });
        //Check if Image is selected
        this.updateService.imageSelected$.subscribe(function (data) {
            _this.imageSelectedFromLocal = data;
        });
        //subscribin to spot DB 
        this.updateService.spotDB$.subscribe(function (data) {
            _this.spotdb = data;
        });
        //subscribin got spot list
        this.updateService.spotList$.subscribe(function (data) {
            _this.spotList = [];
            _this.spotNumber = data;
            for (var i = 0; i < _this.spotNumber; i++) {
                _this.spotList[i] = i.toString();
            }
            _this.spotList[i] = "Can't find my spot";
        });
        //Subscribing to select TLC Search Type
        this.updateService.ImageSearchType$.subscribe(function (data) {
            _this.spots.setValue('');
            _this.percentColor = 15;
            _this.percentIntensity = 5;
            _this.Y1 = 0;
            _this.Y2 = 0;
            _this.selectedSpot = "";
            _this.multiSpot = 0;
            _this.multiSpotCheckedFlag = false;
            _this.showMultiSpot = false; //Multi spot check box will go away on a fresh search
            _this.multiSpot = 0;
            _this.multiSpotResponseArray = [];
            _this.spotSelectionParameterArray = [];
            _this.multiSpotCount = 0;
            _this.clearAllShow = 0;
            if (data == "Fresh" && _this.imageSelectedFromLocal == 1) {
                _this.showYearFilterButton = 0;
                _this.secondary_search_flag = 0;
                _this.ref_fold_list = [];
                _this.req_id = [];
                _this.matchedImageArrayMultipleImage = [];
                _this.updateService.updateImageResponseArray(_this.matchedImageArrayMultipleImage);
                _this.resetYearFilter = 0;
                _this.selectedStartYear = _this.startYear;
                _this.selectedEndYear = _this.endYear;
                _this.mss_images = [];
            }
            else if (data == "Filter" && _this.imageSelectedFromLocal == 1) {
                _this.showYearFilterButton = 0;
                _this.secondary_search_flag = 1;
                _this.ref_fold_list = _this.temp_ref_fold_list;
                _this.req_id = _this.temp_req_id;
                _this.matchedImageArrayMultipleImage.push(_this.tempImageResponse);
                _this.updateService.updateImageResponseArray(_this.matchedImageArrayMultipleImage);
                _this.mss_images = [];
            }
        });
        this.updateService.Y1$.subscribe(function (data) {
            _this.Y1 = data;
        });
        this.updateService.Y2$.subscribe(function (data) {
            _this.Y2 = data;
        });
        this.updateService.selectedMP$.subscribe(function (data) {
            _this.mp = data;
        });
        this.updateService.selectedRA$.subscribe(function (data) {
            _this.ra = data;
        });
        this.updateService.currentLanguage$.subscribe(function (data) {
            _this.language = data;
            translate.use(_this.language);
        });
        //Code for language selection
        this.language = this.translate.currentLang;
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang(sessionStorage.getItem("language"));
        var browserLang = translate.getBrowserLang();
    }
    Object.defineProperty(ImageCropperComponent.prototype, "imageFileChanged", {
        set: function (file) {
            this.initCropper();
            if (file) {
                this.loadImageFile(file);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageCropperComponent.prototype, "imageChangedEvent", {
        set: function (event) {
            this.initCropper();
            if (event && event.target && event.target.files && event.target.files.length > 0) {
                this.loadImageFile(event.target.files[0]);
                this.imageSelected = 1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageCropperComponent.prototype, "imageBase64", {
        set: function (imageBase64) {
            this.initCropper();
            this.loadBase64Image(imageBase64);
        },
        enumerable: true,
        configurable: true
    });
    ImageCropperComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['cropper']) {
            setTimeout(function () {
                _this.setMaxSize();
                _this.checkCropperPosition(false);
                _this.doAutoCrop();
                _this.cd.markForCheck();
            });
        }
        if (changes['aspectRatio']) {
            this.resetCropperPosition();
        }
    };
    ImageCropperComponent.prototype.initCropper = function () {
        this.imageVisible = false;
        this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
            + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
            + 'AAarVyFEAAAAASUVORK5CYII=';
        this.moveStart = {
            active: false,
            type: null,
            position: null,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            clientX: 0,
            clientY: 0
        };
        this.maxSize = {
            width: 0,
            height: 0
        };
        this.originalSize = {
            width: 0,
            height: 0
        };
        this.cropper.x1 = -100;
        this.cropper.y1 = -100;
        this.cropper.x2 = 10000;
        this.cropper.y2 = 10000;
    };
    ImageCropperComponent.prototype.loadImageFile = function (file) {
        var _this = this;
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            var imageType = file.type;
            if (_this.isValidImageType(imageType)) {
                try {
                    _this.checkExifRotationAndLoadImage(event.target.result);
                }
                catch (e) {
                    _this.loadImageFailed.emit();
                }
            }
            else {
                _this.loadImageFailed.emit();
            }
        };
        fileReader.readAsDataURL(file);
    };
    ImageCropperComponent.prototype.isValidImageType = function (type) {
        return type === 'image/jpeg'
            || type === 'image/jpg'
            || type === 'image/png'
            || type === 'image/gif'
            || type === 'image/tiff';
    };
    ImageCropperComponent.prototype.checkExifRotationAndLoadImage = function (imageBase64) {
        var _this = this;
        var exifRotation = ImageUtils.getOrientation(imageBase64);
        if (exifRotation > 1) {
            ImageUtils.resetOrientation(imageBase64, exifRotation, function (rotatedBase64) { return _this.loadBase64Image(rotatedBase64); });
        }
        else {
            this.loadBase64Image(imageBase64);
        }
    };
    ImageCropperComponent.prototype.loadBase64Image = function (imageBase64) {
        var _this = this;
        this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
        this.originalImage = new Image();
        this.originalImage.onload = function () {
            _this.originalSize.width = _this.originalImage.width;
            _this.originalSize.height = _this.originalImage.height;
            _this.cd.markForCheck();
        };
        this.originalImage.src = imageBase64;
    };
    ImageCropperComponent.prototype.imageLoadedInView = function () {
        var _this = this;
        if (this.originalImage != null) {
            this.imageLoaded.emit();
            setTimeout(function () {
                _this.setMaxSize();
                _this.resetCropperPosition();
                _this.cd.markForCheck();
            });
        }
    };
    ImageCropperComponent.prototype.onResize = function (event) {
        this.resizeCropperPosition();
        this.setMaxSize();
    };
    ImageCropperComponent.prototype.resizeCropperPosition = function () {
        var displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (this.maxSize.width !== displayedImage.offsetWidth || this.maxSize.height !== displayedImage.offsetHeight) {
            this.cropper.x1 = this.cropper.x1 * displayedImage.offsetWidth / this.maxSize.width;
            this.cropper.x2 = this.cropper.x2 * displayedImage.offsetWidth / this.maxSize.width;
            this.cropper.y1 = this.cropper.y1 * displayedImage.offsetHeight / this.maxSize.height;
            this.cropper.y2 = this.cropper.y2 * displayedImage.offsetHeight / this.maxSize.height;
        }
    };
    ImageCropperComponent.prototype.resetCropperPosition = function () {
        var displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (displayedImage.offsetWidth / this.aspectRatio < displayedImage.offsetHeight) {
            this.cropper.x1 = 0;
            this.cropper.x2 = displayedImage.offsetWidth;
            var cropperHeight = displayedImage.offsetWidth / this.aspectRatio;
            this.cropper.y1 = (displayedImage.offsetHeight - cropperHeight) / 2;
            this.cropper.y2 = this.cropper.y1 + cropperHeight;
        }
        else {
            this.cropper.y1 = 0;
            this.cropper.y2 = displayedImage.offsetHeight;
            var cropperWidth = displayedImage.offsetHeight * this.aspectRatio;
            this.cropper.x1 = (displayedImage.offsetWidth - cropperWidth) / 2;
            this.cropper.x2 = this.cropper.x1 + cropperWidth;
        }
        this.doAutoCrop();
        this.imageVisible = true;
    };
    ImageCropperComponent.prototype.startMove = function (event, moveType, position) {
        if (position === void 0) { position = null; }
        this.moveStart = Object.assign({
            active: true,
            type: moveType,
            position: position,
            clientX: this.getClientX(event),
            clientY: this.getClientY(event)
        }, this.cropper);
    };
    ImageCropperComponent.prototype.moveImg = function (event) {
        if (this.moveStart.active) {
            event.stopPropagation();
            event.preventDefault();
            this.setMaxSize();
            if (this.moveStart.type === 'move') {
                this.move(event);
                this.checkCropperPosition(true);
            }
            else if (this.moveStart.type === 'resize') {
                this.resize(event);
                this.checkCropperPosition(false);
            }
            this.cd.detectChanges();
        }
    };
    ImageCropperComponent.prototype.setMaxSize = function () {
        var el = this.elementRef.nativeElement.querySelector('.source-image');
        this.maxSize.width = el.offsetWidth;
        this.maxSize.height = el.offsetHeight;
        this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
    };
    ImageCropperComponent.prototype.checkCropperPosition = function (maintainSize) {
        if (maintainSize === void 0) { maintainSize = false; }
        if (this.cropper.x1 < 0) {
            this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
            this.cropper.x1 = 0;
        }
        if (this.cropper.y1 < 0) {
            this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
            this.cropper.y1 = 0;
        }
        if (this.cropper.x2 > this.maxSize.width) {
            this.cropper.x1 -= maintainSize ? (this.cropper.x2 - this.maxSize.width) : 0;
            this.cropper.x2 = this.maxSize.width;
        }
        if (this.cropper.y2 > this.maxSize.height) {
            this.cropper.y1 -= maintainSize ? (this.cropper.y2 - this.maxSize.height) : 0;
            this.cropper.y2 = this.maxSize.height;
        }
    };
    ImageCropperComponent.prototype.moveStop = function (event) {
        if (this.moveStart.active) {
            this.moveStart.active = false;
            this.doAutoCrop();
        }
    };
    ImageCropperComponent.prototype.move = function (event) {
        var diffX = this.getClientX(event) - this.moveStart.clientX;
        var diffY = this.getClientY(event) - this.moveStart.clientY;
        this.cropper.x1 = this.moveStart.x1 + diffX;
        this.cropper.y1 = this.moveStart.y1 + diffY;
        this.cropper.x2 = this.moveStart.x2 + diffX;
        this.cropper.y2 = this.moveStart.y2 + diffY;
    };
    ImageCropperComponent.prototype.resize = function (event) {
        var diffX = this.getClientX(event) - this.moveStart.clientX;
        var diffY = this.getClientY(event) - this.moveStart.clientY;
        switch (this.moveStart.position) {
            case 'left':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                break;
            case 'topleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'top':
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'topright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - 20);
                break;
            case 'right':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                break;
            case 'bottomright':
                this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + 20);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
            case 'bottom':
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
            case 'bottomleft':
                this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - 20);
                this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + 20);
                break;
        }
        if (this.maintainAspectRatio) {
            this.checkAspectRatio();
        }
    };
    ImageCropperComponent.prototype.checkAspectRatio = function () {
        var overflowX = 0;
        var overflowY = 0;
        switch (this.moveStart.position) {
            case 'top':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'bottom':
                this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : (overflowX / this.aspectRatio);
                }
                break;
            case 'topleft':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'topright':
                this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(0 - this.cropper.y1, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'right':
            case 'bottomright':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
            case 'left':
            case 'bottomleft':
                this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
                overflowX = Math.max(0 - this.cropper.x1, 0);
                overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
                if (overflowX > 0 || overflowY > 0) {
                    this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
                    this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
                }
                break;
        }
    };
    ImageCropperComponent.prototype.doAutoCrop = function () {
        if (this.autoCrop) {
            this.crop();
        }
    };
    ImageCropperComponent.prototype.crop = function () {
        var displayedImage = this.elementRef.nativeElement.querySelector('.source-image');
        if (displayedImage && this.originalImage != null) {
            var ratio = this.originalSize.width / displayedImage.offsetWidth;
            var left = Math.round(this.cropper.x1 * ratio);
            var top_1 = Math.round(this.cropper.y1 * ratio);
            var width = Math.round((this.cropper.x2 - this.cropper.x1) * ratio);
            var height = Math.round((this.cropper.y2 - this.cropper.y1) * ratio);
            var resizeRatio = this.getResizeRatio(width);
            var resizedWidth = Math.floor(width * resizeRatio);
            var resizedHeight = Math.floor(height * resizeRatio);
            var cropCanvas = document.createElement('canvas');
            cropCanvas.width = resizedWidth;
            cropCanvas.height = resizedHeight;
            var ctx = cropCanvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(this.originalImage, left, top_1, width, height, 0, 0, width * resizeRatio, height * resizeRatio);
                this.cropToOutputType(cropCanvas, resizedWidth, resizedHeight);
            }
        }
    };
    ImageCropperComponent.prototype.cropToOutputType = function (cropCanvas, resizedWidth, resizedHeight) {
        var _this = this;
        var output = {
            width: resizedWidth,
            height: resizedHeight,
            cropperPosition: Object.assign({}, this.cropper)
        };
        switch (this.outputType) {
            case 'base64':
                output.base64 = this.cropToBase64(cropCanvas);
                this.imageCropped.emit(output);
                break;
            case 'file':
                this.cropToFile(cropCanvas)
                    .then(function (result) {
                    output.file = result;
                    _this.imageCropped.emit(output);
                });
                break;
            case 'both':
                output.base64 = this.cropToBase64(cropCanvas);
                this.cropToFile(cropCanvas)
                    .then(function (result) {
                    output.file = result;
                    _this.imageCropped.emit(output);
                });
                break;
        }
    };
    ImageCropperComponent.prototype.cropToBase64 = function (cropCanvas) {
        var imageBase64 = cropCanvas.toDataURL('image/' + this.format, this.getQuality());
        this.imageCroppedBase64.emit(imageBase64);
        return imageBase64;
    };
    ImageCropperComponent.prototype.cropToFile = function (cropCanvas) {
        var _this = this;
        return this.getCanvasBlob(cropCanvas)
            .then(function (result) {
            if (result) {
                _this.imageCroppedFile.emit(result);
            }
            return result;
        });
    };
    ImageCropperComponent.prototype.getCanvasBlob = function (cropCanvas) {
        var _this = this;
        return new Promise(function (resolve) {
            cropCanvas.toBlob(function (result) { return resolve(result); }, 'image/' + _this.format, _this.getQuality());
        });
    };
    ImageCropperComponent.prototype.getQuality = function () {
        return Math.min(1, Math.max(0, this.imageQuality / 100));
    };
    ImageCropperComponent.prototype.getResizeRatio = function (width) {
        return this.resizeToWidth > 0 && (!this.onlyScaleDown || width > this.resizeToWidth)
            ? this.resizeToWidth / width
            : 1;
    };
    ImageCropperComponent.prototype.getClientX = function (event) {
        return event.clientX != null ? event.clientX : event.touches[0].clientX;
    };
    ImageCropperComponent.prototype.getClientY = function (event) {
        return event.clientY != null ? event.clientY : event.touches[0].clientY;
    };
    ImageCropperComponent.prototype.onSelectSpot = function (event) {
        this.selectedSpot = event.value;
        this.updateService.updateSpotNo(this.selectedSpot);
        if (this.selectedSpot == "Can't find my spot") {
            this.cropperVisible = true;
            this.updateService.updateCFMS(1);
            this.Y1 = 0;
            this.Y2 = 0;
        }
        else {
            this.cropperVisible = false;
            this.updateService.updateCFMS(0);
        }
    };
    ImageCropperComponent.prototype.intensityErrorSelector = function (event) {
        this.percentIntensity = parseInt(event);
        this.updateService.updateIntensityVariation(this.percentIntensity);
    };
    ImageCropperComponent.prototype.colorErrorSelector = function (event) {
        this.percentColor = parseInt(event);
        this.updateService.updateColorVariation(this.percentColor);
    };
    ImageCropperComponent.prototype.matchImage = function () {
        var _this = this;
        if (this.selectedSpot == "Can't find my spot") {
            var scaleFactorY = (this.originalImage.height / 400);
            var scaleFactorX = (this.originalImage.width / 200);
            var xmin = Math.ceil(scaleFactorX * this.cropper.x1);
            var xmax = Math.ceil(scaleFactorX * this.cropper.x2);
            var ymin = Math.ceil(scaleFactorY * this.cropper.y1);
            var ymax = Math.ceil(scaleFactorY * this.cropper.y2);
            //Initializing images on top of which multi spot needs to take place
            if (this.multiSpotCount > 0) {
                this.mss_images = this.multiSpotResponseArray[this.multiSpotCount - 1].mss_images;
            }
            var obj = {
                "Y1": this.Y1,
                "Y2": this.Y2,
                "MP": this.mp,
                "RA": this.ra,
                "xmin": xmin,
                "ymin": ymin,
                "xmax": xmax,
                "ymax": ymax,
                "imageStr_cfms": this.originalImage.src.split("base64,")[1],
                "intensity_variation": this.percentIntensity,
                "color_variation": this.percentColor,
                "request_id_list": this.req_id,
                "ref_plate_folder_list": this.ref_fold_list,
                "checkboxflag": this.secondary_search_flag,
                "startYear": this.selectedStartYear,
                "endYear": this.selectedEndYear,
                "multiSpotSelect": this.multiSpot,
                "mss_images": this.mss_images,
                "cropperX1": this.cropper.x1,
                "cropperX2": this.cropper.x2,
                "cropperY1": this.cropper.y1,
                "cropperY2": this.cropper.y2,
                "Spot": this.selectedSpot
            };
            this.spotSelectionParameterArray.push(obj); //pushing spot parameters in array
            this.restService.matchImageCfms(obj).subscribe(function (Response) {
                var temp = JSON.parse(JSON.stringify(Response.body));
                _this.updateService.updateImageResponse(temp);
                _this.tempImageResponse = temp;
                _this.temp_ref_fold_list = temp.ref_plate_folder_list;
                _this.temp_req_id = temp.request_id_list;
                //condition for showing undo button filter
                if (_this.multiSpot == 1) {
                    _this.undoButtonShow = 1;
                }
                else {
                    _this.undoButtonShow = 0;
                }
                //condition for showing year filter
                if (_this.secondary_search_flag == 0 && _this.multiSpot == 0) {
                    _this.showYearFilterButton = 1;
                }
                else {
                    _this.showYearFilterButton = 0;
                }
                //Multispot check box will only appear once the match image is clicked for fresh results
                _this.showMultiSpot = true;
                //Re-adjusting the checkbox after match has been performed
                _this.multiSpotCheckedFlag = false;
                //pushing image response to multi spot response array for undo functionality
                _this.multiSpotResponseArray.push(temp);
                //handling elements in response array when only slider or variation changed but multi spot did not occur
                var multiSpotArraylength = _this.multiSpotResponseArray.length;
                var spotParameterLength = _this.spotSelectionParameterArray.length;
                if (multiSpotArraylength > _this.multiSpotCount + 1) {
                    _this.multiSpotResponseArray.splice(multiSpotArraylength - 2, 1);
                    _this.spotSelectionParameterArray.splice(spotParameterLength - 2, 1);
                }
                //condition to show clear all filter
                if (_this.multiSpotCount > 0) {
                    _this.clearAllShow = 1;
                }
            }, function (error) {
                var matchImageErr = true;
                _this.updateService.updateErrflags(matchImageErr);
            });
        }
        else {
            //Initializing images on top of which multi spot needs to take place
            if (this.multiSpotCount > 0) {
                this.mss_images = this.multiSpotResponseArray[this.multiSpotCount - 1].mss_images;
            }
            var obj_temp = {
                "Spot": this.selectedSpot,
                "Y1": this.Y1,
                "Y2": this.Y2,
                "spot_db": this.spotdb,
                "MP": this.mp,
                "RA": this.ra,
                "intensity_variation": this.percentIntensity,
                "color_variation": this.percentColor,
                "request_id_list": this.req_id,
                "ref_plate_folder_list": this.ref_fold_list,
                "checkboxflag": this.secondary_search_flag,
                "startYear": this.selectedStartYear,
                "endYear": this.selectedEndYear,
                "multiSpotSelect": this.multiSpot,
                "mss_images": this.mss_images
            };
            this.spotSelectionParameterArray.push(obj_temp); //pushing spot parameters in array
            this.restService.matchImage(obj_temp).subscribe(function (Response) {
                var temp = JSON.parse(JSON.stringify(Response.body));
                _this.updateService.updateImageResponse(temp);
                _this.tempImageResponse = temp;
                _this.temp_ref_fold_list = temp.ref_plate_folder_list;
                _this.temp_req_id = temp.request_id_list;
                console.log("printing request_id_list");
                console.log(temp.request_id_list);
                //condition for showing undo button of mutlispot
                if (_this.multiSpot == 1) {
                    _this.undoButtonShow = 1;
                }
                else {
                    _this.undoButtonShow = 0;
                }
                //condition for showing year filter
                if (_this.secondary_search_flag == 0 && _this.multiSpot == 0) {
                    _this.showYearFilterButton = 1;
                }
                else {
                    _this.showYearFilterButton = 0;
                }
                //Multispot check box will only appear once the match image is clicked for fresh results
                _this.showMultiSpot = true;
                //Re-adjusting the checkbox after match has been performed
                _this.multiSpotCheckedFlag = false;
                //pushing image response to multi spot response array for undo functionality
                _this.multiSpotResponseArray.push(temp);
                //handling elements in response array when only slider or variation changed but multi spot did not occur
                var multiSpotArraylength = _this.multiSpotResponseArray.length;
                var spotParameterLength = _this.spotSelectionParameterArray.length;
                if (multiSpotArraylength > _this.multiSpotCount + 1) {
                    _this.multiSpotResponseArray.splice(multiSpotArraylength - 2, 1);
                    _this.spotSelectionParameterArray.splice(spotParameterLength - 2, 1);
                }
                //condition to show clear all filter
                if (_this.multiSpotCount > 0) {
                    _this.clearAllShow = 1;
                }
            }, function (error) {
                var matchImageErr = true;
                _this.updateService.updateErrflags(matchImageErr);
            });
            this.selectedStartYear = this.startYear;
            this.selectedEndYear = this.endYear;
        }
    };
    ImageCropperComponent.prototype.openYearModal = function () {
        if (this.resetYearFilter == 0) {
            this.updateService.displayYearModal(1);
        }
        else {
            this.updateService.displayYearModal(2);
        }
        this.resetYearFilter = 1;
    };
    ImageCropperComponent.prototype.ClearYearFilterfn = function () {
        var _this = this;
        setTimeout(function () {
            _this.clearYearFilter = false;
            _this.updateService.updateClearYearFilter(false);
        }, 1000);
    };
    ImageCropperComponent.prototype.onMultiSpotSelect = function () {
        if (this.multiSpotCheckedFlag) {
            this.multiSpotCheckedFlag = false;
            this.multiSpotCount -= 1;
            if (this.multiSpotCount == 0) {
                this.multiSpot = 0;
            }
        }
        else {
            this.showYearFilterButton = 0;
            this.multiSpotCount += 1;
            this.multiSpotCheckedFlag = true;
            this.multiSpot = 1;
            this.Y1 = 0;
            this.Y2 = 0;
            this.spots.setValue('');
            this.percentColor = 15;
            this.percentIntensity = 5;
            this.cropperVisible = false;
            this.updateService.updateCFMS(0);
        }
    };
    //function to handle undo multi spot button click
    ImageCropperComponent.prototype.undoMutliSpot = function () {
        this.multiSpotCount -= 1;
        this.updateService.updateImageResponseFromUndoMultiSpot(this.multiSpotResponseArray[this.multiSpotCount]);
        this.tempImageResponse = this.multiSpotResponseArray[this.multiSpotCount];
        this.multiSpotResponseArray.pop();
        //fiding undo button when user has reached first spot selection
        if (this.multiSpotCount == 0) {
            this.undoButtonShow = 0;
        }
        //Updating segment slider values
        this.Y1 = this.spotSelectionParameterArray[this.multiSpotCount].Y1;
        this.Y2 = this.spotSelectionParameterArray[this.multiSpotCount].Y2;
        this.updateService.updateSliderForUndoMultispot(this.Y1, this.Y2);
        //Updating variation slider values
        this.percentColor = this.spotSelectionParameterArray[this.multiSpotCount].color_variation;
        this.percentIntensity = this.spotSelectionParameterArray[this.multiSpotCount].intensity_variation;
        //Updating spot number
        this.selectedSpot = this.spotSelectionParameterArray[this.multiSpotCount].Spot;
        console.log(this.selectedSpot);
        this.spots.setValue(this.selectedSpot);
        //Updating spot area if can't find my spot is selected
        if (this.selectedSpot == "Can't find my spot") {
            //this.updateService.updateCFMS(1)
            this.cropperVisible = true;
            this.cropper.x1 = this.spotSelectionParameterArray[this.multiSpotCount].cropperX1;
            this.cropper.x2 = this.spotSelectionParameterArray[this.multiSpotCount].cropperX2;
            this.cropper.y1 = this.spotSelectionParameterArray[this.multiSpotCount].cropperY1;
            this.cropper.y2 = this.spotSelectionParameterArray[this.multiSpotCount].cropperY2;
            this.updateService.updateSliderForUndoMultispot(this.Y1, this.Y2);
        }
        this.spotSelectionParameterArray.pop();
    };
    ImageCropperComponent.prototype.clearAll = function () {
        this.Y1 = 0;
        this.Y2 = 0;
        this.updateService.updateSliderForUndoMultispot(200, 300);
        this.percentColor = 15;
        this.percentIntensity = 5;
        this.spots.setValue('');
        this.multiSpot = 0;
        this.multiSpotResponseArray = [];
        this.spotSelectionParameterArray = [];
        this.multiSpotCount = 0;
        this.cropperVisible = false;
        this.updateService.updateCFMS(0);
        this.clearAllShow = 0;
    };
    return ImageCropperComponent;
}());
export { ImageCropperComponent };
