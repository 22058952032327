
 
   
<div class="col-2 custom-slider" style="height: 100%">
      <ng5-slider [(value)]="verticalSlider2.minValue"
       [(highValue)]="verticalSlider2.maxValue"
        [options]="verticalSlider2.options"
        (userChangeEnd)="onUserChangeEnd($event)"
        ></ng5-slider>
 
</div>

