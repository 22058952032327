import { Component, OnInit } from '@angular/core';
import { RestService } from '../_services/rest.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { ElasticsearchService } from '../_services/elasticsearch.service';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';


import {PagerService} from '../_services/pager.service'
@Component({
  selector: 'app-image-result',
  templateUrl: './image-result.component.html',
  styleUrls: ['./image-result.component.css']
})
export class ImageResultComponent implements OnInit  {
   
  showLoading=0;
  showPlaceholder=1;
  searchBarEnabled=true;
  imageSearchResults:any;
  //Objects for declaring gallery objects
  galleryOptions: NgxGalleryOptions[];
  galleryOptionsMatched: NgxGalleryOptions[];
  galleryOptionsMatchedRef: NgxGalleryOptions[];
  galleryOptionsRef: NgxGalleryOptions[];
  modalGalleryOptions: NgxGalleryOptions[]
  imageModalGallery: NgxGalleryImage[]

  originalResponse:any;
  showImageResults=false;
  //Varibales to show result count near the pagination bar
  resultCount
  startIndex
  endIndex
  modalDisplay='none'
  issueModalDisplay='none'
  confirmationModalDisplay='none'
  failModalDisplay='none'
  Y1=0
  Y2=0
  mp="Not selected"
  ra="Not selected"

  //Variables for language translation
  language='en'

  //report issue modal variables
  modalReqId
  modalImageName
  modalIntensity=5
  modalColor=15
  matchScore
  imagePath
  modalSpot
  croppedImage
  inputImage
  showMoadalContent=1
  showModalLoader=0


//variable for controlling opening and closing of associated images modal
 modalImageList
 imageModalDisplay='none'
 imageModalTitle
 imageModalContent
 displayLoading=false

  // pager object
  pager: any = {};
  
  // paged items
  pagedItems: any[];

  //Query from elastic search bar
  private queryText = '';

  //network error handler
  errorflag=false;

  //Elastic search warning handlers
  public searchFlag = 0;
  elasticSearchNoResultWarning=0;
  matchImageClicked;
  counterElasticSearch=0;

  //No result from match iumage handler
  showNoResults=0
  //Reference plate handlers
  showReferencePlates=0;
  referencePlates
  refPlateCount

  //Variables for handling multiple image array and showing it on screen
  imageResponseArray
  uploadResponseNumber=[]
  showResponseBreadcrumb=0
  //to highlight selected breadcrumb
  highlightSelectedBreadcrumb

  //variable for storing most recent matched result
  currentMatchedResult

    ngOnInit(): void {
      this.language=sessionStorage.getItem("language");
      //galeery options for associated images in matched results
      this.galleryOptions = [
        {
            width: '280px',
            height: '50px',
            thumbnailsRemainingCount:true,
            thumbnailsColumns: 5,
            imageAnimation: NgxGalleryAnimation.Slide,
          image:false,
          imageSize:'4%',
          previewCloseOnClick:true,
          previewZoom:true,
          imageDescription:true,
        }
      
    ];

  //Gallery options for matched image in result
    this.galleryOptionsMatched = [
      {
          width: '60px',
          height: '200px',
          thumbnailsRemainingCount:true,
          thumbnailsColumns: 1,
          imageAnimation: NgxGalleryAnimation.Slide,
        image:false,
        imageSize:'4%',
        previewCloseOnClick:true,
        previewZoom:true,
        imageArrows:false,
        imageDescription:true,
      }
    
  ];

  //Gallery options for modal
  this.modalGalleryOptions=[
    {
      width: '600px',
      height: '600px',
      imageAnimation: NgxGalleryAnimation.Slide,
     imageSize:'4%',
     previewCloseOnClick:true,
     previewZoom:true,
     imageDescription:true,
     thumbnails:false,
     imageArrows:false
  }
   ];

  //Gallery option for matched reference plate
  this.galleryOptionsMatchedRef = [
    {
        width: '60px',
        height: '136px',
        thumbnailsRemainingCount:true,
        thumbnailsColumns: 1,
        imageAnimation: NgxGalleryAnimation.Slide,
      image:false,
      imageSize:'4%',
      previewCloseOnClick:true,
      previewFullscreen:true,
      previewZoom:true,
      imageDescription:true,
    }

  ];

  //Gallery options for associated reference plates

  this.galleryOptionsRef = [
  {
      width: '280px',
      height: '90px',
      thumbnailsRemainingCount:true,
      thumbnailsColumns: 5,
      imageAnimation: NgxGalleryAnimation.Slide,
    image:false,
    imageSize:'4%',
    previewCloseOnClick:true,
    previewFullscreen:true,
    previewZoom:true,
    imageDescription:true,
  }

  ];
      
}


  
  constructor(
    private restService: RestService,
    private translate:TranslateService,
    private pagerService: PagerService,
    private es: ElasticsearchService,
    private updateService:UpdateService) {
//subscribing multiple image response array
this.updateService.imageResponseArray$.subscribe(
  data=>{
    this.imageResponseArray=data;
  }
);

//Subscribing to all the searche image array to control upload breadcrumb

this.updateService.searchedImages$.subscribe(
  data=>{
    var temp=data;
    this.uploadResponseNumber=[];
    for (var i=1;i<=temp.length;i++){
      this.uploadResponseNumber.push(i)
      
    }
    this.highlightSelectedBreadcrumb=this.uploadResponseNumber.length+1
    
    if (temp.length==0){
      this.showResponseBreadcrumb=0
    }else{
      this.showResponseBreadcrumb=1
    }
    
  }
);

//Updating response from direct image match and elastic search
    this.updateService.imageResponse$.subscribe(
      data=>{
        this.errorflag = false;
        this.showNoResults=0;
        this.elasticSearchNoResultWarning=0;
        this.imageSearchResults=data;
        //Handling no match found when image match is clicked
        if (this.imageSearchResults=="No Matches Found"){
          this.showLoading=0
          this.showNoResults=1;
          this.counterElasticSearch=0;
          this.currentMatchedResult=this.imageSearchResults;
        }//Handling response when searche keyword is not present in matched reports
        else{
        if (this.imageSearchResults.overall_response.length==0 && this.counterElasticSearch!=0){
          this.elasticSearchNoResultWarning=1;
          this.counterElasticSearch=0;
          this.imageSearchResults=this.originalResponse
          
        }
        //Condition to store original response and clear existing response when match image button is clicked
        if (this.matchImageClicked==1){
        this.currentMatchedResult=this.imageSearchResults;
        this.originalResponse=this.imageSearchResults;
        this.updateService.updateImageOriginalResponse(this.originalResponse);
        this.matchImageClicked=0;
        this.counterElasticSearch=0;
        this.handleReferencePlate()
        }
        this.counterElasticSearch=this.counterElasticSearch+1;
        
        
        this.showImageResults=true;
        this.showLoading=0;
        this.setPage(1);
        if (this.refPlateCount > 0){
          this.showReferencePlates=1
        }
        

      }
    }
      
    );

//Updating image response from undo of multi spot

this.updateService.imageResponseFromMultiSpot$.subscribe(
  data=>{
    this.errorflag = false;
    this.showNoResults=0;
    this.elasticSearchNoResultWarning=0;
    this.imageSearchResults=data;
   //Handling no match found when image match is clicked
   if (this.imageSearchResults=="No Matches Found"){
    this.showLoading=0
    this.showNoResults=1;
    this.counterElasticSearch=0;
    this.currentMatchedResult=this.imageSearchResults;
  }else{
    this.currentMatchedResult=this.imageSearchResults;
    this.originalResponse=this.imageSearchResults;
    this.updateService.updateImageOriginalResponse(this.originalResponse);
    this.handleReferencePlate();
    this.showImageResults=true;
    this.showLoading=0;
    this.setPage(1);
    if (this.refPlateCount > 0){
        this.showReferencePlates=1
       }
  } 
  }
);

//updating the placeholder image when fresh search is clicked

this.updateService.ImageSearchType$.subscribe(
  data=>{
    if (data=="Fresh"){
      this.showImageResults=false
      this.showPlaceholder=1
    } 
  });
//Updating match image handler
    this.updateService.matchImageClicked$.subscribe(
      data=>{
        this.matchImageClicked=data;
      }
    )
//Updating if error has occured in performing request
    this.updateService.imageMatchErr$.subscribe(
      data=>{
        this.showLoading = 0;
        this.errorflag = data;
      }
    )
//Updating visibility gif loading of beaker gif
    this.updateService.imageShowLoading$.subscribe(
      data=>{
        this.pager={};
        this.pagedItems=[];
        this.imageSearchResults=[];
        this.showPlaceholder=0;
        this.showReferencePlates=0;
        this.showNoResults=0;
        if (this.counterElasticSearch>1){
          this.counterElasticSearch=1;
        }
        this.elasticSearchNoResultWarning=0;
        this.showLoading=data;
        this.errorflag = false;
      }
    );

    //Updating MP RA and Y1 Y2 filters 
    this.updateService.Y1$.subscribe(
      data=>{
          
          this.Y1=data;
      }
  );
  this.updateService.Y2$.subscribe(
    data=>{
        
        this.Y2=data;
    }
);
this.updateService.selectedMP$.subscribe(
    data=>{
      
      this.mp=data
    },
    
  );
  this.updateService.selectedRA$.subscribe(
    data=>{
      this.ra=data
    },
    
  );

  //Updating intensity variation
  this.updateService.intensityVariation$.subscribe(
  data=>{
    this.modalIntensity=data
  }
  );
  //Updating color variation
  this.updateService.colorVariation$.subscribe(
  data=>{
    this.modalColor=data
  }
);
  //Updating spot number
  this.updateService.spotNo$.subscribe(
    data=>{
      this.modalSpot=data
    }
  );
  this.updateService.inputImage$.subscribe(
    data=>{
      this.inputImage=data;
    }
  );
  this.updateService.croppedImage$.subscribe(
    data=>{
      this.croppedImage=data
    }
  );
  this.updateService.spotNo$.subscribe(
    data=>{
      this.modalSpot=data;
    }
  )

  this.updateService.currentLanguage$.subscribe(
    data=>{
        this.language=data;
        translate.use(this.language);
    }
)
    //Code for language selection
    this.language=this.translate.currentLang
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang(sessionStorage.getItem("language"));
    const browserLang = translate.getBrowserLang();
    translate.use(this.language);
   }

   //Reference plate response handler

   handleReferencePlate(){
    
    
    this.showReferencePlates=this.imageSearchResults.reference_plate_matches_flag
    this.referencePlates=this.imageSearchResults.reference_plate_response
    this.refPlateCount=this.referencePlates.length
  
  }

   //Function for settin pagenation
   setPage(page: number) {
    
    // get pager object from service
    this.pager = this.pagerService.getPager(this.imageSearchResults.overall_response.length, page,3);
    // get current page of items
    this.pagedItems = this.imageSearchResults.overall_response.slice(this.pager.startIndex, this.pager.endIndex + 1);
    //Variable to show result number near pagination bar
    this.resultCount=this.imageSearchResults.overall_response.length
    this.startIndex=this.pager.startIndex + 1
    this.endIndex=this.pager.endIndex + 1
    window.scroll(0,0);

  }
//Function for clearing text search
onClearTextSearch(){
  this.counterElasticSearch=1;
  this.imageSearchResults=this.originalResponse;
  this.setPage(1);

}
  //Function for performing elastic search on image page
  search($event) {
    this.searchFlag=1;
    this.showImageResults=false;
    this.pagedItems=[];
    this.pager={};
      this.queryText = $event.target.value;
      var obj={
        "query":this.queryText,
        "request_id_list":this.originalResponse.request_id_list,
        "overall_response":this.originalResponse.overall_response
      };
     
      this.es.imageTextSearch(obj).subscribe(
          Response => {
            this.imageSearchResults={}
            this.imageSearchResults=JSON.parse(JSON.stringify(Response.body));
            this.showImageResults=true;
            this.setPage(1)
          });
  }

  //function for handling report issue modal
  openModal(rid,image_path,match_score){
    this.imagePath=image_path[0].small,
    this.matchScore=match_score
    this.modalReqId=rid
    this.modalImageName=this.mp+"#"+this.ra
    this.issueModalDisplay='block';
  }

  onCloseHandled(){
    this.modalDisplay='none'; 
  }

  onIssueCloseHandled(){
    this.issueModalDisplay='none'
  }

  onConfirmationCloseHandled(){
    this.confirmationModalDisplay='none'; 
  }

  onFailCloseHandled(){
    this.failModalDisplay='none'; 
  }
  submitIssue(userInput){
    var issue={
      "MP":this.mp,
      "RA":this.ra,
      "Y1":this.Y1,
      "Y2":this.Y2,
      "userComment": userInput,
      "spotNo": this.modalSpot,
      "color_variation":this.modalColor,
      "intensity_variation": this.modalIntensity,
      "matchedImage": this.imagePath,
       "inputImage":this.inputImage,
      "croppedImage":"#######",
      "matchedScore":this.matchScore,
      "requestId":this.modalReqId
    }
    this.showMoadalContent=0
    this.showModalLoader=1
    this.restService.submitIssue(issue).subscribe(
      response=>{
        this.issueModalDisplay='none'
        this.confirmationModalDisplay='block'
        this.showMoadalContent=1
        this.showModalLoader=0
      },
      error=>{
        this.issueModalDisplay='none'
        this.failModalDisplay='block'
        this.showMoadalContent=1
        this.showModalLoader=0
      }
    );
     

  }

  setResponse(response){
    
    this.showNoResults=0;
    this.highlightSelectedBreadcrumb=response

    if (response==this.uploadResponseNumber.length+1){
      this.imageSearchResults=this.currentMatchedResult
      if (this.imageSearchResults=="No Matches Found"){
        this.showLoading=0
        this.pager={};
        this.pagedItems=[];
        this.imageSearchResults=[];
        this.showPlaceholder=0;
        this.showReferencePlates=0;
        this.showNoResults=1;
        this.counterElasticSearch=0;
      }else{
      this.handleReferencePlate();
      this.originalResponse=this.imageSearchResults
      this.updateService.updateImageOriginalResponse(this.originalResponse)
      this.setPage(1);
      }
    }else{
    var i=response;
    this.imageSearchResults=this.imageResponseArray[i-1];
    if (this.imageSearchResults=="No Matches Found"){
      this.showLoading=0
      this.pager={};
      this.pagedItems=[];
      this.imageSearchResults=[];
      this.showPlaceholder=0;
      this.showReferencePlates=0;
      this.showNoResults=1;
      this.counterElasticSearch=0;
    }else{
      this.handleReferencePlate();
      this.originalResponse=this.imageSearchResults
      this.updateService.updateImageOriginalResponse(this.originalResponse)
      this.setPage(1);
    }
   
    }

  }

  openImagemodal(associatedImages){
  //  this.modalImageList=associatedImages
  //  this.modalDisplay='block'
  this.displayLoading=true
   var request_payload = {
     "original_path_object_list": associatedImages
   }
   console.log("Show Associated Images");
   console.log(associatedImages);
   
   this.restService.getAssociatedImages(request_payload).subscribe(Response =>{
     
    var temp=JSON.parse(JSON.stringify(Response.body));
    // this.tempImageResponse=temp
    // this.temp_ref_fold_list=temp.ref_plate_folder_list
    // this.temp_req_id=temp.request_id_list
    console.log("printing associated image response");
    console.log(temp);
    this.modalImageList=temp.signed_url_list
    this.modalDisplay='block'
    this.displayLoading= false
    // this.openImage(temp.signed_url_list, temp.signed_url_list[0])
  },
  error =>{
      var matchImageErr = true;
      this.updateService.updateErrflags(matchImageErr);
  });

   }
  
  openImage(imageList, image){
    this.imageModalDisplay='block'
    console.log("Image");
    
    console.log(image);
    console.log("ImageList");
    console.log(imageList);
    
    this.imageModalGallery=[image]

  }
  onCloseImageHandled(){
    this.imageModalDisplay='none'
  }








  
}
