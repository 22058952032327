import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {

//Variable for language translation
language='en'

 panel1=0;
 panel2=0;
 panel3=0;
 panel4=0;
 panel5=0;
 panel6=0;
 panel7=0;
 panel8=0;
 panel9=0;

  constructor(private translate:TranslateService,
    private updateService:UpdateService) {
    this.updateService.currentLanguage$.subscribe(
      data=>{
          this.language=data;
          translate.use(this.language);
      }
  )
  //Code for language selection
  
  this.language=this.translate.currentLang
  translate.addLangs(['en', 'fr']);
  translate.setDefaultLang(sessionStorage.getItem("language"));
  const browserLang = translate.getBrowserLang();
  translate.use(this.language);
   }
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];


  ngOnInit() {
  this.language=sessionStorage.getItem("language");
    this.galleryOptions = [
      {
          width: '700px',
          height: '150px',
          thumbnailsRemainingCount:true,
           thumbnailsColumns: 5,
           imageAnimation: NgxGalleryAnimation.Slide,
         image:false,
         imageSize:'4%',
         previewCloseOnClick:true,
         previewZoom:true,
         imageDescription:true,
         previewDownload: true
      }
    
  ];
  this.galleryImages = [
    {
        small: 'assets/sample1.png',
        medium: 'assets/sample1.png',
        big: 'assets/sample1.png',
        description: "CA-95-5#Anis"
    },
    {
        small: 'assets/sample2.png',
        medium: 'assets/sample2.png',
        big: 'assets/sample2.png',
        description:"CH-80-20#Anis"
    },
    {
        small: 'assets/sample3.png',
        medium: 'assets/sample3.png',
        big: 'assets/sample3.png',
        description:'CM-85-15#JdP'
    }
];
  }
 showPanel(panel){

   if (panel=="panel1"){
    if (this.panel1==1){
      this.panel1=0
    }  else{
      this.panel1=1
    }
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   
   }else if (panel=="panel2"){
    if (this.panel2==1){
      this.panel2=0
    }  else{
      this.panel2=1
    }
    this.panel1=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel3"){
    if (this.panel3==1){
      this.panel3=0
    }  else{
      this.panel3=1
    }
    this.panel1=0
    this.panel2=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel4"){
    if (this.panel4==1){
      this.panel4=0
    }  else{
      this.panel4=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel5"){
    if (this.panel5==1){
      this.panel5=0
    }  else{
      this.panel5=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel6"){
    if (this.panel6==1){
      this.panel6=0
    }  else{
      this.panel6=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel7=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel7"){
    if (this.panel7==1){
      this.panel7=0
    }  else{
      this.panel7=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel8=0
    this.panel9=0
   }else if (panel=="panel8"){
    if (this.panel8==1){
      this.panel8=0
    }  else{
      this.panel8=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel9=0
   }else if (panel=="panel9"){
    if (this.panel9==1){
      this.panel9=0
    }  else{
      this.panel9=1
    }
    this.panel1=0
    this.panel2=0
    this.panel3=0
    this.panel4=0
    this.panel5=0
    this.panel6=0
    this.panel7=0
    this.panel8=0
   }
 }

 
}
