import { Component, OnInit } from '@angular/core';
import { RestService } from '../_services/rest.service';
import {TranslateService} from '@ngx-translate/core'
import { UpdateService } from '../_services/update.service';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
    language="en";
   

  currentUser=JSON.parse(sessionStorage.getItem('currentUser'));
  userName=this.currentUser.name;

  constructor(
    private translate:TranslateService,
    private restService: RestService,
    private updateService: UpdateService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.updateService.currentLanguage$.subscribe(
        data=>{
            this.language=data;
            translate.use(this.language);
        }
    )
  
//Code for language selection
    this.language=this.translate.currentLang
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang(sessionStorage.getItem("language"));
    const browserLang = translate.getBrowserLang();
    translate.use(this.language);
}

ngOnInit() {
}

}
