<div class= "column">
    <div class = "col-md-12" style = "padding-left: 0; padding-right: 0;">
    <app-navbar></app-navbar>
</div>
<div class = "col-md-12" style = "padding-left: 0; padding-right: 0;">
 <mat-tab-group>
<mat-tab [label]= "language=='en'?'Data Ingestion' : 'Ingestion de données'">
  <div class="white-bar"> 
    <h5 style="margin-left:70px;margin-top:20px">{{ 'Admin Console.Follow the instructions given below to upload reports into the cloud' | translate}}</h5>
  </div>
  <div style="margin-top:10%;margin-left:1%;" class = "col-sm-6 reportCont1">
  </div>
<div style="margin-top:112px" class="row imageandsearchCont">
  <div class = "col-sm-5 container1">
    
    <img *ngIf="noFile" src="/assets/upload.gif" style = "width: 64%;
      margin-top: 5%;"/>
    <img *ngIf="uploaded" src="/assets/uploaded.gif" style = "width: 64%; 
      margin-top: 5%; border: 1px dotted darkslategray;"/>
     <img *ngIf="uploadLoading" src="/assets/beaker.gif" style = "width: 64%;
    margin-top: 5%; border: 1px dotted darkslategray;"/>
    <img *ngIf="uploadError" src="/assets/problem-icon.png" style = "height:301.17px;
    margin-top: 5%;" />

    <h5 *ngIf="noFile" style="text-align:center;">
    {{ 'Admin Console.Upload a .zip file containing all the images and report' | translate}}</h5>

    <h5 *ngIf="uploadLoading" style="text-align:center;">
      {{ 'Admin Console.Please wait while your files are being uploaded...' | translate}}</h5>

    <h5 *ngIf="uploaded" style="text-align:center;">
      {{ 'Admin Console.All files have been successfully uploaded!' | translate}}</h5>

    <h5 *ngIf="uploadError" style="text-align:center;">
        {{ 'Admin Console.There was an error in uploading the file' | translate}}</h5>


      
  <!--Button for uploading TLC image      -->
    <div class="button-wrapper" style="margin-top: 5%">
      <a style="color:white" [attr.href]="bucket_url" target="_blank">
  <!--   (change)="onSelectFile($event)" " clickInputTag() "-->
    <button  class="button" style="color:white;background-color: #2577A7" (click)="openValidateModal()" >
    
    <input 
      type="file"
      
      id="files"
      style="display:none;"
      accept="application/zip"
      #fileInput multiple>{{ 'Admin Console.Upload' | translate}}
    </button>
  </a>
    </div>
    </div> 
    <div class="col-sm-5 container">
      <div>
        <h3>Instructions</h3>
      </div>
      <div class="modal-header"></div>
      <div *ngIf="language=='en'" class="column instructions">
      <ol>
      <li> Create folders of request IDs that are to be uploaded on the cloud</li>
      <li> Each folder must only contain files associated with that request ID</li>
      <li> Each folder should be named in the format of its corresponding request ID i.e, YY followed by 5 digit request number(eg: 1912345)</li>
      <li> Document inside the folder must have the same name as the folder</li>
      <li> Each folder should only contain 1 final report in ".docx" or ".doc" format </li>
      <li> Each folder should have the images associated to that request ID in .png/.jpg/.tif format</li>
      <li> The images should be named in MP#RA format</li>
      <li> Once the folder has been created, click on upload and select the file to be uploaded</li>
       </ol>
    </div>
    <div *ngIf="language=='fr'" class="column instructions">
      <ol>
      <li> Créer des dossiers d'ID de demande à télécharger sur le cloud.</li>
      <li> Chaque dossier ne doit contenir que des fichiers associés à cet ID de demande.</li>
      <li> Chaque dossier doit être nommé dans le format de son ID de demande correspondant, à savoir YY, suivi d'un numéro de demande à 5 chiffres (par exemple: 1912345)</li>
      <li> Le document dans le dossier doit avoir le même nom que le dossier</li>
      <li> Chaque dossier ne doit contenir qu'un seul rapport final au format ".docx" ou ".doc". </li>
      <li> Les images associées à cet ID de demande doivent être associées à chaque dossier au format .png / .jpg / .tif.</li>
      <li> Les images doivent être nommées au format MP # RA</li>
      <li> Une fois le fichier dossier créé, cliquez sur télécharger et sélectionnez le fichier à télécharger.</li>
       </ol>
    </div>
    </div>
    </div>
    </mat-tab>
    <mat-tab [label]= "language=='en'?'Access Request' : 'Demandes d\'accès'">
        <div class="white-bar"> 
          <h5 style="margin-left:92px;margin-top:20px">{{ 'Admin Console.This tab shows the details of all the users requesting access into the tool' | translate}}</h5>
        </div>
      <div style="margin-top:10%;margin-left:1%;" class = "col-sm-6 reportCont1">
      </div>
    <div style="margin-top:112px" class="row imageandsearchCont">
    <div class="container2 col-sm-8">
        <div style="margin-top:11%;margin-bottom: 11%" *ngIf="ELEMENT_DATA.length==0">
        <i class="fa fa-users fa-5x" aria-hidden="true"></i>
        <h3>{{ 'Admin Console.No user registration requests pending' | translate}}</h3>
        </div>
        <div *ngIf="ELEMENT_DATA.length>0" class="container3 col-sm-8">
        <table >
            <tr>
              <th style="width:80px">{{ 'Admin Console.Sr. No.' | translate}}</th>
              <th style="width:80px">{{ 'Admin Console.Name' | translate}}</th>
              <th style="width:94px">{{ 'Admin Console.Designation' | translate}}</th>
              <th style="width:220px">{{ 'Admin Console.Email ID' | translate}}</th>
              <th style="width:80px">{{ 'Admin Console.Accept' | translate}}</th>
              <th style="width:80px">{{ 'Admin Console.Reject' | translate}}</th>
            </tr>
                <tr *ngFor="let char of ELEMENT_DATA;let row = index">
                    <td  *ngFor="let col of displayedColumns">
                      {{char[col]}}
                    </td>
                    <td ><a href="https://groups.google.com/a/test.solvay.com/forum/#!managemembers/tlcautomation-admin/members/active" target="_blank">
                    <button style="height:30px" mat-raised-button >
                      <i style="height:21px"class="fa fa-check" aria-hidden="true"></i></button>
                    </a></td>
                    <td (click)="accessReject(row)" ><button style="height:30px"  mat-raised-button >
                      <i class="fa fa-times" aria-hidden="true"></i></button></td>
                  </tr>
                </table>
              </div>
              </div>
            </div>
          </mat-tab> 
          </mat-tab-group>
          </div>
          </div>

 <!--Modal for displaying access error message-->
 <div class="backdrop" [ngStyle]="{'display':validateModal}"></div>
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':validateModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header">
        <b class="modal-title">Validation Required:</b>
      </div>
      <div style="height:auto" class="modal-body" > 
      <h5 style="text-align:center">Click on the button to Validate the files</h5>
      <div style="text-align:center">
      <button 
        *ngIf="!validationLoading"
        class="button"
        style="color:white;background-color: #2577A7"
        (click)="validateData()" >
      Validate</button>
      <img *ngIf="validationLoading" src="/assets/beaker.gif" style = "width: 40%;
      margin-top: 5%;"/>
      </div>
      
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
 </div><!-- /.modal !-->

  <!--Modal for displaying access error message-->
  <div class="backdrop" [ngStyle]="{'display':validated}"></div>
  <div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':validated}">
    <div class="modal-dialog" role="document">
      <div style="width:50em;margin-left:-150px" class="modal-content">
      <div style="height:30px;padding:5px" class="modal-header">
          <b class="modal-title">Following is the list of validated files and errors:</b>
          <a (click)="closeValidatedModal()" style="position: absolute;right:25px;">
          <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
          </a>
        </div>
        <div style="max-height: 40em;overflow:auto;padding: 15px" class="modal-body" > 
        <h5 *ngIf='!emptyDrive'>No folder uploaded or Uploaded folder name has already been validated in the past. Make sure to upload an un-used folder name</h5>
        <h5 *ngIf='correctFolders.length>0'>These folders have been validated : <table><tr *ngFor= "let row of correctFolders"> {{row}}</tr></table></h5>
        <h5 *ngIf="img_name_error.length==0 && emptyDrive">Image names are correct <i class = "fa fa-check fa-lg" style="color:green"></i></h5>
        <h5 *ngIf="fold_name_error.length==0  && emptyDrive">Folder structure is correct <i class = "fa fa-check fa-lg" style="color:green"></i></h5>
        <h5 *ngIf="doc_name_error.length==0 && emptyDrive">Document names are correct <i class = "fa fa-check fa-lg" style="color:green"></i></h5>
        <h5 *ngIf="doc_ext_error.length==0 && emptyDrive">Document extentions are correct <i class = "fa fa-check fa-lg" style="color:green"></i></h5>
        <h5 *ngIf='incorrectFolders.length>0'>These folders have error in them : {{incorrectFolders}}</h5>
        <h5 *ngIf='incorrectFolders.length>0'>Please make the necessary corrections and upload them again.</h5>
     <div style="margin-top:5px" *ngIf="img_name_error.length>0"> <b>Image name errors :</b> <table><tr *ngFor= "let row of img_name_error"> {{row}}</tr></table><br></div>
     <div style="margin-top:5px" *ngIf="doc_ext_error.length>0 "> <b>Incorrect document extensions:</b>  <table><tr *ngFor= "let row of doc_ext_error"> {{row}}</tr></table><br></div>
     <div style="margin-top:5px" *ngIf="doc_name_error.length>0"> <b>Doc name does not correspond to folder:</b> <table><tr *ngFor= "let row of doc_name_error"> {{row}}</tr></table><br></div>
     <div style="margin-top:5px" *ngIf="fold_name_error.length>0"> <b>Folder structure is incorrect:</b> <table><tr *ngFor= "let row of fold_name_error"> {{row}}</tr></table><br></div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
   </div><!-- /.modal !-->
