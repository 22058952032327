import { Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { RestService } from '../_services/rest.service';
import {TranslateService} from '@ngx-translate/core'
import { UpdateService } from '../_services/update.service';
@Component({
  selector: 'app-jumbo-keyword',
  templateUrl: './jumbo-keyword.component.html',
  styleUrls: ['./jumbo-keyword.component.css']
})
export class JumboKeywordComponent implements OnInit {
  
  Control_Type = new FormControl();
  Control_Keyword = new FormControl();
  options_Type: string[]=["ADDITIF","APPLICATION","DETERMINATION","ELEMENT","IMPUTATION","GESTAIONNAIRE","NON COMMERCIAL","POLYMER","TECHNIQUE ANALYTIQUE"];
  options_Keyword: string[] =[];
  filteredOptions_Type: Observable<string[]>;
  filteredOptions_Keyword: Observable<string[]>;
  type: string;

  //Variable for language translation
  language='en'

  //year filter reset
  yearFilter=0;
  constructor(
    private restService: RestService,
    private translate:TranslateService,
    private updateService:UpdateService){
    this.updateService.currentLanguage$.subscribe(
      data=>{
          this.language=data;
          translate.use(this.language);
      }
  )
      //Code for language selection
      this.language=this.translate.currentLang
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang(sessionStorage.getItem("language"));
      const browserLang = translate.getBrowserLang();
      translate.use(this.language);
  }
  ngOnInit() {
    this.language=sessionStorage.getItem("language");
    
    this.filteredOptions_Type = this.Control_Type.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter_Type(value))
      );

  }

  populateKeyword(){
    this.filteredOptions_Keyword = this.Control_Keyword.valueChanges.pipe(
    startWith(null),
    map((fruit: string | null) => fruit ? this._filter_Keyword(fruit) : this.options_Keyword.slice()));
  }

  private _filter_Type(value: string): string[] {
    const filterValue_Type = value.toLowerCase();

    return this.options_Type.filter(option => option.toLowerCase().includes(filterValue_Type));
  }

    private _filter_Keyword(value: string): string[] {
    const filterValue_Keyword = value.toLowerCase();

    return this.options_Keyword.filter(option => option.toLowerCase().indexOf(filterValue_Keyword)===0);
  }

  typeSelected(event){


this.type=event.option.value;

if (this.type=="ADDITIF"){
  var obj={
    "type": "ADD"
  }

}else if (this.type=="DETERMINATION"){

  var obj={
    "type": "DET"
  }
}else if (this.type=="ELEMENT"){

  var obj={
    "type": "ELE"
  }
}else if (this.type=="IMPUTATION"){
  var obj={
    "type": "IMP"
  }
}else if(this.type=="GESTAIONNAIRE"){
  var obj={
    "type": "GES"
  }
}else if (this.type=="NON COMMERCIAL"){
  var obj={
    "type": "NC"
  }
}else if (this.type=="POLYMER"){
  var obj={
    "type": "POL"
  }
}else if (this.type=="TECHNIQUE ANALYTIQUE"){
  var obj={
    "type": "TEC"
  }
}else if (this.type=="APPLICATION"){
  var obj={
    "type": "APP"
  }
}



//Getting Keyword values
this.restService.getJumboKeyword(obj).subscribe(Response =>{
  this.options_Keyword=[];
  var temp=JSON.parse(JSON.stringify(Response.body));
  for (var i = 0; i < temp.length; i++){
    this.options_Keyword.push(temp[i].Keyword)
  }
  this.populateKeyword()

});

  
  }

keywordSelected($event){
  this.updateService.updateSelectedJumboKeyword($event.option.value)
}

clickHandlerKeyWord(){
  this.Control_Keyword.setValue('')
  
}
clickHandlerType(){
  this.Control_Type.setValue('')
  this.updateService.resetYearFilter(this.yearFilter)
  this.yearFilter=1;
}
  

}
