<div class= "column">
  <div class = "col-md-12" style = "padding-left: 0; padding-right: 0;">
     <app-navbar></app-navbar>
  </div>
  <div class="container" style="margin-top:8%">
    
    <div class="instruction-group">
      <div class="panel-body" style="background-color: white;border-radius: 8px">
        <h3>{{ 'Instructions.Sample Images' | translate}}</h3>
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
        <p>{{ 'Instructions.Click on the thumbnail to preview and download sample image' | translate}}</p>
        <hr>
      
        <!--Text wrapped inside a clickable panel button here-->
      <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel1')"><h3>I. Instructions for Image Matching<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
     
      <div *ngIf="language=='en'" class="panel" [style.display]="panel1==1?'block':'none'">
      <p><b>Step 1: </b> Select 'MP' and 'RA' Filter</p>
      <img src = "assets/Selecting MP-RA.png" class="image" style = "height:16em;width:680px"> 
      <p><b>Step 2: </b> After selecting 'MP' and 'RA', 'Select TLC' button will become active. Click on it to select a TLC plate.</p>
      <p> After your selection, tool will crop out the plate from image and highlight identified spot. Segment slider,spot selection dropdown and Intensity and Color variation adjustment sliders will appear adjacent to the image</p>
      <p><b>Step 3: </b>Adjust the slider on the left side of image to select the segment in which spot is to be searched</p>
      <p><b>Step 4: </b> Select a spot from dropdown and adjust Intensity and Color Variation sliders as per your choice, then click 'Match Image'</p>
      <img src = "assets/Selected TLC Plate.png" class="image" style = "height:20em;width:680px"/>
      <p>Matched Images will appear on right side of the page with 'Reference Plates' appearing at the top. Matched request IDs appear in decreasing order of their match score</p>
      <img src = "assets/Matched Images and Reports.png" class="image" style = "height:20em;width:680px"/> 
    </div>
    <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel1')"><h3>I. Instructions pour la correspondance d'images<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
     
      <div *ngIf="language=='fr'" class="panel" [style.display]="panel1==1?'block':'none'">
      <p><b>Étape 1: </b>Sélectionnez les filtres 'MP' et 'RA'</p>
      <img src = "assets/Selecting MP-RA.png" class="image" style = "height:16em;width:680px"> 
      <p><b>Étape 2: </b> Après avoir sélectionné 'MP' et 'RA', le bouton 'Sélectionnez CCM' sera activé. Cliquez dessus pour sélectionner une plaque TLC.</p>
      <p>Une fois votre sélection effectuée, l’outil supprime la plaque de l’image et met en évidence le point identifié. Le curseur de segment, la liste déroulante de sélection de point et les curseurs d'intensité et de variation de couleur apparaissent en regard de l'image.</p>
      <p><b>Étape 3: </b>Ajuster le curseur sur le côté gauche de l'image pour sélectionner le segment dans lequel vous souhaitez rechercher un point</p>
      <p><b>Étape 4: </b>Sélectionnez un endroit dans la liste déroulante et ajuster les curseurs Intensité et Variation de couleur selon votre choix, puis cliquez sur "Faire correspondre l'image".</p>
      <img src = "assets/Selected TLC Plate.png" class="image" style = "height:20em;width:680px"/>
      <p>Les images correspondantes apparaîtront à droite de la page avec les "Plaques de référence" apparaissant en haut. Les ID de demande appariés apparaissent dans l'ordre décroissant de leur score.</p>
      <img src = "assets/Matched Images and Reports.png" class="image" style = "height:20em;width:680px"/> 
    </div>
     
      <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel2')"><h3>II. Selecting a spot when it hasn't been highlighted by the tool<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
      
      <div *ngIf="language=='en'" class="panel" [style.display]="panel2==1?'block':'none'">
        <p><b>Step 1: </b> From the spot selection dropdown select 'Can't find my spot' </p>
        <p>An adjustable box with dotted boundary will appear over the image</p>
        <p><b>Step 2: </b>Move the box using mouse and adjust its size so that the spot that you want to match is at the centre of the box</p>
        <p><b>Step 3: </b>Click 'Match Image'. Matched Images will appear on right side of the page with 'Reference Plates' appearing at the top. Matched request IDs appear in decreasing order of their match score</p>
        <img src = "assets/cant find my spot.png" class="image" style = "height:35em;"/> 
        </div>
      
      <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel2')"><h3>II. Sélection d'un tacher qui n'a pas été mis en évidence par l'outil<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
    
      <div *ngIf="language=='fr'" class="panel" [style.display]="panel2==1?'block':'none'">
        <p><b>Étape 1: </b> Dans le menu déroulant de sélection de points, sélectionnez "Impossible de trouver mon tacher" </p>
        <p>Une boîte ajustable avec une limite en pointillé apparaîtra sur l'image</p>
        <p><b>Étape 2: </b>Déplacez la boîte à l’aide de la souris et ajustez sa taille de sorte que le tacher que vous souhaitez faire correspondre se trouve au centre de la boîte.</p>
        <p><b>Étape 3: </b>Cliquez sur "Match Image". Les images correspondantes apparaîtront à droite de la page avec les "Plaques de référence" apparaissant en haut. Les ID de demande appariés apparaissent dans l'ordre décroissant de leur score.</p>
        <img src = "assets/cant find my spot.png" class="image" style = "height:35em;"/> 
        </div>

      <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel3')"><h3>III. Performing text search to filter matched images<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
      
      <div *ngIf="language=='en'" class="panel" [style.display]="panel3==1?'block':'none'">
      <p><b>Step 1: </b>After matched images have been populated, use 'Cognitive Search' at the top right corner of the screen to search for keywords in the matched reports</p>
      <p>Reports having searched keyword will be filtered with the searched keyword being highlighted in blue color</p>
      <p><b>Step 2: </b>To get back to the initial result, click on the 'Clear Text Search' button at the top left corner of the section</p>
      <img src = "assets/Text search on matched images.png" class="image" style = "height:35em;width:680px"/> 
      </div>

      <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel3')"><h3>III. Effectuer une recherche de texte pour filtrer les images correspondantes<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
      
      <div *ngIf="language=='fr'" class="panel" [style.display]="panel3==1?'block':'none'">
      <p><b>Étape 1: </b>Une fois les images correspondantes terminées, utilisez "Recherche cognitive" dans le coin supérieur droit de l'écran pour rechercher des mots-clés dans les rapports correspondants.</p>
      <p>Les rapports ayant rechercher un mot clé seront filtrés, le mot clé recherché étant surligné en bleu</p>
      <p><b>Étape 2: </b>Pour revenir au résultat initial, cliquez sur le bouton "Effacer la recherche de texte" dans le coin supérieur gauche de la section.</p>
      <img src = "assets/Text search on matched images.png" class="image" style = "height:35em;width:680px"/> 
      </div>

      
        
      <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel4')"><h3>IV. Performing Multi Image Search<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
  
      <div *ngIf="language=='en'" class="panel" [style.display]="panel4==1?'block':'none'">
        <p><b>Step 1: </b>Perform regular image search as described in 'I'</p>
        <p><b>Step 2: </b>Click on 'Select TLC' button, a pop will appear asking you to perform 'Fresh search' or 'Filter Current Results'</p>
        <img src = "assets/fresh search or filter results.png" class="image" style = "height:16em"/>
        <p>To perform multiple image search, select 'Filter Current Result' and then select your required plate</p>
        <p><b>Step 3: </b>Just like before, image will be cropped and identified box will be highlighted.</p>
        <p>The previous image will appear at the bottom of the page. Adjust slider, select spot and select intensity and color variation then click match image</p>
        <p>This time the match the will be carried out on the matched results of previous search</p>
        <img src = "assets/multi image search.png" class="image" style = "height:35em;width:680px"/> 
        <p>To perform multiple image search again, follow the above steps</p>
        </div>
    
      <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel4')"><h3>IV. Effectuer une recherche d'images multiples<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>

      <div *ngIf="language=='fr'" class="panel" [style.display]="panel4==1?'block':'none'">
        <p><b>Étape 1: </b>Effectuez une recherche d'image régulière comme décrit dans la section 'I'</p>
        <p><b>Étape 2: </b>Cliquez sur le bouton 'Sélectionnez CCM', un pop-up apparaîtra vous demandant de réaliser une 'recherche récente' ou un 'filtre des résultats actuels</p>
        <img src = "assets/fresh search or filter results.png" class="image" style = "height:16em"/>
        <p>Pour effectuer plusieurs recherches d'images, sélectionner «Filtrer le résultat actuel», puis sélectionnez la plaque souhaitée.</p>
        <p><b>Étape 3: </b>Comme avant, l'image sera recadrée et la case identifiée sera surlignée</p>
        <p>L'image précédente apparaît au bas de la page. Ajuster le curseur à sélectionnez un point, sélectionnez l'intensité et la variation de couleur, puis cliquez sur l'image correspondante</p>
        <p>Cette fois le match sera effectué sur les résultats correspondants de la recherche précédente</p>
        <img src = "assets/multi image search.png" class="image" style = "height:35em;width:680px"/> 
        <p>Pour effectuer à nouveau plusieurs recherches d’images, suivez les étapes ci-dessus.</p>
        </div>


        <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel5')"><h3>V. Report an Issue<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='en'" class="panel" [style.display]="panel5==1?'block':'none'">
        <p><b>Step 1: </b>With every matched result <i class = "fa fa-exclamation-triangle fa-lg"></i> icon appear at the top right corner. Click on it</p>
        <p><b>Step 2: </b>A pop up will appear indicating your selected filters along with a comment box. Type in your exact issue and click "Submit".</p>
        <img src = "assets/report issue.png" class="image" style = "height:35em;width:680px"/> 
        </div>  

        <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel5')"><h3>V. Signaler un problème<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='fr'" class="panel" [style.display]="panel5==1?'block':'none'">
        <p><b>Étape 1: </b>Avec chaque résultat correspondu <i class = "fa fa-exclamation-triangle fa-lg"></i> l'icône apparaît dans le coin en haut à droite. Clique dessus</p>
        <p><b>Étape 2: </b>Une fenêtre contextuelle apparaît, indiquant les filtres sélectionnés, ainsi qu'une zone de commentaire. Tapez votre problème exact et cliquez sur "Soumettre".</p>
        <img src = "assets/report issue.png" class="image" style = "height:35em;width:680px"/> 
        </div>  

        <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel6')"><h3>VI. Searching a report using free text<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='en'" class="panel" [style.display]="panel6==1?'block':'none'">
        <p><b>Step 1: </b>Click on the 'Report' Tab in the Nav Bar</p>
        <p><b>Step 2: </b>Note that the 'Free Text Search' radio button is selected by default</p>
        <p><b>Step 3: </b>Type the keyword you want to search and hit enter or click on the search icon</p> 
        <img src = "assets/search report.png" class="image" style = "height:35em;width:680px"/>  
        </div>  
        
        <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel6')"><h3>VI. Recherche d'un rapport en texte libre<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        <div *ngIf="language=='fr'" class="panel" [style.display]="panel6==1?'block':'none'">
        <p><b>Étape 1: </b>Cliquez sur l'onglet "Rapport" dans la barre de navigation.</p>
        <p><b>Étape 2: </b>Notez que le bouton radio "Recherche de texte libre" est sélectionné par défaut</p>
        <p><b>Étape 3: </b>apez le mot-clé que vous souhaitez rechercher et appuyez sur Entrée ou cliquez sur l'icône de recherche.</p> 
        <img src = "assets/search report.png" class="image" style = "height:35em;width:680px"/> 
        </div> 
         
      <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel7')"><h3>VII. Performing Jumbo Search<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
  
      <div *ngIf="language=='en'" class="panel" [style.display]="panel7==1?'block':'none'">
        <p><b>Step 1: </b>Click on the 'Jumbo Keyword Search' Radio button in the Report tab</p>
        <img src = "assets/jumbo keyword selection.png" class="image" style = "height:20em"/> 
        <p><b>Step 2: </b>Select the type of keyword you want to search for from the 'Select Type' Dropdown</p>
        <p><b>Step 3: </b>Select the exact keyword in the chosen type to be searched for</p>
        <img src = "assets/jumbo search dropdown.png" class="image" style = "height:22em;width:670px"/> 
        <p>Corresponding reports are displayed with respect to the keywords entered</p>
        <img src = "assets/jumbo search results.png" class="image" style = "height:22em;width:670px"/> 
        <p>You can search for multiple keywords within the same report by adding more keywords and clear
          them by clicking on the cross icon on the chip </p>
        </div>

      <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel7')"><h3>VII. Effectuer une recherche jumbo<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>

      <div *ngIf="language=='fr'" class="panel" [style.display]="panel7==1?'block':'none'">
        <p><b>Étape 1: </b>Cliquez sur le bouton radio "Recherche par mots clés" dans l'onglet "Rapport".</p>
        <img src = "assets/jumbo keyword selection.png" class="image" style = "height:20em"/> 
        <p><b>Étape 2: </b>Sélectionnez le type de mot clé que vous souhaitez rechercher dans la liste déroulante "Sélectionner le type".</p>
        <p><b>Étape 3: </b>Sélectionnez le mot-clé exact dans le type choisi à rechercher</p>
        <img src = "assets/jumbo search dropdown.png" class="image" style = "height:22em;width:670px"/> 
        <p>Les rapports correspondants sont affichés en fonction des mots-clés entrés.</p>
        <img src = "assets/jumbo search results.png" class="image" style = "height:22em;width:670px"/> 
        <p>Vous pouvez rechercher plusieurs mots-clés dans le même rapport en ajoutant d'autres mots-clés et en les supprimant en cliquant sur l'icône représentant une croix dans la puce. </p>
        </div>

        <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel8')"><h3>VIII. Searching a Reference Plate<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='en'" class="panel" [style.display]="panel8==1?'block':'none'">
        <p><b>Step 1: </b>Click on the 'Reference Plate' Tab in the Nav Bar</p>
        <p><b>Step 2: </b>Select the folder you want to search the plate from using the Dropdown</p>
        <img src = "assets/reference plate.png" class="image" style = "height:20em"/> 
        </div>

        <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel8')"><h3>VIII. Recherche d'une plaque de référence<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='fr'" class="panel" [style.display]="panel8==1?'block':'none'">
        <p><b>Étape 1: </b>Cliquez sur l'onglet 'Référence' dans la barre de navigation.</p>
        <p><b>Étape 2: </b>Sélectionnez le dossier dans lequel vous souhaitez rechercher la plaque à l'aide du menu déroulant.</p>
        <img src = "assets/reference plate.png" class="image" style = "height:20em"/> 
        </div>
        
        <button *ngIf="language=='en'" class="accordion" (click)="showPanel('panel9')"><h3>IX. Viewing and Downloading Report<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
        
        <div *ngIf="language=='en'" class="panel" [style.display]="panel9==1?'block':'none'">
          <p><b>Step 1: </b>To View a report, click on the Request ID. A new tab with report will open</p>
          <p><b>Step 2: </b>To download a report, click on the <i class="fa fa-download fa-lg" ></i> icon at the top right corner of the matched result</p>
          </div>

        <button *ngIf="language=='fr'" class="accordion" (click)="showPanel('panel9')"><h3>IX. Voir et télécharger le rapport<i class="fa fa-plus" style="float:right" aria-hidden="true"></i></h3></button>
      
        <div *ngIf="language=='fr'" class="panel" [style.display]="panel9==1?'block':'none'">
          <p><b>Étape 1: </b>Pour afficher un rapport, cliquez sur l'ID de la demande. Un nouvel onglet avec rapport s'ouvrira</p>
          <p><b>Étape 2: </b>Pour télécharger un rapport, cliquez sur le bouton <i class="fa fa-download fa-lg" ></i> icône dans le coin supérieur droit du résultat correspondant</p>
          </div>
      
        </div>
    </div>
  </div>
</div>

