/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-login.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./google-login.component";
import * as i4 from "../_services/users.service";
import * as i5 from "@angular/router";
import * as i6 from "../_services/update.service";
var styles_GoogleLoginComponent = [i0.styles];
var RenderType_GoogleLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleLoginComponent, data: {} });
export { RenderType_GoogleLoginComponent as RenderType_GoogleLoginComponent };
function View_GoogleLoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-login-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have permission to login. Please try a different id or register to get acess"]))], null, null); }
function View_GoogleLoginComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "loginBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["style", "margin-top:5px;margin-bottom: 25px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login/Connexion"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "google-button"], ["style", "width: fit-content; margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["data-toggle", "tooltip"], ["tabindex", "0"], ["title", "Request access to the tool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "login-button btn-default access"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.register() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["style", "text-decoration:underline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Request Access"]))], null, null); }
function View_GoogleLoginComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "loginBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "loading-gif"], ["src", "assets/loading.gif"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "margin-top: -9%; color: gray;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logging in..."]))], null, null); }
export function View_GoogleLoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoogleLoginComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo-tool"], ["src", "assets/research catalyst-logo-VF1.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoogleLoginComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GoogleLoginComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.invalidLoginShow == 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.loadingFlag == 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.loadingFlag == 1); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_GoogleLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-google-login", [], null, null, null, View_GoogleLoginComponent_0, RenderType_GoogleLoginComponent)), i1.ɵdid(1, 4308992, null, 0, i3.GoogleLoginComponent, [i4.UsersService, i5.Router, i6.UpdateService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleLoginComponentNgFactory = i1.ɵccf("app-google-login", i3.GoogleLoginComponent, View_GoogleLoginComponent_Host_0, {}, {}, []);
export { GoogleLoginComponentNgFactory as GoogleLoginComponentNgFactory };
