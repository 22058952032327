import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../_services/users.service';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';


declare var google: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  currentUser=JSON.parse(sessionStorage.getItem('currentUser'));
  timer :number //=parseInt(sessionStorage.getItem('timer'));
  refresh_token = JSON.stringify(sessionStorage.getItem('refresh_token'));
  userName=this.currentUser.name;

  id_token = "";
  private user = undefined;

  userType=this.currentUser.type;
  new_id_token;
  expires_in;
  greeting=this.userName

    title = '';
    loggedIn=false;
    accessErrorFlag=false
    englishSelected=true;
    frenchSelected=false;

    //Radio button label variable
    labelPosition 

    //Modal for asking user selection of search type
    modalDisplay='none'
    

    //Modal for asking user selection of language
    language='en'
    languageModalDisplay='none'

    //Modal for showing access error
    accessModalDisplay='none'


    Auth_backend="https://backend-dot-gcp-sqo-ri-tlc-d.appspot.com";
    refresh_token_flag=false;

    private guiclt_id: string;

    constructor(
        private router: Router,
        private translate:TranslateService,
        private userservice:UsersService,
        private updateService:UpdateService){
        updateService.loggedIn$.subscribe(
            data=>{
              this.loggedIn=data;
            }
          )
        userservice.expiryTime$.subscribe(
             data=>{
                // reducint the expiry timer by 5mins
                 this.timer=parseInt(data)-300;
                 console.log("Updated timer value:", this.timer);
            }
          );
          this.updateService.currentLanguage$.subscribe(
            data=>{
                this.language=data;
                translate.use(this.language);
            }
        )
        
        this.guiclt_id = userservice.client_id;
        //Code for language selection
        
        this.language=this.translate.currentLang
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang(sessionStorage.getItem("language"));
        const browserLang = translate.getBrowserLang();
        translate.use(this.language);
        }
    logout(){
        
        this.loggedIn=false;
        this.userservice.signOut()
        this.router.navigate(['/login']);
    }

    openAdminConsole(){
        if(this.userType=="admin"){
        window.open('#/admin-console')
       // this.router.navigate(['/admin-console'])
         }
        else{
            this.accessModalDisplay='block'
        }

    }

    closeAccessErrorModal(){
        this.accessModalDisplay='none'
    }
    

    openFAQ(){
        window.open("assets/FAQ document.pdf", "_blank");
    }
    selectEnglish(){
        this.frenchSelected=false;
        this.englishSelected=true;
        sessionStorage.setItem("language","en");
        this.updateService.updateLanguage();
        this.onCloseLanguageHandle()
    }
    selectFrench(){
        this.englishSelected=false;
        this.frenchSelected=true;
        sessionStorage.setItem("language","fr");
        this.updateService.updateLanguage();
        this.onCloseLanguageHandle()
    }
    //function for handling user selection modal
  openModal(){
    console.log("opeingin login modal");
   this.labelPosition=null
   this.modalDisplay='block';
   document.getElementById('sign-in-modal').style.display = 'block';
   document.getElementById('sign-in-modal-backdrop').style.display = 'block';
   
  }

  onCloseHandled(){   
   this.modalDisplay='none'; 
   document.getElementById('sign-in-modal').style.display = 'none';
   document.getElementById('sign-in-modal-backdrop').style.display = 'none';
  }

  onCloseLanguageHandle(){
   this.languageModalDisplay='none'
  }

  //Handling radio button click
  loginAgain(){
    this.labelPosition=="login";
    this.userservice.LoginModalFlag();
    this.modalDisplay='none';
    document.getElementById('sign-in-modal').style.display = 'none';
    document.getElementById('sign-in-modal-backdrop').style.display = 'none';

  }

    
    logoutFromModal(){
        this.userservice.signOut();
        this.modalDisplay='none';
        document.getElementById('sign-in-modal').style.display = 'none';
        document.getElementById('sign-in-modal-backdrop').style.display = 'none';
    }
    

    ngOnInit(){
        this.accessErrorFlag=false;
        this.language=sessionStorage.getItem("language");        
        if(sessionStorage.getItem("select language")=='yes'){
            this.languageModalDisplay='block';
            sessionStorage.setItem("select language","no");
        }
       setInterval(()=> {
        this.userservice.updateTimer()
        var display = getComputedStyle(document.getElementById('sign-in-modal')).getPropertyValue('display') //to check if the signin again pop-up is open or not
        if (this.timer && ((Date.now()/1000) > this.timer) && display=='none'){
             this.openModal()}          
        },45000);
        
    }
    
    // ngAfterViewInit(): void {
    //   this.loadGoogleScript().then(() => {
    //     this.renderGoogleSignInButton();
    //   });
    // }

    loadGoogleScript(): Promise<void> {
      return new Promise((resolve, reject) => {
        if (document.getElementById('google-js')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.id = 'google-js';
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.body.appendChild(script);
      });
    }

    renderGoogleSignInButton(): void {
        google.accounts.id.initialize({
          client_id: this.guiclt_id,
          prompt: 'none',
          scope: 'profile email',
          auto_select: true,
          callback: this.handleCredentialResponse
        });
        google.accounts.id.prompt();
    }

    handleCredentialResponse(response) {
      this.modalDisplay='none';
      this.id_token = response.credential;
      // Send the ID token to your backend for verification and user creation/login
      //console.log("ID Token: ", this.id_token);
      //this.userservice.signInGoogleUser();
      this.user = JSON.parse(atob(this.id_token.split('.')[1]));
      sessionStorage.setItem("id_token",this.id_token);
      let expires_at=this.user.exp.toString();
      sessionStorage.setItem("timer",expires_at);
      this.modalDisplay='none';
      document.getElementById('sign-in-modal').style.display = 'none';
      document.getElementById('sign-in-modal-backdrop').style.display = 'none';
    }

    refreshTokenSilently(){
      this.loadGoogleScript().then(() => {
        this.renderGoogleSignInButton();
      });
    }
}
