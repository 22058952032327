<div *ngIf="invalidLoginShow==1" class="invalid-login-message">You don't have permission to login. Please try a different id or register to get acess</div>
<div class="logo">
    <!-- <img src="assets/solvay-logo(t).png" class="logo-solvay"/> -->
</div>
<div class="heading">
    <img src="assets/research catalyst-logo-VF1.png" class="logo-tool"/> <!--assets/research catalyst-1.png-->
</div>
<div *ngIf="loadingFlag==0"  class = "loginBox">
    <h3 style="margin-top:5px;margin-bottom: 25px">Login/Connexion</h3>
    <!-- <div>
        <div id="g_id_onload"
            data-client_id=""
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleCredentialResponse"
            data-close_on_tap_outside="false"
            data-itp_support="true">
        </div>
   
        <div class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="filled_blue"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left" 
            style="width: fit-content;margin: auto;">
        </div>
    </div> -->

    <div id="google-button" style="width: fit-content; margin: auto;"></div>

    <div>
        <span tabindex="0" data-toggle="tooltip" title="Request access to the tool">
            <button class="login-button btn-default access" (click)="register()">
                <p style="text-decoration:underline">Request Access</p>
            </button>
        </span>
    </div>
</div>

<div  *ngIf="loadingFlag==1" class ="loginBox">
    <img src="assets/loading.gif" class="loading-gif" >
    <p style="margin-top: -9%; color: gray;">Logging in...</p>
</div>
