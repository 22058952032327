import { Component } from '@angular/core';
import { NgxGalleryOptions,NgxGalleryAnimation} from 'ngx-gallery';
import {TranslateService} from '@ngx-translate/core';
import { UpdateService } from '../_services/update.service';


@Component({
  selector: 'app-tlc',
  templateUrl: './tlc.component.html',
  styleUrls: ['./tlc.component.css']
})
export class TLCComponent {

  dataList: any[];
  dummyDataList: any[];
  showDropDown: boolean;
  labelPosition = 'FreeText';
  selectedIndex:number=0;
  secondarySearchFlag;
  galleryOptions: NgxGalleryOptions[];
  language='en'
  Report="Report"
  yearFilterReset=0;
  constructor(
    private translate: TranslateService,
    private updateService:UpdateService) {
    this.updateService.currentLanguage$.subscribe(
      data=>{
          this.language=data;
          translate.use(this.language);
      }
  )
  //Code for language selection
  this.language=this.translate.currentLang
  translate.addLangs(['en', 'fr']);
  translate.setDefaultLang(sessionStorage.getItem("language"));
  const browserLang = translate.getBrowserLang();
  translate.use(this.language);
  };
  

  //Handler for selection of jumbo radio button
  onSelectRadio(event){
this.labelPosition=event.value
this.updateService.changeJumboVisibility(this.labelPosition);
this.yearFilterReset=1;
this.updateService.resetYearFilter(this.yearFilterReset)
  }

  //Handler for selecetion of tab change
  onTabChange(event){
    this.selectedIndex=event.index;
  }


  ngOnInit(){
  this.language=sessionStorage.getItem("language");
    this.galleryOptions = [
      {
          width: '400px',
          height: '555px', 
         
          thumbnailsColumns: 2,
         imageAnimation: NgxGalleryAnimation.Slide,
         thumbnailsPercent: 90,
         imagePercent:100,
         imageSize:'4%',
         thumbnailsMargin: 25,
         previewCloseOnClick:true,
         imageDescription:true,
         previewZoom:true,
         imageSwipe:false,
         image:false
      }
    ]
}
}


