import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { RestService } from './rest.service';
import { UpdateService } from '../_services/update.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ElasticsearchService {
  
  //Variable for API URLs
  API_Hotspot='http://192.168.43.231:8000';
  API_LOCAL='http://192.168.43.189:8000';
  API_Pranshu='http://192.168.0.111:8000';
  API_Backend="https://backend-dot-gcp-sqo-ri-tlc-d.appspot.com";
  API_temp="https://temp-backend-dot-gcp-sqo-ri-tlc-d.appspot.com"
  API_URL=environment.api_url;
  
  //Getting id token from session storage
  id_token=sessionStorage.getItem('id_token');



  constructor(private  httpClient:  HttpClient,private restService: RestService,private updateService : UpdateService) { }


  //############################## Elastic Search requests start here #######################3

  //Simple elastic search on search report page
  fullTextSearch(query){
    this.updateService.showSearchLoader.next(1);
    
    var res= this.httpClient.post(`${this.API_URL}/elasticSearch/`,query,{
      headers: new HttpHeaders({"Authorization":"Bearer "+sessionStorage.getItem('id_token')}),
      reportProgress: true,
      observe: 'response'
    });
    //this.showSearchLoader.next(0);
    return res;
  }
  

//Elastic search on matched reports in image result pages
  imageTextSearch(query){
    this.updateService.updateLoaderImageElastic(1);
    return this.httpClient.post(`${this.API_URL}/imageElasticSearch/`,query,{
      headers: new HttpHeaders({"Authorization":"Bearer "+sessionStorage.getItem('id_token')}),
      reportProgress: true,
      observe: 'response'
    });
  }

  //Jumbo keyword search

  jumboTextSearch(query){
    this.updateService.showSearchLoader.next(1);
    var res= this.httpClient.post(`${this.API_URL}/jumboElasticSearch/`,query,{
      headers: new HttpHeaders({"Authorization":"Bearer "+sessionStorage.getItem('id_token')}),
      reportProgress: true,
      observe: 'response'
    });
    
    return res;
  }

}
