import { Subject } from 'rxjs/Subject';
import * as i0 from "@angular/core";
var UpdateService = /** @class */ (function () {
    function UpdateService() {
        //Variable for updating matched image results
        this.match_images = new Subject();
        this.match_images$ = this.match_images.asObservable();
        //Variables for error flags
        this.imageMatchErr = new Subject();
        this.imageMatchErr$ = this.imageMatchErr.asObservable();
        //Variables to show multiple plate error
        this.multiPlateDetected = new Subject();
        this.multiPlateDetected$ = this.multiPlateDetected.asObservable();
        //Variables to show no plate error
        this.noPlateDetected = new Subject();
        this.noPlateDetected$ = this.noPlateDetected.asObservable();
        //Variable to update selected jumbo keywords
        this.selectedJumboKeyword = new Subject();
        this.selectedJumboKeyword$ = this.selectedJumboKeyword.asObservable();
        //Variable for checking is match image is clicked
        this.matchImageClicked = new Subject();
        this.matchImageClicked$ = this.matchImageClicked.asObservable();
        //Variable to capture jumbo match type
        this.jumboMatchType = new Subject();
        this.jumboMatchType$ = this.jumboMatchType.asObservable();
        //Variables to change visibility of log in page
        this.loggedIn = new Subject();
        this.loggedIn$ = this.loggedIn.asObservable();
        //Varibales to show loading gif on image-result component
        this.imageShowLoading = new Subject();
        this.imageShowLoading$ = this.imageShowLoading.asObservable();
        //Variables for upload-component placeholder
        this.uploadPlaceholder = new Subject();
        this.uploadPlaceholder$ = this.uploadPlaceholder.asObservable();
        //Variables to show loading gif on uploading image
        this.uploadLoadingVisible = new Subject();
        this.uploadLoadingVisible$ = this.uploadLoadingVisible.asObservable();
        //Variable to refresh slider values
        this.minValue = new Subject();
        this.minValue$ = this.minValue.asObservable();
        this.maxValue = new Subject();
        this.maxValue$ = this.maxValue.asObservable();
        //Variables for storing value of selected MP and RA
        this.selectedMP = new Subject();
        this.selectedMP$ = this.selectedMP.asObservable();
        this.selectedRA = new Subject();
        this.selectedRA$ = this.selectedRA.asObservable();
        this.mp = "";
        this.ra = "";
        //Variables for storing value of selected Jumbo keywords
        this.selectedKeyword = new Subject();
        this.selectedKeyword$ = this.selectedKeyword.asObservable();
        //Variable for controlling visibility of jumbo search bar
        this.jumboVisible = new Subject();
        this.jumboVisible$ = this.jumboVisible.asObservable();
        //Variables for updating jumbo keywords on change of jumbo type
        this.jumboKeyword = new Subject();
        this.jumboKeyword$ = this.jumboKeyword.asObservable();
        //Variables to update minimum and maximum value of slider
        this.Y1 = new Subject();
        this.Y1$ = this.Y1.asObservable();
        this.Y2 = new Subject();
        this.Y2$ = this.Y2.asObservable();
        //Variables for updating slider after undo button is clicked
        this.undoY1 = new Subject();
        this.undoY1$ = this.undoY1.asObservable();
        this.undoY2 = new Subject();
        this.undoY2$ = this.undoY2.asObservable();
        //Variable for updating image match response
        this.imageResponse = new Subject();
        this.imageResponse$ = this.imageResponse.asObservable();
        //Variables for updating jumbo search results
        this.jumboResults = new Subject();
        this.jumboResults$ = this.jumboResults.asObservable();
        //Variables for updating original image response
        this.originalImageResponse = new Subject();
        this.originalImageResponse$ = this.originalImageResponse.asObservable();
        //varibale for updating spot number
        this.spotNo = new Subject();
        this.spotNo$ = this.spotNo.asObservable();
        //Variable for updating color variation
        this.colorVariation = new Subject();
        this.colorVariation$ = this.colorVariation.asObservable();
        //Varibale for updating intentisty variation
        this.intensityVariation = new Subject();
        this.intensityVariation$ = this.intensityVariation.asObservable();
        //Variable for updating uploaded image
        this.inputImage = new Subject();
        this.inputImage$ = this.inputImage.asObservable();
        //Variable for updating cropped image
        this.croppedImage = new Subject();
        this.croppedImage$ = this.croppedImage.asObservable();
        //Variable for activating secondary search
        this.secondarySearch = new Subject();
        this.secondarySearch$ = this.secondarySearch.asObservable();
        //Variable for storing searched images
        this.searchedImages = new Subject();
        this.searchedImages$ = this.searchedImages.asObservable();
        //Varibale for updating select tlc image search type
        this.imageSearchType = new Subject();
        this.ImageSearchType$ = this.imageSearchType.asObservable();
        //Variables for handling multiple image response
        this.imageResponseArray = new Subject();
        this.imageResponseArray$ = this.imageResponseArray.asObservable();
        //Capturing processed image after cropping
        this.processedImage = new Subject();
        this.processedImage$ = this.processedImage.asObservable();
        //Variable to check if image is selected
        this.imageSelected = new Subject();
        this.imageSelected$ = this.imageSelected.asObservable();
        //capturing spot image list to remove mouse bug
        this.spotList = new Subject();
        this.spotList$ = this.spotList.asObservable();
        //capturing spot db to remove mouse bug
        this.spotDB = new Subject();
        this.spotDB$ = this.spotDB.asObservable();
        //variable to control cant find my spot image view
        this.CFMSselected = new Subject();
        this.CFMSselected$ = this.CFMSselected.asObservable();
        //language controller
        this.currentLanguage = new Subject();
        this.currentLanguage$ = this.currentLanguage.asObservable();
        //year filter reset
        this.yearFilter = new Subject();
        this.yearFilter$ = this.yearFilter.asObservable();
        //year filter for image match
        this.displayYearFilter = new Subject();
        this.displayYearFilter$ = this.displayYearFilter.asObservable();
        //Image tab start year
        this.imageTabStartYear = new Subject();
        this.imageTabStartYear$ = this.imageTabStartYear.asObservable();
        //Image tab end year
        this.imageTabEndYear = new Subject();
        this.imageTabEndYear$ = this.imageTabEndYear.asObservable();
        //Applying image match using year filter in image tab
        this.imageTabYearFilter = new Subject();
        this.imageTabYearFilter$ = this.imageTabYearFilter.asObservable();
        //Variable for updating response from undo button of multi spot
        this.imageResponseFromMultiSpot = new Subject();
        this.imageResponseFromMultiSpot$ = this.imageResponseFromMultiSpot.asObservable();
        //variables for updating visibility of loader in search report page
        this.showSearchLoader = new Subject();
        this.showSearchLoader$ = this.showSearchLoader.asObservable();
        //variables for updating visibility of error message on search report page
        this.searchReportErr = new Subject();
        this.searchReportErr$ = this.searchReportErr.asObservable();
        //Variables for controlling the show of error message on invalid login
        this.invalidLoginFlag = new Subject();
        this.invalidLoginFlag$ = this.invalidLoginFlag.asObservable();
        //Variables for updating signed url
        this.signed_urls = new Subject();
        this.signed_urls$ = this.signed_urls.asObservable();
        this.clrYearFilter = new Subject();
        this.clrYearFilter$ = this.clrYearFilter.asObservable();
    }
    //function to update selected start year in image tab
    UpdateService.prototype.imageYearFilterStartYear = function (value) {
        this.imageTabStartYear.next(value);
    };
    //function to update selected end year in image tab
    UpdateService.prototype.imageYearFilterEndYear = function (value) {
        this.imageTabEndYear.next(value);
    };
    //function to apply match image in image tab
    UpdateService.prototype.applyImageYearFilter = function () {
        this.imageTabYearFilter.next("apply");
    };
    //Function to display year filter in image tab
    UpdateService.prototype.displayYearModal = function (num) {
        this.displayYearFilter.next(1);
    };
    //Function to check if image is selected or not
    UpdateService.prototype.checkImageSelected = function (val) {
        this.imageSelected.next(val);
    };
    //Function for handling processed image after cropping
    UpdateService.prototype.captureProcessedImage = function (image) {
        this.processedImage.next(image);
    };
    //Function to update multiple plate detected
    UpdateService.prototype.showMultiplePlateWarning = function (n) {
        this.multiPlateDetected.next(n);
    };
    //Function to update no plate detected
    UpdateService.prototype.showNoPlateWarning = function (n) {
        this.noPlateDetected.next(n);
    };
    //Function to update the error flags
    UpdateService.prototype.updateErrflags = function (data) {
        this.imageMatchErr.next(data);
    };
    //Update jumbo matched type
    UpdateService.prototype.updateJumboMatchtype = function (type) {
        this.jumboMatchType.next(type);
    };
    //function to update selected jumbo keywords
    UpdateService.prototype.updateSelectedJumboKeyword = function (keyword) {
        this.selectedJumboKeyword.next(keyword);
    };
    //Function to show loader in image elastic search
    UpdateService.prototype.updateLoaderImageElastic = function (v) {
        this.imageShowLoading.next(v);
    };
    //Function to update original image response
    UpdateService.prototype.updateImageOriginalResponse = function (data) {
        this.originalImageResponse.next(data);
    };
    //Function to update jumbo search response
    UpdateService.prototype.updateJumboTextResponse = function (response) {
        this.jumboResults.next(response);
    };
    //Function to update image response
    UpdateService.prototype.updateImageResponse = function (obj) {
        this.imageResponse.next(obj);
    };
    //Function to update slider values
    UpdateService.prototype.updateSliderValues = function (y1, y2) {
        this.Y1.next(y1);
        this.Y2.next(y2);
    };
    //Function to update jumbo keyword
    UpdateService.prototype.updateJumboKeyword = function (array) {
        this.jumboKeyword.next([]);
        this.jumboKeyword.next(array);
    };
    //Function to change jumbo visibility
    UpdateService.prototype.changeJumboVisibility = function (value) {
        if (value == 'Jumbo') {
            this.jumboVisible.next(true);
        }
        else {
            this.jumboVisible.next(false);
        }
    };
    //Function to change MP value
    UpdateService.prototype.updateMP = function (mp) {
        this.selectedMP.next(mp);
        this.mp = mp;
    };
    //Function to change RA value
    UpdateService.prototype.updateRA = function (ra) {
        this.selectedRA.next(ra);
        this.ra = ra;
    };
    //Function to update jumbo keyword
    UpdateService.prototype.updateKeyword = function (keyword) {
        this.selectedKeyword.next(keyword);
    };
    //Function to toggle values of upload component placeholder
    UpdateService.prototype.changeUploadPlaceholder = function () {
        this.uploadPlaceholder.next(false);
    };
    //Function to show loading gif on uploading image
    UpdateService.prototype.changeUploadLoading = function () {
        this.uploadLoadingVisible.next(false);
    };
    //Function to toggle visibility of slider
    UpdateService.prototype.changeSliderInitialValues = function () {
        this.minValue.next(100);
        this.maxValue.next(300);
    };
    //Function to toggle login visibility
    UpdateService.prototype.changeLogin = function () {
        this.loggedIn.next(true);
    };
    //Function for updating spot number
    UpdateService.prototype.updateSpotNo = function (spot) {
        this.spotNo.next(spot);
    };
    //Function for updating color variation
    UpdateService.prototype.updateColorVariation = function (color) {
        this.colorVariation.next(color);
    };
    //Function for updating intensity variation
    UpdateService.prototype.updateIntensityVariation = function (intensity) {
        this.intensityVariation.next(intensity);
    };
    //Function for updating input image 
    UpdateService.prototype.updateInputImage = function (image) {
        this.inputImage.next(image);
    };
    //Fucntion for updating cropped image
    UpdateService.prototype.updateCroppedImage = function (image) {
        this.croppedImage.next(image);
    };
    //Function for handling select TLC plate type
    UpdateService.prototype.updateImageSelectType = function (type) {
        this.imageSearchType.next(type);
    };
    //Function for updatin image response array
    UpdateService.prototype.updateImageResponseArray = function (images) {
        this.imageResponseArray.next(images);
    };
    //function to update searched images
    UpdateService.prototype.updatesearchedImages = function (value) {
        this.searchedImages.next(value);
    };
    ;
    //function to update spot db and spot list
    UpdateService.prototype.updateSpotList = function (res) {
        this.spotList.next(res);
    };
    ;
    UpdateService.prototype.updateSpotDB = function (res) {
        this.spotDB.next(res);
    };
    //updating cant find my spot to show image without spots identified
    UpdateService.prototype.updateCFMS = function (value) {
        this.CFMSselected.next(value);
    };
    //language controller
    UpdateService.prototype.updateLanguage = function () {
        var lang = sessionStorage.getItem("language");
        this.currentLanguage.next(lang);
    };
    //Resetting year filter in jumbo search
    UpdateService.prototype.resetYearFilter = function (obj) {
        this.yearFilter.next(obj);
    };
    //function to update slider after undo multispot is clicked
    UpdateService.prototype.updateSliderForUndoMultispot = function (Y1, Y2) {
        this.undoY1.next(Y1);
        this.undoY2.next(Y2);
    };
    //function to update image response from undo of multi spot
    UpdateService.prototype.updateImageResponseFromUndoMultiSpot = function (obj) {
        this.imageResponseFromMultiSpot.next(obj);
    };
    //Updating loader on report search page
    UpdateService.prototype.updateLoaderForReportSearch = function (v) {
        this.showSearchLoader.next(v);
    };
    //Update network error message on report search page
    UpdateService.prototype.updateSearchErrFlag = function (data) {
        this.searchReportErr.next(data);
    };
    //function to update the status of invalid login flag
    UpdateService.prototype.updateInvalidLoginFlag = function (status) {
        console.log("inside invalid flag in updateservice");
        this.invalidLoginFlag.next(status);
    };
    UpdateService.prototype.updateClearYearFilter = function (pbStat) {
        this.clrYearFilter.next(pbStat);
    };
    UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(); }, token: UpdateService, providedIn: "root" });
    return UpdateService;
}());
export { UpdateService };
