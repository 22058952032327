import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestService } from './rest.service';
import { UpdateService } from '../_services/update.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./rest.service";
import * as i3 from "./update.service";
var ElasticsearchService = /** @class */ (function () {
    function ElasticsearchService(httpClient, restService, updateService) {
        this.httpClient = httpClient;
        this.restService = restService;
        this.updateService = updateService;
        //Variable for API URLs
        this.API_Hotspot = 'http://192.168.43.231:8000';
        this.API_LOCAL = 'http://192.168.43.189:8000';
        this.API_Pranshu = 'http://192.168.0.111:8000';
        this.API_Backend = "https://backend-dot-gcp-sqo-ri-tlc-d.appspot.com";
        this.API_temp = "https://temp-backend-dot-gcp-sqo-ri-tlc-d.appspot.com";
        this.API_URL = environment.api_url;
        //Getting id token from session storage
        this.id_token = sessionStorage.getItem('id_token');
    }
    //############################## Elastic Search requests start here #######################3
    //Simple elastic search on search report page
    ElasticsearchService.prototype.fullTextSearch = function (query) {
        this.updateService.showSearchLoader.next(1);
        var res = this.httpClient.post(this.API_URL + "/elasticSearch/", query, {
            headers: new HttpHeaders({ "Authorization": "Bearer " + sessionStorage.getItem('id_token') }),
            reportProgress: true,
            observe: 'response'
        });
        //this.showSearchLoader.next(0);
        return res;
    };
    //Elastic search on matched reports in image result pages
    ElasticsearchService.prototype.imageTextSearch = function (query) {
        this.updateService.updateLoaderImageElastic(1);
        return this.httpClient.post(this.API_URL + "/imageElasticSearch/", query, {
            headers: new HttpHeaders({ "Authorization": "Bearer " + sessionStorage.getItem('id_token') }),
            reportProgress: true,
            observe: 'response'
        });
    };
    //Jumbo keyword search
    ElasticsearchService.prototype.jumboTextSearch = function (query) {
        this.updateService.showSearchLoader.next(1);
        var res = this.httpClient.post(this.API_URL + "/jumboElasticSearch/", query, {
            headers: new HttpHeaders({ "Authorization": "Bearer " + sessionStorage.getItem('id_token') }),
            reportProgress: true,
            observe: 'response'
        });
        return res;
    };
    ElasticsearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElasticsearchService_Factory() { return new ElasticsearchService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.RestService), i0.ɵɵinject(i3.UpdateService)); }, token: ElasticsearchService, providedIn: "root" });
    return ElasticsearchService;
}());
export { ElasticsearchService };
