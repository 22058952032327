/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-elastic-search.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./image-elastic-search.component";
import * as i4 from "../_services/rest.service";
import * as i5 from "../_services/elasticsearch.service";
import * as i6 from "../_services/update.service";
var styles_ImageElasticSearchComponent = [i0.styles];
var RenderType_ImageElasticSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageElasticSearchComponent, data: {} });
export { RenderType_ImageElasticSearchComponent as RenderType_ImageElasticSearchComponent };
export function View_ImageElasticSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row SearchBarDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-sm-6 reportCont1"], ["style", "margin-top: 2%; margin-bottom: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "DDText"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("TLC.Digital Database Image Search")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ImageElasticSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-elastic-search", [], null, null, null, View_ImageElasticSearchComponent_0, RenderType_ImageElasticSearchComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageElasticSearchComponent, [i4.RestService, i2.TranslateService, i5.ElasticsearchService, i6.UpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageElasticSearchComponentNgFactory = i1.ɵccf("app-image-elastic-search", i3.ImageElasticSearchComponent, View_ImageElasticSearchComponent_Host_0, {}, {}, []);
export { ImageElasticSearchComponentNgFactory as ImageElasticSearchComponentNgFactory };
