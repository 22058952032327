
<div class= "column">
    <!--Navigation bar starts-->
  <div class = "col-md-12" style = "padding-left: 0; padding-right: 0;">
     <app-navbar></app-navbar>
  </div>
  <!--Navigation bar ends -->
  <div class = "col-md-12" style = "padding-left: 0; padding-right: 0;">
     <mat-tab-group >
         <!--Image tab starts-->
         <mat-tab label = "Image">
             <div class="dropdown-bar-custom" style="top: 0.8%"> 
                <app-mp-ra-dropdown></app-mp-ra-dropdown>
            </div>
            <app-image-elastic-search></app-image-elastic-search>
            <div class = "row imageandsearchCont">
                <div class = "col-sm-4 container1">
                    <app-upload-image></app-upload-image>
                    
                </div>
                
                <div class="col-sm-7 container2">
                    <app-image-result></app-image-result>
                </div>
            </div>
         </mat-tab>
         <!--Image tab ends-->
         <!--Report tab starts-->
         <mat-tab [label] = "language=='en'? 'Report' : 'Rapport'">
             <div class = "dropdown-bar-custom row">
              <div class = "radio col-sm-5">
                  <mat-radio-group [(ngModel)]="labelPosition" (change)="onSelectRadio($event)">
                    <mat-radio-button class="example-margin" value="FreeText">{{ 'TLC.Free Text Search' | translate}}</mat-radio-button>
                    <mat-radio-button class="example-margin" value="Jumbo">{{ 'TLC.Jumbo Keyword Search' | translate}}</mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-sm-6" [style.display]="labelPosition=='Jumbo'?null:'none'">
                    <app-jumbo-keyword></app-jumbo-keyword>
              </div>
            </div>
            
                <app-search-report></app-search-report>
            
         </mat-tab>
         <!--Report tab ends-->
         <!--Reference tab starts-->
         <mat-tab [label] = "language=='en'? 'Reference Plate' : 'Plaque De Référence'">
             <app-reference-plate></app-reference-plate>
         </mat-tab>
         <!--Reference tab ends-->
     </mat-tab-group>
     
  </div>
</div>
