<div class="row" style="position: absolute;width:100%;right:-170px;padding-left: 5.5%; padding-right: 2%">
    <div style="margin-right: 10%">
  <form class="mp-ra-form">
    <mat-form-field class="example-full-width" floatLabel="never">
      <input class="dropdown-custom" type="text" [placeholder]="language=='en'? 'Select Type' : 'Sélectionner Le Type'" aria-label="Number" matInput [formControl]="Control_Type" [matAutocomplete]="autoMP" (click)="clickHandlerType()">
      <mat-autocomplete autoActiveFirstOption #autoMP="matAutocomplete" (optionSelected)="typeSelected($event)">
        <mat-option *ngFor="let option of filteredOptions_Type | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  </div>

  <div >
  
    <form class="mp-ra-form" >
  <mat-form-field class="example-full-width2" floatLabel="never">
    <input class="dropdown-custom" type="text" [placeholder]="language=='en'? 'Select Keyword' : 'Sélectionne Des Mots Clés'" aria-label="Number" matInput [formControl]="Control_Keyword" [matAutocomplete]="autoRA" (click)="clickHandlerKeyWord()">
    <mat-autocomplete autoActiveFirstOption #autoRA="matAutocomplete"  (optionSelected)="keywordSelected($event)">
      <mat-option *ngFor="let option of filteredOptions_Keyword | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</form>
</div>
  
 
  </div>
