  <div class="header">
    <nav class="navbar navbar-default" style="background: white; height: inherit;">


      <!-- <div class="navbar-header comp-logo">
        <a class="navbar-brand " href="https://www.solvay.com/en" target="_blank">
          <img class="solvayLogo" src="assets/component (16).png">
        </a>
      </div> -->

      <div class="navbar-header tool-title" >
          <a class="navbar-brand " [routerLink]="['/home']" style="padding:0;">
            <img class="toolLogo" src="assets/research catalyst-logo-55px.png">  <!--assets/research catalyst-1.png-->
          </a>
        </div>

      <div class="languageSelector">
        <div style="margin-top:5px">
        <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Submit Feedback' : 'Soumettre La Rétroaction'">
        <a href="https://docs.google.com/spreadsheets/d/1ZRFdtvUZo1PCToWxLMeNBKOfGMjw_Ka7F6TKqRCDRIg/edit?usp=sharing" target="_blank">
        <button type="button" class="nav-button"> {{ 'Navbar.Feedback' | translate}}</button>
        </a>
        </span>
        <span tabindex="0" data-toggle="tooltip" [title]="language=='en'? 'Check out the tutorial' : 'Essayez Ces Tutoriels'">
        <a [routerLink]="['/instruction']">
        <button type="button" class="nav-button">{{ 'Navbar.Tutorial' | translate}}</button>
        </a>
        </span>
        </div>
        <p style="color: gray;font-size:74%;margin-right:15px;text-align: center">{{ 'Navbar.Select Language:' | translate}} <br />
          <a [style.color]="language=='en'? 'blue' : 'grey'" (click)=selectEnglish()><span>{{ 'Navbar.English' | translate}} </span></a>
          <span>/</span>
          <a [style.color]="language=='fr'? 'blue' : 'grey'" (click)=selectFrench()><span>{{ 'Navbar.French' | translate}}</span></a>
        </p>
      </div>
    <div class="menu" style="color: gray">
  
   {{ 'Navbar.Hi'  | translate}} {{greeting}}
      <button style="margin-left:-12px" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon style="font-size:18px">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">

          <button mat-menu-item (click)="openFAQ()">
              <mat-icon>information</mat-icon>
              <span>{{ 'Navbar.FAQ' | translate}}</span>
          </button>
          <button mat-menu-item (click)="openAdminConsole()">
              <mat-icon>person</mat-icon>
              <span>{{ 'Navbar.Admin Console' | translate}}</span>
          </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>{{ 'Navbar.Logout' | translate}}</span>
        </button>
        
        
      </mat-menu>
    

    </div>
    </nav>
</div>

  <!--Modal for selecting fresh search or filter search-->
<div class="backdrop" id="sign-in-modal-backdrop" [ngStyle]="{'display':modalDisplay}"></div>
<div class="modal" id="sign-in-modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':modalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
       
        <b class="modal-title">{{ 'Navbar.Your session is about to expire' | translate}}</b>
        
      </div>
      <div style="height:55px" class="modal-body" >                 
      <!-- <button mat-raised-button [color]="'custom'" (click)="loginAgain()">Login again</button>
      <button mat-raised-button [color]="'custom'" (click)="logoutFromModal()" >Logout</button> -->
      <button  type="button" style="font-size: 15px;left:60px;position:absolute" class="btn btn-default login-button" (click)="refreshTokenSilently()" >
          <i class="fa fa-google" aria-hidden="true"></i>{{ 'Navbar.Login Again' | translate}}
          </button>
          <!-- <button id="google-button" class="g_id_onload"
               data-scope="profile email"
               data-auto_select="true"
               style="width: fit-content; margin: auto;">
        </button> -->
          <button  type="button" style="font-size: 15px;right:90px;position:absolute" class="btn btn-default login-button" (click)="logoutFromModal()" >
              <i class="fa fa-google" aria-hidden="true"></i>{{ 'Navbar.Logout' | translate}}
              </button>
      </div>
      
      
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
 </div><!-- /.modal !-->


 <!--Modal for selecting language-->
 <div class="backdrop" [ngStyle]="{'display':languageModalDisplay}"></div>
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':languageModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
       
        <b class="modal-title">{{ 'Navbar.Please select your preferred language' | translate}}</b>
        
      </div>
      <div style="height:55px" class="modal-body" >                 
      <!-- <button mat-raised-button [color]="'custom'" (click)="loginAgain()">Login again</button>
      <button mat-raised-button [color]="'custom'" (click)="logoutFromModal()" >Logout</button> -->
      <button  type="button" style="font-size: 15px;left:60px;position:absolute" class="btn btn-default login-button" (click)="selectEnglish()" >
        English
      </button>
      <button  type="button" style="font-size: 15px;right:90px;position:absolute" class="btn btn-default login-button" (click)="selectFrench()" >
        Français
      </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
 </div><!-- /.modal !-->


 <!--Modal for displaying access error message-->
 <div class="backdrop" [ngStyle]="{'display':accessModalDisplay}"></div>
<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':accessModalDisplay}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
    <div class="modal-header">
        <b class="modal-title">{{ 'Navbar.Error' | translate}}</b>
        <a (click)="closeAccessErrorModal()" style="position: absolute;right:25px;">
        <span  style = "float: right;"><i class = "fa fa-close fa-lg"></i></span>
        </a>
      </div>
      <div style="height:55px" class="modal-body" > 
      <h4 style="text-align:center">{{ 'Navbar.Sorry but you do not have administrator rights' | translate}}</h4>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
 </div><!-- /.modal !-->

