  <div class = "row" >
   <div class = "col-md-8"> 
            <img
                [src]="safeImgDataUrl"
                [style.visibility]="imageVisible ? 'visible' : 'hidden'"
                (load)="imageLoadedInView()"
                class="source-image"
                style = "height: 400px"
            />
            <div  class="cropper"
         [class.rounded]="roundCropper"
         [style.top.px]="cropper.y1"
         [style.left.px]="cropper.x1"
         [style.width.px]="cropper.x2 - cropper.x1"
         [style.height.px]="cropper.y2 - cropper.y1"
         [style.visibility]="cropperVisible ? 'visible' : 'hidden'"
         >
        <div
            (mousedown)="startMove($event, 'move')"
            (touchstart)="startMove($event, 'move')"
            class="move"
        >&nbsp;</div>
        <span
            class="resize topleft"
            (mousedown)="startMove($event, 'resize', 'topleft')"
            (touchstart)="startMove($event, 'resize', 'topleft')"
        ><span class="square"></span></span>
        <span
            class="resize top"
        ><span class="square"></span></span>
        <span
            class="resize topright"
            (mousedown)="startMove($event, 'resize', 'topright')"
            (touchstart)="startMove($event, 'resize', 'topright')"
        ><span class="square"></span></span>
        <span
            class="resize right"
        ><span class="square"></span></span>
        <span
            class="resize bottomright"
            (mousedown)="startMove($event, 'resize', 'bottomright')"
            (touchstart)="startMove($event, 'resize', 'bottomright')"
        ><span class="square"></span></span>
        <span
            class="resize bottom"
        ><span class="square"></span></span>
        <span
            class="resize bottomleft"
            (mousedown)="startMove($event, 'resize', 'bottomleft')"
            (touchstart)="startMove($event, 'resize', 'bottomleft')"
        ><span class="square"></span></span>
        <span
            class="resize left"
        ><span class="square"></span></span>
        <span
            class="resize-bar top"
            (mousedown)="startMove($event, 'resize', 'top')"
            (touchstart)="startMove($event, 'resize', 'top')"
        ></span>
        <span
            class="resize-bar right"
            (mousedown)="startMove($event, 'resize', 'right')"
            (touchstart)="startMove($event, 'resize', 'right')"
        ></span>
        <span
            class="resize-bar bottom"
            (mousedown)="startMove($event, 'resize', 'bottom')"
            (touchstart)="startMove($event, 'resize', 'bottom')"
        ></span>
        <span
            class="resize-bar left"
            (mousedown)="startMove($event, 'resize', 'left')"
            (touchstart)="startMove($event, 'resize', 'left')"
        ></span>
            </div>
</div>
<div class="spot-box column col-md-4" style="padding:5% 0% 0% 5%">
    <div>   
    <mat-form-field style="width:100px !important;">
    <mat-select id="spotDropdown" [placeholder]="language=='en'? 'Select spot' : 'Choisir un lieu'"  [formControl]="spots" (selectionChange)="onSelectSpot($event)" >
    <mat-option *ngFor="let spot_ of spotList" [value]="spot_">{{spot_}}</mat-option>
    </mat-select>
    </mat-form-field>
    </div>
    <!--Intensity Slider-->
    <div class="intensitySlider">
            <input type="range" #intensity min="5" max="100" [(value)]="percentIntensity" class="slider"  (input)="intensityErrorSelector(intensity.value)">
            <p style="font-size: 12px">{{ 'Image Cropper.Intensity variation' | translate}}: {{percentIntensity}}%</p>
          </div>
          <!--Color Slider-->
          <div class="colorSlider">
            <input type="range" #color min="15" max="100" [(value)]="percentColor" class="slider" id="myRange" (input)="colorErrorSelector(color.value)">
            <p style="font-size: 12px">{{ 'Image Cropper.Color Variation' | translate}}: {{percentColor}}%</p>
          </div>
        <div>
        <span tabindex="0" data-toggle="tooltip" 
        [attr.title]="(selectedSpot==''|| (Y1==0 && Y2==0)) && language=='en'?'Select spot and adjust slider to perform match':(selectedSpot==''|| (Y1==0 && Y2==0)) && language=='fr'? 'S�lectionnez le point et r�gler le slider pour performer la correspondance':(selectedSpot!==''|| (Y1!==0 && Y2!==0))&&language=='en'?'Click to perform match':(selectedSpot!==''|| (Y1!==0 && Y2!==0))&&language=='fr'?'Cliquez pour performer le match':''">
        <button mat-raised-button [color]="'custom'" style="width:122%;" [disabled]="selectedSpot=='' || (Y1==0 && Y2==0)?true:false" (click)="matchImage()"><span style ="font-size: 0.9em; padding: 1em;">{{ 'Image Cropper.Match Image' | translate}}</span></button>
        </span>
    </div>
    <!--Year filter button-->
    <div *ngIf="showYearFilterButton==1">
            
            <button type="button" class="btn btn-default btn-sm" style="
        margin-top: 9.5%;
        /* margin-left: -40%; */
        width:122%;
        background: #2577a8;
        height: 38px;
        border-radius: 5px;
        color: white;" (click)="openYearModal()">{{ 'Search Report.Year Filter' | translate}}</button>
    
    </div>
    <div *ngIf="showYearFilterButton==1 && clearYearFilter==true">            
        <button type="button" class="btn btn-default btn-sm" 
        style="margin-top: 9.5%;width:122%;height: 38px;border-radius: 5px;color: #000;" 
        (click)="ClearYearFilterfn()">{{ 'Search Report.ClearYearFilter' | translate}}</button>
    </div>
    
    <!--Undo Multi Spot button-->
    <div>
        <button *ngIf="undoButtonShow==1 && showMultiSpot" mat-raised-button [color]="'custom'" style="margin-left: -40%; 
            width:160%; margin-top: 4%"
            
            (click)="undoMutliSpot()">
            <span class="glyphicon glyphicon-refresh"></span>
            <span style ="font-size: 0.9em; padding: 1em;">{{ 'Image Cropper.Undo Multi-spot' | translate}}
            </span>
          </button>
    </div>
    <!--Clear all button-->
    <div>
        <button *ngIf="clearAllShow==1 && showMultiSpot " mat-raised-button [color]="'custom'" style="margin-left: -40%; 
            width:160%; margin-top: 6%"
            
            (click)="clearAll()">
            <span class="glyphicon glyphicon-refresh"></span>
            <span style ="font-size: 0.9em; padding: 1em;">{{ 'Image Cropper.Clear Multi-Spot' | translate}}
            </span>
          </button>
    </div>
    <!--Multi spot check box-->
        <span *ngIf="showMultiSpot" style=" margin-right: 2%;">
            <form  action="/action_page.php">
         <input type="checkbox"  name="type" value="multiSpot"  id="multiSpot" (click)="onMultiSpotSelect()" [checked]="multiSpotCheckedFlag"> <span>Multi-Spot</span>
            </form>
        </span>
       
      
    </div>

</div>


   
